<template>
    <div>
        <transition name="bounce-11">
            <div class="info-box" v-if="show">
                <div class="info-box-content d-block">
                    <div class="number-circle number-circle--38 float-left mr-4 font-weight-bold"
                         :class="{'number-circle--olive-active': point.is_loading, 'number-circle--blue-active': point.is_unloading}">
                        {{ point.point_number + 1 }}
                    </div>


                    <div class="btn-group btn-group-toggle mb-2" data-toggle="buttons">
                        <label class="btn btn-default" :class="{'bg-olive active': point.is_loading}"
                               @click="changeDirection('is_loading')">
                            <input type="radio" autocomplete="off" v-model="direction" value="is_loading">
                            {{ trans("tender.Loading") }}
                        </label>
                        <label class="btn btn-default" :class="{'bg-blue active': point.is_unloading}"
                               @click="changeDirection('is_unloading')">
                            <input type="radio" autocomplete="off" v-model="direction" value="is_unloading">
                            {{ trans("tender.Unloading") }}
                        </label>
                    </div>

                    <div class="btn-group float-right mb-2">
                        <span class="btn btn-outline-danger" @click="deletePoint">
                            <i class="fas fa-times"></i>
                        </span>
                        <span class="btn btn-outline-success" :class="{disabled: idx === 0}"
                              @click="$emit('reorder', {dir: 'up', idx: idx})">
                            <i class="fas fa-arrow-up"></i>
                        </span>
                        <span class="btn btn-outline-success" :class="{disabled: idx + 1 === pointsCnt}"
                              @click="$emit('reorder', {dir: 'down', idx: idx})">
                            <i class="fas fa-arrow-down"></i>
                        </span>
                    </div>


                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <form-date-time :value.sync="point.arrival_date_plan"
                                                :name="trans(point.is_loading ? 'tender.Loading time' : 'tender.Unloading time')"
                                                :rules="'required'"></form-date-time>
                            </div>
                        </div>
                    </div>

                    <address-list v-bind="{point}" :type="point.is_loading ? 'loading' : 'unloading'"></address-list>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Vue from "vue";
import AddressList from "../address/AddressList";

Vue.component("AddressList", AddressList);

export default {
    props: ["point", "idx", "pointsCnt"],
    data() {
        return {
            // новые точки имеют префикс new_, поэтому анимация появления работает только для них
            show: this.point.id >= 0,
            direction: this.point.is_loading ? "is_loading" : (this.point.is_unloading ? "is_unloading" : "")
        }
    },
    mounted() {
        this.show = true;
    },
    methods: {
        deletePoint() {
            this.show = false;
            let _this = this;
            setTimeout(function () {
                _this.$emit('delete', _this.idx);
            }, 500);
        },
        changeDirection(dir) {
            this.point.is_loading = dir === "is_loading";
            this.point.is_unloading = dir === "is_unloading";
        }
    }
}
</script>
