/**
 * @link https://sweetalert2.github.io/#configuration
 */
export default {
    swalSuccess(text, timer = 5000) {
        this.$swal.fire({
            icon: 'success',
            title: text,
            position: 'top-end',
            showConfirmButton: false,
            timer,
            toast: true
        });
    },
    swalError(text, timer = 5000) {
        this.$swal.fire({
            icon: 'error',
            title: text,
            position: 'top-end',
            showConfirmButton: false,
            timer,
            toast: true
        });
    }
}
