<template>
    <div>
                <form-response-errors ref="formResponseErrors"></form-response-errors>

                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link active" href="#notify"
                                                    data-toggle="tab">{{ trans("user.Notifications") }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="tab-pane active" id="notify">
                                <UserNotifySettings></UserNotifySettings>
                            </div>
                        </div>
                    </div>
                </div>

    </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";

export default {
    props: ["id"], // данные берутся из Vue.$router
    methods: {
        ...mapActions("userSettings", ["initState"]),
    },
    mounted() {
        this.initState({id: this.id});
    },
    computed: {
        ...mapState("userSettings", [
            "error"
        ]),
        ...mapGetters("userSettings", [
            "user"
        ])
    },
    watch: {
        error() {
            /**
             * Механизм показа ошибок из модуля tenderForm
             */
            this.$refs.formResponseErrors.show(this.error);
        }
    },
}
</script>
