import DriverList from "../views/driver/DriverList";
import DriverForm from "../views/driver/DriverForm";
import DriverDetail from "../views/driver/DriverDetail";

let ico = '<i class="icon-driver mr-2"></i>';

export default [
    {
        path: "/driver",
        name: "driver",
        component: DriverList,
        meta: {
            metaTags: {
                title: translate("driver.Drivers"),
                h1: ico + translate("driver.Drivers"),
            },
            breadcrumb: {
                label: translate("driver.Drivers"),
                parent: "home"
            }
        }
    },
    {
        path: "/driver/:id/edit",
        name: "driver-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: DriverForm,
        meta: {
            metaTags: {
                title: translate("driver.Driver"),
                h1: ico + translate("driver.Driver Editing"),
            },
            breadcrumb: {
                label: translate("driver.Driver"),
                parent: "driver"
            },
            rwBc: true
        }
    },
    {
        path: "/driver/:id/clone",
        name: "driver-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: DriverForm,
        meta: {
            metaTags: {
                title: translate("driver.Driver"),
                h1: ico + translate("driver.Driver Cloning"),
            },
            breadcrumb: {
                label: translate("driver.Driver"),
                parent: "driver"
            },
            rwBc: true
        }
    },
    {
        path: "/driver/create",
        name: "driver-create",
        props: route => ({id: 0, view: "create"}),
        component: DriverForm,
        meta: {
            metaTags: {
                title: translate("driver.Driver"),
                h1: ico + translate("driver.Driver Creation"),
            },
            breadcrumb: {
                label: translate("driver.Driver"),
                parent: "driver"
            }
        }
    },
    {
        path: "/driver/:id",
        name: "driver-detail",
        component: DriverDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("driver.Driver"),
                h1: ico + translate("driver.Driver"),
            },
            breadcrumb: {
                label: translate("driver.Driver"),
                parent: "driver"
            },
            rwBc: true
        }
    }
];
