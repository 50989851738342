<template>
    <div>
        <div v-for="point in route">
            <span class="text-muted text-sm">{{ momentDate(point.arrival_date_plan) }}</span>
            <i v-if="point.is_loading" class="icon-loading text-olive active"></i>
            <i v-if="point.is_unloading" class="icon-unloading text-blue active"></i>
            {{ point.locality.name }}
        </div>
    </div>
</template>

<script>
// TODO проверить правильность порядка точек маршрута
export default {
    props: ["route"]
}
</script>
