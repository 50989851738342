<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>
        <div class="card">
            <div class="card-header">
                <div class="card-tools float-none">

                    <div class="form-inline float-left">
                        <slot name="omnifilter">
                            <SearchBox :value="omniFilter"
                                       :placeholder="trans('Search')"
                                       v-on:search="omniFilterSearch"
                                       v-on:reset="omniFilterReset"
                                       v-on:input="omniFilter = $event.target.value"
                            ></SearchBox>
                        </slot>

                        <form-checkbox v-if="deleted_filter" :value.sync="include_deleted"
                                       :name="trans('Including deleted')" class="mt-1"></form-checkbox>
                    </div>

                    <div class="float-right">
                        <div class="btn-group">
                            <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown"
                                    aria-expanded="false">
                                <i class="fas fa-bars"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" role="menu">
                                <a v-on:click.prevent="excel" href="#" class="dropdown-item">
                                    <i class="fa fa-download mr-1" aria-hidden="true"></i>
                                    {{ trans("Download Excel") }}
                                </a>
                            </div>
                        </div>

                        <span @click="reload" class="btn btn-sm btn-tool mr-1">
                            <i class="fa fa-redo" aria-hidden="true"></i>
                        </span>

                        <slot name="buttons"></slot>
                    </div>


                </div>
            </div>
            <div class="card-body p-0">
                <ag-grid-vue
                    ref="agGrid"
                    style="height: 600px;"
                    class="ag-theme-alpine ag-theme-alpine--custom"
                    :gridOptions="gridOptions"
                >
                </ag-grid-vue>
            </div>
        </div>
    </div>
</template>

<script>
import SearchBox from "./SearchBox";

let defaultGridOptions = {
    enableCellTextSelection: true,
    localeText: window.i18n.aggrid,
    rowModelType: "serverSide",
    cacheBlockSize: 200,
    paginationPageSize: 100,
    maxBlocksInCache: 10000,
    pagination: true,
    multiSortKey: "ctrl",
    serverSideStoreType: "partial",
    defaultColDef: {
        filter: "agTextColumnFilter",
        flex: 1,
        sortable: true,
        resizable: true,
        minWidth: 60,
    },
    getRowClass(params) {
        try {
            if (params.data.deleted_at) {
                return "ag-row-deleted";
            }
        } catch (e) {

        }
    }
};

export default {
    props: ["componentGridOptions", "excelColumns", "service", "deleted_filter"],
    components: {SearchBox},
    data() {
        return {
            omniFilter: "",
            include_deleted: false,
            gridOptions: {
                ...defaultGridOptions,
                ...this.componentGridOptions
            }
        };
    },
    mounted() {
        this.setServer();
    },
    watch: {
        include_deleted() {
            this.$refs.agGrid.gridOptions.api.onFilterChanged();
        }
    },
    methods: {
        omniFilterReset() {
            this.omniFilter = "";
            this.omniFilterSearch();
        },
        omniFilterSearch() {
            this.$refs.agGrid.gridOptions.api.onFilterChanged();
        },
        excel() {
            let params = {
                columnKeys: this.excelColumns
            };
            this.$refs.agGrid.gridOptions.api.exportDataAsExcel(params);
        },
        reload() {
            this.$refs.formResponseErrors.hide();
            this.setServer();
        },
        setServer() {
            const ServerSideDatasource = (server) => {
                return {
                    getRows: async (params) => {
                        this.$refs.formResponseErrors.hide();
                        let response = await server.getResponse(params.request);
                        if (response.success) {
                            params.success(response);
                        } else {
                            params.fail();
                        }
                    }
                };
            };

            const Server = () => {
                return {
                    getResponse: async (requestParams) => {
                        try {
                            if (this.include_deleted) {
                                requestParams.filterModel["trashed"] = {filter: "with"};
                            }
                            let response = await this.service.getListAggrid(this.gridOptions.cacheBlockSize, requestParams, this.omniFilter);
                            return {
                                success: true,
                                rowData: response.data.data,
                                rowCount: response.data.meta.total
                            };
                        } catch (error) {
                            this.$refs.formResponseErrors.show(error);

                            return {
                                success: false
                            };
                        }
                    }
                };
            };

            let server = new Server();
            let datasource = new ServerSideDatasource(server);

            this.$refs.agGrid.gridOptions.api.setServerSideDatasource(datasource);
        }
    }
}
</script>
