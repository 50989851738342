/**
 * @see \App\Domain\Company\Policies\CompanyPolicy
 */
export default {
    "create-company": (user) => {
        return user.hasAccess(["company-all-admin", "company-all-create"]);
    },
    "read-company": (user, company) => {
        return user.hasAccess(["company-all-admin", "company-all-read"]) ||
            (user.hasAccess(["company-own-admin", "company-own-read"]) && user.company_id === company.id);
    },
    "read-company-list": (user) => {
        return user.hasAccess(["company-all-admin", "company-all-read", "company-own-admin", "company-own-read"]);
    },
    "update-company": (user, company) => {
        return user.hasAccess(["company-all-admin", "company-all-update"]) ||
            (user.company_id === company.id && user.hasAccess(["company-own-admin", "company-own-update"]))
    },
    "delete-company": (user, company) => {
        if (company.deleted_at) return false;
        return company.id !== user.company_id &&
            user.hasAccess(["company-all-admin", "company-all-delete"]);
    },
    "restore-company": (user, company) => {
        return !!company.deleted_at && user.hasAccess(["company-all-admin", "company-all-restore"]);
    },
    "block-company": (user) => {
        return user.hasAccess("company-all-admin", "company-all-block");
    },
    "kpi-company": (user) => {
        return user.hasAccess(["company-all-admin", "company-all-read"]);
    },
    "deleted-company-read": (user) => {
        return user.hasAccess(["company-all-admin", "company-all-delete"]);
    }
};
