import toastr from "toastr";
import WsInstance from './WsInstance';

function shortRoute(tender) {
    let points = _.sortBy(tender.route_points, o => o.point_number);
    let localities = points.map(o => o.locality.name);
    localities = localities.join(" - ");
    return `${localities}: ${tender.starting_bid} руб`
}

WsInstance.onInit((echo) => {
    echo.channel("tendering")
        .listen(".PublicTenderStart", (data) => {
            toastr.options = {
                newestOnTop: true,
                timeOut: 10000,
            }
            toastr["info"](shortRoute(data.tender));
            app.$store.dispatch("tendering/newTenderFromWS", data.tender);
        })
        .listen(".PublicTenderClose", (data) => {
            app.$store.dispatch("tendering/closeTenderFromWS", data.tender);
        })
        .listen(".NewBet", (data) => {
            toastr.options = {
                newestOnTop: true,
                timeOut: 10000,
            }

            let user = app.$store.state.auth.user;

            /**
             * Если новая ставка происходит от другой компании, проверяем перебитые ставки
             * и сообщаем, если среди них есть наша компания
             */
            if (data.bet.company_id !== user.company_id && data.prevOutbidBets.length) {
                for (let bet of data.prevOutbidBets) {
                    if (bet.company_id === user.company_id) {
                        toastr["info"](`Ваша ставка перебита. Тендер ${data.bet.tender_id} ${bet.bid} => ${data.bet.bid}`);
                    }
                }
            }

            app.$store.dispatch("tendering/updateTenderFromWS", {bet: data.bet, tender: data.tender});
        });

    /**
     * Смена статуса подключения
     * @link https://github.com/pusher/pusher-js
     */
    echo.connector.pusher.connection.bind_global((event, data) => {
        if (event === "state_change") {
            app.$store.dispatch("ws/setConnectionState", data.current);
        }
    });

});
