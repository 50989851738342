/**
 * Получить текст ошибки из запроса
 * @param error
 * @param defaultErrMsg
 * @returns {*}
 */
function getHttpError(error, defaultErrMsg) {
    // console.log(error);
    let ret = defaultErrMsg ? defaultErrMsg : translate("Failed to load data");

    if (error.response && error.response.data) {
        if (error.response.data.error) {
            ret = translate(error.response.data.error);
        }

        if (error.response.data.message) {
            ret = translate(error.response.data.message);
        }
    }

    return ret;
}

/**
 * Получить список ошибок в запросе (422), если они есть, или текст одной
 * @param error
 * @param defaultErrMsg
 * @returns {[]|*[]}
 */
function getHttpErrorList(error, defaultErrMsg) {
    try {
        let ret = [];
        let errors = error.response.data.errors;
        for (let idx in errors) {
            for (let error of errors[idx]) {
                ret.push(error);
            }
        }
        return ret;
    } catch (err) {
        return [getHttpError(error, defaultErrMsg)];
    }
}

export {getHttpError, getHttpErrorList}
