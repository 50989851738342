export const ws = {
    namespaced: true,
    state: {
        connectionState: null
    },
    actions: {
        async setConnectionState({commit}, connectionState) {
            commit("setConnectionState", connectionState);
        }
    },
    mutations: {
        setConnectionState(state, connectionState) {
            state.connectionState = connectionState;
        }
    }
};
