class ApiAggridRequestParams {
    constructor(perPage, requestParams, specialFields, omniFilter) {
        this.pagination(perPage, requestParams.startRow);
        this.filters(requestParams.filterModel);
        this.sort(requestParams.sortModel);
        this.specialFields(specialFields);
        this.omniFilter(omniFilter);
        /**
         * Также возможно добавить иклуды:
         *      let params = new ApiAggridRequestParams(perPage, requestParams);
         *      params.include = "users";
         *
         */
    }

    omniFilter(omniFilter) {
        if (typeof omniFilter === "undefined" || omniFilter === "") return;
        this[`filter[omniFilter]`] = omniFilter;
    }

    /**
     * Типичный вариант - в запрос передается "filter[aggrid]" со значением `filterModel`
     * Однако на серверной стороне фильтр `AggridFilter` @see \App\Models\Filters\AggridFilter
     * не умеет обрабатывать объединения (joins)
     * По этой причине такие поля исключаются из "filter[aggrid]" и добавляются напрямую,
     * например "filter[cargoType.slug]"
     */
    specialFields(specialFields) {
        if (!specialFields || !specialFields.length) return;

        for (let fieldName of specialFields) {
            try {
                let filterModel = this["filter[aggrid]"][fieldName];
                if (filterModel) {
                    delete this["filter[aggrid]"][fieldName];
                    this[`filter[${fieldName}]`] = filterModel.filter;
                }
            } catch (e) {
            }
        }
    }

    filters(filterModel) {
        // console.log(JSON.stringify(filterModel));

        /**
         * Использование в Laravel:
         * AllowedFilter::custom("aggrid", new AggridFilter, "aggrid", "dirtyHackForAggrid")
         *
         * Без клонирования, в случае изменения, неверно начинает работать пагинация
         * Как воспроизвести: Компании => выбрать тип грузоперевозок "автомобильные" => на второй странице
         * будут железнодорожные
         */
        this["filter[aggrid]"] = _.clone(filterModel);
    }

    pagination(perPage, startRow) {
        this.perPage = perPage;
        this.page = Math.floor(startRow / perPage) + 1;
    }

    /**
     * @link https://spatie.be/docs/laravel-query-builder/v3/features/sorting
     */
    sort(sortModel) {
        let sort = [];

        for (let it of sortModel) {
            sort.push(
                (it.sort === "desc" ? "-" : "") + it.colId
            )
        }

        this.sort = sort.join(",");
    }

}

export default ApiAggridRequestParams;
