<template>

    <div class="form-group">
        <validation-provider :rules="rules" v-slot="{ errors }" :vid="vid" class="d-block">
            <label v-if="name" :for="id" v-html="name" :class="{'text-muted': disabled}"></label>
            <div class="input-group">
                <div v-if="ico" class="input-group-prepend">
                    <span class="input-group-text"><i :class="ico"></i></span>
                </div>
                <div class="custom-file">
                    <input type="file" class="custom-file-input"
                           :id="id"
                           :class="{'is-invalid': errors[0]}"
                           :disabled="disabled"
                           @change="fileSelected"
                    >
                    <label class="custom-file-label" :for="id">{{ label }}</label>
                </div>
            </div>

            <img v-if="hasPreview" ref="preview" class="form-file-thumbnail img-thumbnail mt-2" src="#" alt=""
                 :class="{'invisible': !thumbnailLoaded}"
            />

        </validation-provider>
    </div>

</template>

<script>
let noFileSelected = "Выберите файл";

export default {
    name: "form-file",
    props: ["name", "rules", "vid", "ico", "disabled", "hasPreview", "allowedMimeTypes"],
    data() {
        return {
            id: this.getRandId("file-"),
            label: noFileSelected,
            thumbnailLoaded: false
        };
    },
    methods: {
        fileSelected(event) {
            let file = event.target.files[0];

            if (this.allowedMimeTypes && this.allowedMimeTypes.indexOf(file.type) === -1) {
                this.swalError("Выберите файл другого формата");
                return;
            }

            this.label = file.name;

            let reader = new FileReader();

            reader.onload = (e) => {
                let data = e.target.result;
                if (this.hasPreview) {
                    this.$refs.preview.src = data;
                }
                this.thumbnailLoaded = true;
                this.$emit("change", data.substring(data.indexOf(",") + 1));
            }

            reader.readAsDataURL(file);
        }
    }

}
</script>

<style lang="scss">
.form-file-thumbnail {
    max-width: 100px;
}
</style>
