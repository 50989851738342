<template>
    <h1 v-if="h1" class="m-0 text-dark" v-html="h1"></h1>
</template>

<script>
export default {
    name: "main-header",
    computed: {
        h1() {
            return this.$store.state.meta.h1;
        }
    },
}
</script>
