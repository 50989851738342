<template>
    <div class="modal fade" ref="popup">
        <div class="modal-dialog">
            <div class="modal-content modal-sm">
                <div class="modal-header">
                    <h4 class="modal-title">{{ trans("auth.Session timed out") }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>{{ trans("auth.Your session has timed out") }}</p>

                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="refreshTokenReloadPage"
                               v-model="isReloadPage">
                        <label for="refreshTokenReloadPage" class="custom-control-label font-weight-normal">
                            {{ trans("auth.Reload page") }}
                        </label>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-primary" @click="refreshToken()">
                        {{ trans("auth.Login again") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "refresh-token-modal",
    data() {
        return {
            isReloadPage: true,
            cb: null
        };
    },
    methods: {
        async refreshToken() {
            try {
                await this.$store.dispatch("auth/refreshToken");
                $('.modal').modal('hide');
                $('.modal-backdrop').remove();
                if (this.isReloadPage) {
                    window.location.reload();
                }
            } catch (err) {
                // Не удалось обновить токен, как решение - простое разлогинивание
                $('.modal').modal('hide');
                $('.modal-backdrop').remove();
                this.$store.dispatch("auth/logout");
                if (this.isReloadPage) {
                    window.location.reload();
                }
            }
        },
        show(params) {
            this.cb = params.cb;
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();
        },
    }
}
</script>
