<template>
    <div class="card card-primary">
        <loading-spinner v-if="isProgress"/>
        <div class="card-header">
            <h3 class="card-title">
                <i class="nav-icon fas fa-truck"></i> Автомобиль
            </h3>
        </div>
        <div class="card-body border border-primary">
            <template v-if="isEdit">
                <template v-if="noVehicles">
                    <router-link
                        v-if="can('create-vehicle')"
                        :to="{name: 'vehicle-create'}" class="btn btn-sm btn-primary"><i
                        class="fas fa-plus-square"></i>
                        <span class="d-none d-sm-inline ml-1">
                        {{ trans("vehicle.Create vehicle") }}
                    </span>
                    </router-link>
                </template>
                <template v-else>

                    <form-select v-if="vehicles.length"
                                 :value="ride.vehicle_id" v-on:change="changeSelectedVehicle"
                                 :name="trans('tender.Vehicle profile')" :options="vehiclesList"
                                 :rules="'required'"></form-select>
                    <loading-form-field v-else>{{ trans('tender.Vehicle profile') }}</loading-form-field>

                </template>
            </template>

            <ul class="list-group list-group-condensed list-group-unbordered list-group-flush">
                <li class="list-group-item">
                    <b>{{ trans('vehicle.Car model') }}</b>
                    <span class="float-right">{{ ride.car_model }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('vehicle.Car number') }}</b>
                    <span v-if="ride.car_number" class="float-right">{{ ride.car_number }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('vehicle.Car trailer number') }}</b>
                    <span v-if="ride.car_trailer_number" class="float-right">{{ ride.car_trailer_number }}</span>
                </li>
                <template v-if="isEdit">
                    <li class="list-group-item border-bottom-0">
                        <form-input :value.sync="ride.seal_number" :name="trans('tender.Seal number')"/>
                    </li>
                    <li class="list-group-item">
                        <form-input :value.sync="ride.container_number" :name="trans('tender.Container number')"/>
                    </li>
                </template>
                <template v-else>
                    <li class="list-group-item">
                        <b>{{ trans('tender.Container number') }}</b>
                        <span class="float-right">{{ ride.container_number }}</span>
                    </li>
                    <li class="list-group-item">
                        <b>{{ trans('tender.Seal number') }}</b>
                        <span class="float-right">{{ ride.seal_number }}</span>
                    </li>
                </template>

            </ul>


        </div>
    </div>
</template>

<script>

import VehicleService from "../../../services/VehicleService";

export default {
    props: ["ride", "winBet", "isEdit", "isProgress"],
    data() {
        return {
            vehicles: [],
            noVehicles: false
        }
    },
    computed: {
        vehiclesList() {
            return this.vehicles.map(o => {
                return {id: o.id, label: `${o.car_model} ${o.car_number}`}
            });
        }
    },
    async mounted() {
        try {
            this.loadVihicles();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        async loadVihicles() {
            let response = await VehicleService.getCompanyVehicles(this.winBet.company_id);
            this.vehicles = response.data.data;
            if (!this.vehicles.length) {
                this.noVehicles = true;
            }
        },
        changeSelectedVehicle(vehicle_id) {
            let vehicle = this.vehicles.find(o => o.id === vehicle_id);
            this.ride.vehicle_id = vehicle_id;

            let copyFields = [
                "car_model", "car_number", "car_trailer_number",
            ];
            for (let fieldName of copyFields) {
                this.ride[fieldName] = vehicle[fieldName];
            }
        }
    }
}
</script>
