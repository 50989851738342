<template>
    <div>
        <TenderingSettings></TenderingSettings>
        <TenderingGrid></TenderingGrid>
    </div>
</template>


<script>
import TenderingSettings from "./settings/TenderingSettings";
import TenderingGrid from "./grid/TenderingGrid";

export default {
    components: {TenderingSettings, TenderingGrid}
}
</script>
