import TenderList from "../views/tender/TenderList";
import TenderForm from "../views/tender/TenderForm";
import TenderDetail from "../views/tender/TenderDetail";

let ico = '<i class="fas fa-business-time mr-2"></i>';

export default [
    {
        path: "/tender",
        name: "tender",
        component: TenderList,
        meta: {
            metaTags: {
                title: translate("tender.Tenders"),
                h1: ico + translate("tender.Tenders"),
            },
            breadcrumb: {
                label: translate("tender.Tenders"),
                parent: "home"
            }
        }
    },
    {
        path: "/tender/:id/edit",
        name: "tender-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: TenderForm,
        meta: {
            metaTags: {
                title: translate("Tender"),
                h1: ico + translate("tender.Tender Editing"),
            },
            breadcrumb: {
                label: translate("Tender"),
                parent: "tender"
            },
            rwBc: true
        }
    },
    {
        path: "/tender/:id/clone",
        name: "tender-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: TenderForm,
        meta: {
            metaTags: {
                title: translate("Tender"),
                h1: ico + translate("tender.Tender Cloning"),
            },
            breadcrumb: {
                label: translate("Tender"),
                parent: "tender"
            },
            rwBc: true
        }
    },
    {
        path: "/tender/create",
        name: "tender-create",
        props: route => ({id: 0, view: "create"}),
        component: TenderForm,
        meta: {
            metaTags: {
                title: translate("Tender"),
                h1: ico + translate("tender.Tender Creation"),
            },
            breadcrumb: {
                label: translate("Tender"),
                parent: "tender"
            }
        }
    },
    {
        path: "/tender/:id",
        name: "tender-detail",
        component: TenderDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("Tender"),
                h1: ico + translate("Tender"),
            },
            breadcrumb: {
                label: translate("Tender"),
                parent: "tender"
            },
            rwBc: true
        }
    }
];
