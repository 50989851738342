<template>
    <div>
        <DeleteItemModal ref="deleteModal"></DeleteItemModal>
        <RestoreItemModal ref="restoreModal"></RestoreItemModal>
        <AggridList ref="aggridlist"
                    :componentGridOptions="gridOptions"
                    :excelColumns='["id", "vehicle_type.name", "status.name", "cargo_weight", "cargo_volume", "starting_bid", "auction_step", "win_bet", "has_vat"]'
                    :service="service"
                    :deleted_filter="can('deleted-tender-read')"
        >
            <template v-slot:buttons>
                <router-link
                    v-if="can('create-tender')"
                    :to="{name: 'tender-create'}" class="btn btn-sm btn-primary"><i
                    class="fas fa-plus-square"></i>
                    <span class="d-none d-sm-inline ml-1">
                        {{ trans("Create") }}
                    </span>
                </router-link>
            </template>
        </AggridList>
    </div>
</template>

<script>

/**
 * Этот компонент сделан для административных целей. Торги сделаны в виде отдельного компонента
 * Его нужно доработать
 * Вот что хотелось бы видеть:
 * - расширенные фильтры (несколько статусов)
 * - сохранение "пресетов" фильтров
 * - сохранение настроек колонок (ширина, показ, порядок)
 * - настраиваемые параметры компонента:
 *   - по типу (Тендер, Назначение, Тендер на длительное закрепление, Доставка через ТК
 *   - по типу перевозок (Городские, Междугородние, Перемещения)
 */

import TenderService from "../../services/TenderService";
import FloatingYesNoFilter from "../../aggrid/filters/FloatingYesNoFilter";
import IdCellRenderer from "../../aggrid/cells/IdCellRenderer";
import IdCellParams from "../../aggrid/cells/IdCellParams";
import CRUDCellRenderer from "../../aggrid/cells/CRUDCellRenderer";
import CRUDCellParams from "../../aggrid/cells/CRUDCellParams";
import FloatingSelectFilter from "../../aggrid/filters/FloatingSelectFilter";
import VehicleTypeService from "../../services/VehicleTypeService";
import TenderStatusService from "../../services/TenderStatusService";

export default {
    data() {
        return {
            service: TenderService,
            gridOptions: {
                columnDefs: [
                    {
                        ...{...IdCellParams, sort: "desc"}, cellRendererParams: {
                            getRoute(id) {
                                return {
                                    name: "tender-detail",
                                    params: {id}
                                };
                            }
                        }
                    },
                    {
                        headerName: this.trans("tender.Vehicle type"), field: "vehicle_type_id", width: 400,
                        suppressMenu: true,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingSelectFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                            itemList: async () => {
                                let response = await VehicleTypeService.getListIdNameAll();
                                return response.data.data.map(o => {
                                    return {id: o.id, label: o.name}
                                });
                            }
                        },
                        valueGetter: (prop) => {
                            try {
                                return prop.data.vehicle_type.name;
                            } catch (e) {
                            }
                        }
                    },
                    {
                        headerName: this.trans("tender.Status"), field: "tender_status_id", width: 400,
                        suppressMenu: true,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingSelectFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                            itemList: async () => {
                                let response = await TenderStatusService.getListIdNameAll();
                                return response.data.data.map(o => {
                                    return {id: o.id, label: o.name}
                                });
                            }
                        },
                        valueGetter: (prop) => {
                            try {
                                return prop.data.status.name;
                            } catch (e) {
                            }
                        }
                    },
                    {
                        headerName: this.trans("tender.Cargo weight"), field: "cargo_weight", width: 300,
                        filter: "agNumberColumnFilter",
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("tender.Cargo volume"), field: "cargo_volume", width: 300,
                        filter: "agNumberColumnFilter",
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("tender.Starting bid"), field: "starting_bid", width: 400,
                        filter: "agNumberColumnFilter",
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("tender.Auction step"), field: "auction_step", width: 400,
                        filter: "agNumberColumnFilter",
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("tender.Winning bet"), field: "win_bet", width: 400,
                        floatingFilter: false, suppressMenu: true,
                        sortable: false,
                        valueGetter(prop) {
                            try {
                                let value = prop.data.win_bet;
                                if (!value) return null;
                                return value.bid;
                            } catch (e) {
                            }
                        }
                    },
                    {
                        headerName: this.trans("Has Vat"), field: "has_vat", width: 120, maxWidth: 120,
                        filter: "agNumberColumnFilter",
                        suppressMenu: true,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingYesNoFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true
                        },
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            return prop.value ? '<i class="fas fa-check"></i>' : null;
                        }
                    },
                    {
                        ...CRUDCellParams,
                        cellRendererParams: {
                            canRead: (params) => {
                                return this.can("read-tender", params.data);
                            },
                            readCb: (id) => {
                                this.$router.push({name: "tender-details", params: {id}});
                            },
                            canEdit: (params) => {
                                return this.can("update-tender", params.data);
                            },
                            editCb: (id) => {
                                this.$router.push({name: "tender-edit", params: {id}});
                            },
                            canDelete: (params) => {
                                return this.can("delete-tender", params.data);
                            },
                            canRestore: (params) => {
                                return this.can("restore-tender", params.data);
                            },
                            deleteCb: (id) => {
                                this.$refs.deleteModal.show(async () => {
                                    try {
                                        await TenderService.remove(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("tender.Tender deleted"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Delete Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            },
                            restoreCb: (id) => {
                                this.$refs.restoreModal.show(async () => {
                                    try {
                                        await TenderService.restore(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("tender.Tender restored"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Restore Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            }
                        }
                    }
                ],
                frameworkComponents: {
                    FloatingYesNoFilter, IdCellRenderer, CRUDCellRenderer, FloatingSelectFilter
                }
            }
        }
    }
}
</script>
