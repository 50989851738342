<template>
    <div class="row">

        <HomeStatBlock :num="numCompanies" :bgClass="'bg-info'">
            <div class="icon">
                <i class="fas fa-handshake"></i>
            </div>
            <p>{{ trans("mainPage.Companies number") }}</p>
            <template #link>
                <router-link v-if="can('read-company-list')" :to="{name: 'company'}"
                             class="small-box-footer">
                    {{ trans("Readmore") }} <i class="fas fa-arrow-circle-right"></i>
                </router-link>
            </template>
        </HomeStatBlock>

        <HomeStatBlock :num="numUsers" :bgClass="'bg-success'">
            <div class="icon">
                <i class="fas fa-user"></i>
            </div>
            <p>{{ trans("mainPage.Users number") }}</p>
            <template #link>
                <router-link v-if="can('read-user-list')" :to="{name: 'user'}" class="small-box-footer">
                    {{ trans("Readmore") }} <i class="fas fa-arrow-circle-right"></i>
                </router-link>
            </template>
        </HomeStatBlock>

        <HomeStatBlock :num="numCompleteTenders" :bgClass="'bg-warning'">
            <div class="icon">
                <i class="fas fa-truck"></i>
            </div>
            <p>{{ trans("mainPage.Complete tenders number") }}</p>
            <template #link>
                <router-link v-if="can('read-tender-list')" :to="{name: 'tender'}" class="small-box-footer">
                    {{ trans("Readmore") }} <i class="fas fa-arrow-circle-right"></i>
                </router-link>
            </template>
        </HomeStatBlock>

        <HomeStatBlock :num="numActiveTenders" :bgClass="'bg-danger'">
            <div class="icon">
                <i class="fas fa-comments-dollar"></i>
            </div>
            <p>{{ trans("mainPage.Active tenders number") }}</p>
            <template #link>
                <router-link v-if="can('read-tender-list')" :to="{name: 'tendering'}" class="small-box-footer">
                    {{ trans("Readmore") }} <i class="fas fa-arrow-circle-right"></i>
                </router-link>
            </template>
        </HomeStatBlock>

    </div>
</template>

<script>
import HomeService from "../../services/HomeService";
import Vue from "vue";
import HomeStatBlock from "./HomeStatBlock";

Vue.component("HomeStatBlock", HomeStatBlock);

export default {
    data() {
        return {
            numCompanies: null,
            numUsers: null,
            numCompleteTenders: null,
            numActiveTenders: null
        }
    },
    async mounted() {
        let resp = await HomeService.getStat();
        Object.assign(this._data, resp.data.data);
    }
}
</script>
