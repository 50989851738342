class TenderRequestValidator {
    constructor(tender) {
        this.tender = tender;
    }

    /**
     * Проверки:
     * [+] 1. начало торгов < окончание торгов
     * [+] 2. должна быть хотя бы 1 точка загрузки и 1 разгрузки
     * [+] 3. порядок точек - время должно идти по возрастанию
     * [+] 4. время первой точки должно быть > окончание торгов
     * [+] 5. после выбора точки должны быть заполнены поля Грузоотправитель и другие поля
     * [+] 6. первая точка должна быть загрузка, последняя - разгрузка, а промежуточные - не проверяются
     * [ ] 7. TODO может ли выбранный адрес загружать/разгружать
     */

    validate() {
        let errors = {};
        let tender = this.tender;

        this.checkBetInterval(tender, errors);
        // checkAppointedCompany - проверка выполняется валидатором
        this.checkBidParams(tender, errors);

        try {
            // checkPointNumber - порядок выставляется автоматически, нет необходимости проверки
            this.checkPointCount(tender.route_points);
            this.checkPointHasLoading(tender.route_points);
            this.checkPointHasUnloading(tender.route_points);
            this.checkPointDateOrder(tender.route_points);
            this.checkFirstPointDate(tender.route_points[0], tender.bet_end);
            this.checkPointProps(tender.route_points);
            this.checkStartFinish(tender.route_points);
        } catch (error) {
            errors["route_points"] = error.message;
        }

        return errors;
    }

    checkBetInterval(tender, errors) {
        if (tender.bet_start >= tender.bet_end) {
            let err = translate("tender.Check start and end tender dates");
            errors["bet_start"] = err;
            errors["bet_end"] = err;
        }
    }

    checkBidParams(tender, errors) {
        if (parseInt(tender.starting_bid) <= parseInt(tender.auction_step)) {
            errors["starting_bid"] = translate("tender.The starting bid cannot be less than the auction step");
        }
    }

    checkPointCount(points) {
        if (!points.length) {
            throw new Error(translate("tender.Create a route"));
        }
    }

    checkPointHasLoading(points) {
        if (!points.find(point => {
            return point.is_loading;
        })) {
            throw new Error(translate("tender.There is no loading point"));
        }
    }

    checkPointHasUnloading(points) {
        if (!points.find(point => {
            return point.is_unloading;
        })) {
            throw new Error(translate("tender.There is no unloading point"));
        }
    }

    checkPointDateOrder(points) {
        let prevDate;
        points.forEach(point => {
            let pointDate = point.arrival_date_plan;
            if (prevDate && prevDate >= pointDate) {
                throw new Error(translate("tender.Route points out of chronological order"));
            }
            prevDate = pointDate;
        });
    }

    checkFirstPointDate(point, bet_end) {
        if (point.arrival_date_plan <= bet_end) {
            throw new Error(translate("tender.The beginning of the route must be later than the end of the tender"));
        }
    }

    checkPointProps(points) {
        points.forEach(point => {
            if (!point.src_address_id) {
                throw new Error(translate("tender.Select route point coordinates"));
            }

            if (!point.address) {
                throw new Error(translate("tender.Address field is missing"));
            }

            if (!point.company_id) {
                throw new Error(translate("tender.Company field is missing"));
            }

            if (!point.locality_id) {
                throw new Error(translate("tender.Locality field is missing"));
            }

            if (point.is_loading && point.is_unloading) {
                throw new Error(translate("tender.Select loading or unloading"));
            }
        });
    }

    checkStartFinish(points) {
        if (!_.head(points).is_loading) {
            throw new Error(translate("tender.The route must start with loading"));
        }
        if (!_.last(points).is_unloading) {
            throw new Error(translate("tender.The route must end with unloading"));
        }
    }

}

export default TenderRequestValidator;
