import Vue from "vue";

export default Vue.extend({
    template: `
        <div v-if="hasRoute" @click="click" class="text-blue cursor-pointer">
            <span>{{ params.value }}</span>
        </div>
        <div v-else>
            <span>{{ params.value }}</span>
        </div>
    `,
    computed: {
        hasRoute() {
            return typeof this.params.getRoute === "function";
        }
    },
    methods: {
        click() {
            let route = this.params.getRoute(this.params.value, this.params);
            this.$router.push(route);
        }
    }
});
