<template>
    <span>
    <i v-if="ico" class="nav-icon fas fa-handshake mr-2"></i>
    <router-link v-if="can('read-company', company)"
                 :to="{name: 'company-detail', params: {id: company.id}}"
    >
        {{ company.name }}
    </router-link>
    <span v-else>{{ company.name }}</span>
    </span>
</template>

<script>
export default {
    props: ["company", "ico"]
}
</script>
