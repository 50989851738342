<template>
    <label class="tendering-per-page float-right">{{ trans("Entities per Page") }}:
        <select class="custom-select form-control" :value="value" @change="change"
                :class="{'custom-select-sm': size === 'sm'}"
        >
            <option v-for="i in varians" :value="i">{{ i }}</option>
        </select>
    </label>
</template>

<script>
export default {
    props: {
        size: {
            type: String
        },
        value: {
            type: Number
        },
        varians: {
            type: Array,
            default() {
                return [5, 10, 25, 50];
            }
        }
    },
    methods: {
        change(e) {
            this.$emit("change", parseInt(e.target.value));
        }
    }
}
</script>
