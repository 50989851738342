<template>
    <div></div>
</template>

<script>
export default {
    name: "Logout",
    mounted() {
        this.$store.dispatch("auth/logout");
        try {
            this.$router.push("/");
        } catch (e) {
        }
    }
};
</script>
