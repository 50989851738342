<template>
    <transition name="bounce-11">
        <div class="card card-primary card-outline card-tabs card-settings-block" v-if="showSettings">
            <div class="card-header p-0 pt-1 border-bottom-0">
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="remove" @click="toggleSettings">
                        <i class="fas fa-times"></i>
                    </button>
                </div>

                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="pill" href="#tendering-settings-view" role="tab">
                            Внешний вид
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="pill" href="#tendering-settings-notify" role="tab">
                            Уведомления
                        </a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="tab-pane fade active show" id="tendering-settings-view" role="tabpanel">
                        <SettingsView></SettingsView>
                    </div>
                    <div class="tab-pane fade" id="tendering-settings-notify" role="tabpanel">
                        <UserNotifySettings></UserNotifySettings>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import SettingsView from "./SettingsView";
import {mapActions, mapState} from "vuex";

export default {
    components: {SettingsView},
    mounted() {
        this.initState({id: this.$store.state.auth.user.id});
    },
    computed: {
        ...mapState("tendering", [
            "showSettings"
        ]),
    },
    methods: {
        ...mapActions("userSettings", ["initState"]),
        ...mapActions("tendering", ["toggleSettings"])
    }
}
</script>
