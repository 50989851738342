/**
 * @see \App\Domain\Region\Policies\RegionPolicy
 */
export default {
    "read-region-list": (user) => {
        return user.hasAccess(["region-all-admin", "region-all-read"]);
    },
    "read-region": (user) => {
        return user.hasAccess(["region-all-admin", "region-all-read"]);
    },
    "create-region": (user) => {
        return user.hasAccess(["region-all-admin", "region-all-create"]);
    },
    "update-region": (user) => {
        return user.hasAccess(["region-all-admin", "region-all-update"]);
    },
    "delete-region": (user, region) => {
        if (region.deleted_at) return false;
        return user.hasAccess(["region-all-admin", "region-all-delete"]);
    },
    "restore-region": (user, region) => {
        return !!region.deleted_at && user.hasAccess(["region-all-admin", "region-all-restore"]);
    },
    "deleted-region-read": (user) => {
        return user.hasAccess(["region-all-admin", "region-all-delete"]);
    },
};
