import TenderService from "../services/TenderService";
import TenderClassService from "../services/TenderClassService";
import VehicleTypeService from "../services/VehicleTypeService";
import TenderTypeService from "../services/TenderTypeService";
import AddressService from "../services/AddressService";
import CompanyService from "../services/CompanyService";
import Tender from "../models/Tender";

export const tenderForm = {
    namespaced: true,
    state: {
        view: null,
        isSaving: false, // TODO сейчас поле не используется, сделать его изменение при saveData()
        error: null,
        tender: {},
        tenderTypes: [],
        tenderClasses: [],
        vehicleTypes: [],
        appointedCompanies: [],
        top_loading: [],
        top_unloading: [],
    },
    actions: {
        async initState(context, payload) {
            context.commit("setView", payload.view);
            context.dispatch("loadData", payload);
            context.dispatch("loadTenderClassed");
            context.dispatch("loadVehicleTypes");
            context.dispatch("loadTenderTypes");
            context.dispatch("loadTopAddresses", "loading");
            context.dispatch("loadTopAddresses", "unloading");
            context.dispatch("loadAppointedCompanies");
        },
        async loadData({commit}, payload) {
            switch (payload.view) {
                case "edit":
                case "clone":
                    try {
                        let resp = await TenderService.getEdit(payload.id, payload.withTrashed);
                        commit("setTenderData", new Tender(resp.data.data));
                    } catch (error) {
                        commit("throwError", error);
                    }
                    break;
                case "create":
                    commit("setTenderData", new Tender);
                    break;
            }
        },
        async loadTenderClassed({commit}) {
            try {
                let resp = await TenderClassService.getListIdNameAll();
                commit("setTenderClasses", resp.data.data);
            } catch (error) {
                commit("throwError", error);
            }
        },
        async loadVehicleTypes({commit}) {
            try {
                let resp = await VehicleTypeService.getListIdNameAll();
                commit("setVehicleTypes", resp.data.data);
            } catch (error) {
                commit("throwError", error);
            }
        },
        async loadTenderTypes({commit}) {
            try {
                let resp = await TenderTypeService.getListAll();
                commit("setTenderTypes", resp.data.data);
            } catch (error) {
                commit("throwError", error);
            }
        },
        async loadTopAddresses({commit}, type) {
            try {
                let resp = await AddressService.getTopByType(`is_${type}`);
                commit("setTopAddresses", {type, list: resp.data.data});
            } catch (error) {
                commit("throwError", error);
            }
        },
        async loadAppointedCompanies({commit}) {
            try {
                let resp = await CompanyService.getTransporterCompanies();
                commit("setAppointedCompanies", resp.data.data);
            } catch (error) {
                commit("throwError", error);
            }
        },
        async saveData({state, commit}, requestData) {
            let resp;
            try {
                switch (state.view) {
                    case "edit":
                        resp = await TenderService.patch(state.tender.id, requestData);
                        break;
                    case "clone":
                    case "create":
                        resp = await TenderService.post(requestData);
                }
                return resp.data.data.id;
            } catch (error) {
                commit("throwError", error);
                throw error;
            }
        }
    },
    mutations: {
        setView(state, view) {
            state.view = view;
        },
        setTenderData(state, data) {
            // Выстраиваем точки в порядке БД
            data.route_points = _.sortBy(data.route_points, o => o.point_number);
            state.tender = data;
        },
        throwError(state, error) {
            state.error = error;
        },
        setTenderClasses(state, data) {
            state.tenderClasses = data;
        },
        setVehicleTypes(state, data) {
            state.vehicleTypes = data;
        },
        setTenderTypes(state, data) {
            state.tenderTypes = data;
        },
        setTopAddresses(state, payload) {
            state[`top_${payload.type}`] = payload.list;
        },
        setAppointedCompanies(state, data) {
            state.appointedCompanies = data;
        }
    },
    getters: {
        tenderTypesSelect(state) {
            return state.tenderTypes.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        tenderClassesSelect(state) {
            return state.tenderClasses.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        vehicleTypesSelect(state) {
            return state.vehicleTypes.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        appointedCompaniesSelect(state) {
            return state.appointedCompanies.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        tenderTypeSlug(state) {
            let tenderType = state.tenderTypes.find((o) => {
                return o.id == state.tender.tender_type_id;
            });
            return tenderType && tenderType.slug ? tenderType.slug : null;
        }
    }
};
