<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :loadDefaultData="loadDefaultData"
            :transformResponseCb="transformResponseCb"
            :withTrashed="can('deleted-user-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">

                <form-input :value.sync="item.surname" :name="trans('user.surname')" :rules="'required'"
                            :ico="'fas fa-user'"></form-input>
                <form-input :value.sync="item.name" :name="trans('user.name')" :rules="'required'"
                            :ico="'fas fa-user'"></form-input>
                <form-input :value.sync="item.lastname" :name="trans('user.lastname')" :rules="'required'"
                            :ico="'fas fa-user'"></form-input>
                <form-input :value.sync="item.email" :name="trans('user.email')" :rules="'required|email'"
                            :ico="'fas fa-envelope'"></form-input>

                <template v-if="can('pick-any-user-company')">
                    <form-select v-if="companies.length"
                                 :value="item.company_id" v-on:change="item.company_id = $event"
                                 :name="trans('Company')" :options="companies"
                                 :rules="'required'"></form-select>
                    <loading-form-field v-else>{{ trans('Company') }}</loading-form-field>
                </template>

                <template v-if="can('pick-user-role')">
                    <form-select v-if="roles.length"
                                 :value="item.role_ids" v-on:change="item.role_ids = $event"
                                 :name="trans('User role')" :options="roles"
                                 :multiple="true"
                                 :rules="'required'"></form-select>
                    <loading-form-field v-else>{{ trans('User role') }}</loading-form-field>
                </template>

                <div class="callout callout-info">
                    <h5>{{ trans("Info") }}</h5>
                    <p>{{ trans("user.Registration data will be mailed") }}</p>
                </div>

            </template>
        </UniversalForm>
    </div>
</template>

<script>
import UserService from "../../services/UserService";
import CompanyService from "../../services/CompanyService";
import RoleService from "../../services/RoleService";
import User from "../../models/User"

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            companies: [],
            roles: [],
            service: UserService
        }
    },
    async mounted() {
        try {
            if (this.can('pick-any-user-company')) {
                this.loadCompanies();
            }

            if (this.can('pick-user-role')) {
                this.loadRoles();
            }
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", User.fio(item));
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                surname: "",
                name: "",
                lastname: "",
                email: "",
                company_id: 0,
                role_ids: []
            });
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "user-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "user"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "user-detail", params: {id: vm.item.id}});
        },
        transformResponseCb(response) {
            response.data.data.role_ids = response.data.data.roles ? response.data.data.roles.map(o => o.id) : [];
            if (this.view === "clone") {
                response.data.data.email = "";
                response.data.data.config = null;
            }
        },
        async loadCompanies() {
            let response = await CompanyService.getListIdNameAll();
            this.companies = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        async loadRoles() {
            let response = await RoleService.getListIdNameAll();
            this.roles = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },

    }
}
</script>
