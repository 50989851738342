import CompanyList from "../views/company/CompanyList";
import CompanyForm from "../views/company/CompanyForm";
import CompanyDetail from "../views/company/CompanyDetail";

let ico = '<i class="fas fa-handshake mr-2"></i>';

export default [
    {
        path: "/company",
        name: "company",
        component: CompanyList,
        meta: {
            metaTags: {
                title: translate("company.Companies"),
                h1: ico + translate("company.Companies"),
            },
            breadcrumb: {
                label: translate("company.Companies"),
                parent: "home"
            }
        }
    },
    {
        path: "/company/:id/edit",
        name: "company-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: CompanyForm,
        meta: {
            metaTags: {
                title: translate("Company"),
                h1: ico + translate("company.Company Editing"),
            },
            breadcrumb: {
                label: translate("Company"),
                parent: "company"
            },
            rwBc: true
        }
    },
    {
        path: "/company/:id/clone",
        name: "company-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: CompanyForm,
        meta: {
            metaTags: {
                title: translate("Company"),
                h1: ico + translate("company.Company Cloning"),
            },
            breadcrumb: {
                label: translate("Company"),
                parent: "company"
            },
            rwBc: true
        }
    },
    {
        path: "/company/create",
        name: "company-create",
        props: route => ({id: 0, view: "create"}),
        component: CompanyForm,
        meta: {
            metaTags: {
                title: translate("Company"),
                h1: ico + translate("company.Company Creation"),
            },
            breadcrumb: {
                label: translate("Company"),
                parent: "company"
            }
        }
    },
    {
        path: "/company/:id",
        name: "company-detail",
        component: CompanyDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("Company"),
                h1: ico + translate("company.About Company"),
            },
            breadcrumb: {
                label: translate("Company"),
                parent: "company"
            },
            rwBc: true
        }
    }
];
