import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class DriverService extends AbstractService {

    getRoute() {
        return "driver";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["company.name", "trashed"], omniFilter);
        params.include = "company";

        console.log(JSON.stringify(params));
        return axios.get("driver", {params});
    }

    getListIdNameAll() {
        return axios.get("driver?perPage=1000");
    }

    getCompanyDrivers(company_id) {
        return axios.get(`driver?filter[company_id]=${company_id}&perPage=1000`);
    }

    getDetail(id, withTrashed) {
        let params = {
            include: "company"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`driver/${id}`, {params});
    }

}

export default new DriverService();
