<template>
    <div>
        <DeleteItemModal ref="deleteModal"></DeleteItemModal>
        <RestoreItemModal ref="restoreModal"></RestoreItemModal>
        <AggridList ref="aggridlist"
                    :componentGridOptions="gridOptions"
                    :excelColumns='["id", "surname", "name", "lastname", "email", "company.name", "last_login", "tender_win_cnt", "bets_cnt"]'
                    :service="service"
                    :deleted_filter="can('deleted-user-read')"
        >
            <template v-slot:buttons>
                <router-link
                    v-if="can('create-user')"
                    :to="{name: 'user-create'}" class="btn btn-sm btn-primary"><i
                    class="fas fa-plus-square"></i>
                    <span class="d-none d-sm-inline ml-1">
                        {{ trans("Create") }}
                    </span>
                </router-link>
            </template>
        </AggridList>
    </div>
</template>

<script>

import UserService from "../../services/UserService";
import FloatingYesNoFilter from "../../aggrid/filters/FloatingYesNoFilter";
import IdCellRenderer from "../../aggrid/cells/IdCellRenderer";
import IdCellParams from "../../aggrid/cells/IdCellParams";
import CRUDCellRenderer from "../../aggrid/cells/CRUDCellRenderer";
import CRUDCellParams from "../../aggrid/cells/CRUDCellParams";

export default {
    props: ["company_id"],
    data() {
        UserService.addFilter("company_id", this.company_id ?? null);
        return {
            service: UserService,
            gridOptions: {
                columnDefs: [
                    {
                        ...{...IdCellParams, sort: "desc"}, cellRendererParams: {
                            getRoute(id) {
                                return {
                                    name: "user-detail",
                                    params: {id}
                                };
                            }
                        }
                    },
                    {
                        headerName: this.trans("user.surname"), field: "surname", width: 160,
                        floatingFilter: true,
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            if (prop.data.deleted_at) {
                                return `<del>${prop.value}</del>`;
                            }
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("user.name"), field: "name", width: 160,
                        floatingFilter: true,
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            if (prop.data.deleted_at) {
                                return `<del>${prop.value}</del>`;
                            }
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("user.lastname"), field: "lastname", width: 160,
                        floatingFilter: true,
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            if (prop.data.deleted_at) {
                                return `<del>${prop.value}</del>`;
                            }
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("user.email"), field: "email", width: 180,
                        floatingFilter: true
                    },
                    {
                        headerName: this.trans("user.Company"), field: "company.name", width: 220,
                        floatingFilter: true,
                        suppressMenu: true,
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                        },
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            if (prop.data.company.deleted_at) {
                                return `<del>${prop.value}</del>`;
                            }
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("user.Last login date"), field: "last_login", width: 220,
                        filter: "agDateColumnFilter",
                        floatingFilter: true,
                        valueGetter: (prop) => {
                            try {
                                return prop.data.last_login ? this.momentFullDateTime(prop.data.last_login) : null;
                            } catch (e) {
                            }
                        }
                    },
                    {
                        headerName: this.trans("user.Number of Tenders Won"), field: "tender_win_cnt", width: 180,
                        filter: "agNumberColumnFilter",
                        cellRenderer: (prop) => {
                            return prop.value ? prop.value : "";
                        }
                    },
                    {
                        headerName: this.trans("user.Number of bets"), field: "bets_cnt", width: 180,
                        filter: "agNumberColumnFilter",
                        cellRenderer: (prop) => {
                            return prop.value ? prop.value : "";
                        }
                    },
                    {
                        ...CRUDCellParams,
                        cellRendererParams: {
                            canRead: (params) => {
                                return this.can("read-user", params.data);
                            },
                            readCb: (id) => {
                                this.$router.push({name: "user-detail", params: {id}});
                            },
                            canEdit: (params) => {
                                return this.can("update-user", params.data);
                            },
                            editCb: (id) => {
                                this.$router.push({name: "user-edit", params: {id}});
                            },
                            canDelete: (params) => {
                                return this.can("delete-user", params.data);
                            },
                            canRestore: (params) => {
                                return this.can("restore-user", params.data);
                            },
                            deleteCb: (id) => {
                                this.$refs.deleteModal.show(async () => {
                                    try {
                                        await UserService.remove(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("user.User deleted"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Delete Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            },
                            restoreCb: (id) => {
                                this.$refs.restoreModal.show(async () => {
                                    try {
                                        await UserService.restore(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("user.User restored"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Restore Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            }
                        }
                    },
                ],
                frameworkComponents: {
                    FloatingYesNoFilter, IdCellRenderer, CRUDCellRenderer
                },
            }
        }
    }
}
</script>
