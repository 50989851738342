import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class RegionService extends AbstractService {

    getRoute() {
        return "region";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["trashed"], omniFilter);

        console.log(JSON.stringify(params));
        return axios.get("region", {params});
    }

    getListIdNameAll() {
        return axios.get("region?perPage=1000&fields[region]=id,name");
    }

    getDetail(id, withTrashed) {
        let params = {};
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`region/${id}`, {params});
    }

}

export default new RegionService();
