<template>
    <div v-if="isShow">

        <template v-if="httpStatus === 404">
            <Page404 :rawError="rawError"/>
        </template>

        <template v-else>
            <div class="alert alert-danger alert-dismissible">
                <button v-if="reload" type="button" class="close" aria-hidden="true" @click="reload()"
                        :title="trans('Reload')">
                    <i class="fas fa-sync"></i>
                </button>

                <button v-else type="button" class="close" aria-hidden="true" @click="hide()" :title="trans('Hide')">
                    <i class="fas fa-times"></i>
                </button>

                <template v-for="errors in errorsBundle">
                    <h5 v-if="typeof errors === 'string'" class="mb-0">{{ errors }}</h5>
                    <h5 v-else v-for="error in errors" class="mb-0">{{ error }}</h5>
                </template>

                <div class="small">
                    {{ rawError }}
                </div>
            </div>
        </template>

    </div>
</template>

<script>
export default {
    props: ["reload"],
    name: "form-response-errors",
    data() {
        return {
            isShow: false,
            rawError: null,
            errorsBundle: {},
            httpStatus: null
        };
    },
    methods: {
        getHttpStatus(error) {
            try {
                return error.response.status || null;
            } catch (err) {
                return null;
            }
        },
        show(error) {
            this.httpStatus = this.getHttpStatus(error);
            this.rawError = error;

            try {
                // Если в ответе есть data.errors, показываем табличку с ними
                this.errorsBundle = error.response.data.errors;
                if (typeof this.errorsBundle === "undefined") throw new Error("Empty errors");
            } catch (err) {
                // Иначе обрабатываем как HTTP ошибки (404, etc)
                this.errorsBundle = {
                    data: [this.getHttpError(error)]
                }
            }
            this.isShow = true;
        },
        hide() {
            this.isShow = false;
        }
    }
}
</script>
