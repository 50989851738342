<template>
    <div class="timeline">
        <RouteTimePoint v-for="(point, idx) in points"
                        v-bind="{point, idx, prevPoint: points[idx-1]}"
                        :key="point.id"
                        class="timeline timeline-inverse"
        ></RouteTimePoint>
        <div>
            <i class="far fa-clock bg-gray"></i>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import RouteTimePoint from "./RouteTimePoint";

Vue.component("RouteTimePoint", RouteTimePoint);

export default {
    props: ["points"]
}
</script>
