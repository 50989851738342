import TenderStatusList from "../views/tenderStatus/TenderStatusList";

export default [
    {
        path: "/tender-status",
        name: "tender-status",
        component: TenderStatusList,
        meta: {
            metaTags: {
                title: translate("tenderStatus.Tender statuses"),
                h1: '<i class="icon-status"></i> ' + translate("tenderStatus.Tender statuses"),
            },
            breadcrumb: {
                label: translate("tenderStatus.Tender statuses"),
                parent: "home"
            }
        }
    }
];
