import TenderingService from "../services/TenderingService";

export const tendering = {
    namespaced: true,
    state: {
        tenders: [],
        error: null,
        omniFilter: "",
        showSettings: false,
        perPage: 5,
        currentPage: 1,
        totalRows: 0,
        settings: {
            isCompact: false,
            cols: {
                id: true,
                bet_end: true,
                route_points: true,
                vehicle_type: true,
                cargo_weight_volume: true,
                comment: true,
                auction_step: true,
                has_vat: true,
                my_bet: true,
                min_bid: true,
                new_bid: true,
            }
        }
    },
    actions: {
        async initState(context) {
            context.dispatch("loadData");
        },
        async newTenderFromWS({commit, state}, tender) {
            commit("addTenders", [tender]);
        },
        async closeTenderFromWS({commit, state}, tender) {
            commit("closeTenders", [tender]);
        },
        async updateTenderFromWS({commit, state}, payload) {
            commit("updateTender", payload);
        },
        async loadData({commit, state}) {
            try {
                let resp = await TenderingService.getList({
                    currentPage: state.currentPage,
                    perPage: state.perPage,
                });
                commit("setTenderData", resp);
            } catch (error) {
                commit("throwError", error);
            }
        },
        toggleSettings({commit, state}) {
            commit("setShowSettings", !state.showSettings);
        },
        updateOmniFilter({commit}, e) {
            commit("setOmniFilter", e.target.value);
        },
        async omniFilterReset({commit}) {
            commit("setOmniFilter", "");
        },
        async omniFilterSearch({commit}) {
            // TODO
        },
        setPerPage({commit, dispatch}, number) {
            commit("setPerPage", number);
            dispatch("loadData");
        },
        setCurrentPage({commit, dispatch}, number) {
            commit("setCurrentPage", number);
            dispatch("loadData");
        }
    },
    mutations: {
        setOmniFilter(state, text) {
            state.omniFilter = text;
            // this.omniFilterSearch();
        },
        setShowSettings(state, showSettings) {
            state.showSettings = showSettings;
        },
        setTenderData(state, resp) {
            resp.data.data.forEach(it => {
                it.guiIsShow = true;
                it.guiIsHide = false;
            });
            state.tenders = resp.data.data;
            state.perPage = parseInt(resp.data.meta.per_page);
            state.currentPage = resp.data.meta.current_page;
            state.totalRows = resp.data.meta.total;
        },
        addTenders(state, tenders) {
            if (!tenders.length) return;

            tenders.forEach(it => {
                it.guiIsShow = false;
            });

            state.tenders = state.tenders.concat(tenders);

            setTimeout(() => {
                state.tenders.forEach(it => {
                    it.guiIsShow = true;
                });
            }, 50);
        },
        closeTenders(state, tenders) {
            if (!tenders.length) return;

            let tenderIds = tenders.map(o => o.id);

            state.tenders.map(o => {
                if (tenderIds.indexOf(o.id) !== -1) {
                    o.guiIsHide = true;
                }
            });

            setTimeout(() => {
                state.tenders = state.tenders.filter(o => {
                    return tenderIds.indexOf(o.id) === -1;
                });
            }, 1000);

        },
        updateTender(state, payload) {
            let user = this.state.auth.user;
            let bet = payload.bet;
            let tender = payload.tender;

            state.tenders.forEach(o => {
                if (o.id === tender.id) {
                    o.min_bet = bet;
                    if (user.company_id === bet.company_id) {
                        o.company_min_bet = bet;
                    }
                    o.bet_end = tender.bet_end;
                }
            });
        },
        throwError(state, error) {
            console.log(error)
            state.error = error;
        },
        setPerPage(state, number) {
            state.perPage = number;
        },
        setCurrentPage(state, number) {
            state.currentPage = number;
        },
    },
    getters: {
        tenders(state) {
            // TODO сделать сортировку в соответствии с сортировкой таблицы
            return _.orderBy(state.tenders, ["id"], ["desc"]);
        }
    }
};
