<template>

    <div class="form-group">
        <validation-provider :rules="rules" v-slot="{ errors }" class="d-block">
            <label :for="id" v-html="name"></label>
            <div class="input-group">

                <div class="input-group-prepend"><span class="input-group-text"><i
                    class="far fa-calendar-alt"></i></span></div>

                <VueCtkDateTimePicker v-model="value"
                                      class="form-control p-0"
                                      :class="{'is-invalid': errors[0]}"
                                      @input="$emit('update:value', value)"
                                      :id="id"
                                      :only-date="true"
                                      :no-label="true"
                                      formatted="DD.MM.YYYY"
                                      output-format="YYYY-MM-DD"
                                      :label="trans('Select date')"
                                      :button-now-translation="trans('Now')"
                                      locale="ru_RU">
                </VueCtkDateTimePicker>

            </div>
            <div class="text-danger small mt-1">{{ errors[0] }}</div>
        </validation-provider>
    </div>

</template>

<script>
export default {
    name: "form-date",
    props: ["name", "value", "rules"],
    data() {
        return {
            id: this.getRandId("date-")
        };
    }
}
</script>
