import FeatureFlagList from "../views/featureFlag/FeatureFlagList";

export default [
    {
        path: "/feature-flag",
        name: "feature-flag",
        component: FeatureFlagList,
        meta: {
            metaTags: {
                title: translate("featureFlag.Feature flags"),
                h1: '<i class="fas fa-check-circle"></i> ' + translate("featureFlag.Feature flags"),
            },
            breadcrumb: {
                label: translate("featureFlag.Feature flags"),
                parent: "home"
            }
        }
    }
];
