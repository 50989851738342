import axios from "../axios";

class BetService {

    allBets(tenderId, limit = 500) {
        return axios.get(`/tender/${tenderId}/bet?include=company&sort=created_at&perPage=${limit}`);
    }

    actualBets(tenderId) {
        return axios.get(`/tender/${tenderId}/actual-bet?include=company,creator`);
    }

}

export default new BetService();
