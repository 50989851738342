<template>
    <div>

        <table class="table table-sm">
            <thead>
            <tr>
                <th class="border-0"></th>
                <th class="border-0">Почта</th>
                <th class="border-0"><span class="text-muted">SMS</span></th>
                <th class="border-0"><span class="text-muted">Push</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="event in events">
                <td>
                    {{ event.name }}
                </td>
                <td>
                    <form-checkbox class="mb-0"
                                   :value="getNotify(event.type, 'mail')"
                                   v-on:update:value="setNotify({value: $event, type: event.type, via: 'mail'})"
                    ></form-checkbox>
                </td>
                <td>
                    <i class="fas fa-minus text-muted"></i>
                </td>
                <td>
                    <i class="fas fa-minus text-muted"></i>
                </td>
            </tr>
            </tbody>
        </table>

        <!--        <form-checkbox class="mb-0" :name="'Включить звук для уведомлений'"-->
        <!--                       :value.sync="settings.notify.enable_sound"></form-checkbox>-->

    </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    methods: {
        ...mapActions("userSettings", ["setNotify"]),
        getNotify(event, via) {
            try {
                return this.config.notify.via[event].indexOf(via) !== -1;
            } catch (e) {
                // если null
                return false;
            }
        }
    },
    computed: {
        ...mapGetters("userSettings", [
            "config"
        ])
    },
    data() {
        return {
            events: [
                {name: "Новые тендеры", type: "public_tender_start"},
                {name: "Ставка перебита", type: "bet_outbid"},
                {name: "Изменение статуса тендера", type: "change_tender_status"}
            ]
        };
    }
}
</script>
