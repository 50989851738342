/**
 * Blade-подобная локализация
 *
 * import translate from "./vue-components/translate";
 * Vue.prototype.trans = translate;
 *
 * {{ trans(...) }}
 *
 * @link https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path#6491621
 */

window.translate = function (text) {
    let translated = objectByString(window.i18n, text);
    if (!translated) {
        console.error(`No translation for: ${text}`)
    }
    return translated ? translated : text;
};

export default (string) => {
    return translate(string);
}
