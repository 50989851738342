import PageFromStorage from "../views/page/PageFromStorage"

let ico = '<i class="fas fa-file-alt mr-2"></i>';

export default [
    {
        path: "/page/:slug",
        name: "page",
        component: PageFromStorage,
        props: route => ({slug: route.params.slug}),
        meta: {
            noAuth: true
        }
    }
];
