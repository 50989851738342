<template>
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-md-8" v-if="successMessage">
                <div class="callout callout-success">
                    <h5>{{ trans('Reset Password') }}</h5>
                    <p><i class="icon fas fa-check"></i> {{ successMessage }}</p>
                </div>
                <router-link :to="{name: 'login'}" class="btn btn-primary">{{ trans("Sign in") }}</router-link>
            </div>

            <div v-else class="col-md-8">
                <div class="card">
                    <div class="card-header">{{ trans('Reset Password') }}</div>

                    <div class="card-body">

                        <form-response-errors ref="formResponseErrors"></form-response-errors>

                        <ValidationObserver v-slot="{ handleSubmit }" class="d-block">
                            <form @submit.prevent="handleSubmit(submit)" class="overlay-wrapper">
                                <loading-spinner v-if="isProgress"></loading-spinner>

                                <validation-provider rules="required" v-slot="{ errors }" class="d-block">
                                    <div class="form-group row">
                                        <label for="email" class="col-md-4 col-form-label text-md-right">
                                            {{ trans('E-Mail') }}
                                        </label>

                                        <div class="col-md-6">
                                            <input id="email" type="email"
                                                   v-model="email"
                                                   class="form-control"
                                                   :class="{'is-invalid': errors[0]}"
                                                   :placeholder="trans('E-mail')"
                                            >

                                            <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </validation-provider>

                                <validation-provider rules="required" v-slot="{ errors }" class="d-block">
                                    <div class="form-group row">
                                        <label for="password" class="col-md-4 col-form-label text-md-right">
                                            {{ trans('Password') }}
                                        </label>

                                        <div class="col-md-6">
                                            <input id="password" type="password"
                                                   v-model="password"
                                                   class="form-control"
                                                   :class="{'is-invalid': errors[0]}"
                                                   autocomplete="new-password" autofocus>

                                            <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </validation-provider>

                                <validation-provider rules="required" v-slot="{ errors }" class="d-block">
                                    <div class="form-group row">
                                        <label for="password-confirm" class="col-md-4 col-form-label text-md-right">
                                            {{ trans('Confirm Password') }}
                                        </label>

                                        <div class="col-md-6">
                                            <input id="password-confirm" type="password"
                                                   v-model="password_confirmation"
                                                   class="form-control"
                                                   :class="{'is-invalid': errors[0]}"
                                                   autocomplete="new-password">

                                            <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </validation-provider>


                                <div class="form-group row mb-0">
                                    <div class="col-md-6 offset-md-4">
                                        <button type="submit" class="btn btn-primary">
                                            {{ trans('Reset Password') }}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ["token"],
    data() {
        return {
            isProgress: false,
            successMessage: "",
            email: this.$route.query.email,
            password: "",
            password_confirmation: ""
        };
    },
    mounted() {
        this.redirectIfAuthorized();
    },
    methods: {
        redirectIfAuthorized() {
            if (this.$store.getters["auth/loggedIn"]) {
                this.$router.push({name: "user-detail", params: {id: this.$store.state.auth.user.id}});
            }
        },
        async submit() {
            this.isProgress = true;
            this.$refs.formResponseErrors.hide();
            try {
                let data = {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                };
                let response = await this.$store.dispatch("auth/resetPasswordByToken", data);
                this.successMessage = response.data.message;
            } catch (error) {
                let errorMsg = this.getHttpError(error, this.trans("Failed to save data"));
                this.swalError(errorMsg);
                this.$refs.formResponseErrors.show(error);
            }
            this.isProgress = false;
        }
    }
}
</script>
