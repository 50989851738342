import axios from "../axios";

class AuthService {
    login(credentials) {
        return axios
            .post("auth/getToken", {
                login: credentials.email,
                password: credentials.password
            });
    }

    refreshToken(refresh_token) {
        return axios
            .post("auth/refreshToken", {
                refresh_token: refresh_token
            });
    }

    resetPassword(data) {
        return axios
            .post("password/email", data);
    }

    resetPasswordByToken(data) {
        return axios
            .post("password/reset", data);
    }

    register(data) {
        return axios
            .post("register", data);
    }
}

export default new AuthService();
