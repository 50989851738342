<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :loadDefaultData="loadDefaultData"
            :withTrashed="can('deleted-driver-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">
                <template v-if="can('pick-any-driver-company')">
                    <form-select v-if="companies.length"
                                 :value="item.company_id" v-on:change="item.company_id = $event"
                                 :name="trans('Company')" :options="companies"
                                 :rules="'required'"></form-select>
                    <loading-form-field v-else>{{ trans('Company') }}</loading-form-field>
                </template>

                <form-input :value.sync="item.surname" :name="trans('driver.Surname')"
                            :rules="'required'" :ico="'fas fa-user'"></form-input>

                <form-input :value.sync="item.name" :name="trans('driver.Name')"
                            :rules="'required'" :ico="'fas fa-user'"></form-input>

                <form-input :value.sync="item.lastname" :name="trans('driver.Lastname')"
                            :rules="'required'" :ico="'fas fa-user'"></form-input>

                <div class="row">
                    <div class="col-md-6">
                        <form-mask :value.sync="item.passport_seria" :name="trans('driver.Passport series')"
                                   :mask="'####'" :masked="true"
                                   :rules="'digits:4'" :ico="'fas fa-passport'"></form-mask>
                    </div>
                    <div class="col-md-6">
                        <form-mask :value.sync="item.passport_number" :name="trans('driver.Passport number')"
                                   :mask="'######'" :masked="true"
                                   :rules="'digits:6'" :ico="'fas fa-passport'"></form-mask>
                    </div>
                </div>

                <form-date :value.sync="item.passport_date" :name="trans('driver.Passport issue date')"
                           :rules="'required'"></form-date>

                <form-input :value.sync="item.passport_agency" :name="trans('driver.Passport issue agency')"
                            :rules="'required'" :ico="'fas fa-stamp'"></form-input>

                <form-mask :value.sync="item.phone" :name="trans('driver.Phone')"
                           :mask="'+7 (###) ###-####'" :masked="true"
                           :rules="'required'" :ico="'fas fa-phone'"></form-mask>

                <form-checkbox :value.sync="item.is_blocked" :name="trans('driver.Is blocked')"
                               :rules="{ required: { allowFalse: true } }"></form-checkbox>
            </template>
        </UniversalForm>
    </div>
</template>

<script>
import DriverService from "../../services/DriverService";
import CompanyService from "../../services/CompanyService";
import User from "../../models/User";

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            companies: [],
            service: DriverService
        }
    },
    async mounted() {
        try {
            if (this.can('pick-any-driver-company')) {
                this.loadCompanies();
            }
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", User.fio(item));
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                surname: "",
                name: "",
                lastname: "",
                passport_seria: "",
                passport_number: "",
                phone: null,
                passport_date: null,
                passport_agency: "",
                company_id: null,
                is_blocked: false
            });
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "driver-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "driver"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "driver-detail", params: {id: vm.item.id}});
        },
        async loadCompanies() {
            let response = await CompanyService.getListIdNameAll();
            this.companies = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
    }
}
</script>
