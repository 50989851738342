<template>
    <div class="card card-primary">
        <loading-spinner v-if="isProgress"/>
        <div class="card-header">
            <h3 class="card-title">
                <i class="nav-icon icon-driver"></i> Водитель
            </h3>
        </div>
        <div class="card-body border border-primary">
            <template v-if="isEdit">
                <template v-if="noDrivers">
                    <router-link
                        v-if="can('create-driver')"
                        :to="{name: 'driver-create'}" class="btn btn-sm btn-primary mb-2"><i
                        class="fas fa-plus-square"></i>
                        <span class="d-none d-sm-inline ml-1">
                            {{ trans("driver.Create driver") }}
                        </span>
                    </router-link>
                </template>
                <template v-else>
                    <form-select v-if="drivers.length"
                                 :value="ride.driver_id" v-on:change="changeSelectedDriver"
                                 :name="trans('tender.Driver profile')" :options="driversList"
                                 :rules="'required'"></form-select>
                    <loading-form-field v-else>{{ trans('tender.Driver profile') }}</loading-form-field>
                </template>
            </template>

            <ul class="list-group list-group-condensed list-group-unbordered list-group-flush">
                <li class="list-group-item">
                    <b>{{ trans('driver.Surname') }}</b>
                    <span class="float-right">{{ ride.surname }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('driver.Name') }}</b>
                    <span class="float-right">{{ ride.name }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('driver.Lastname') }}</b>
                    <span class="float-right">{{ ride.lastname }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('driver.Passport series') }}</b>
                    <span class="float-right">{{ ride.passport_seria }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('driver.Passport number') }}</b>
                    <span class="float-right">{{ ride.passport_number }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('driver.Passport issue date') }}</b>
                    <span class="float-right">{{ momentDateYYYY(ride.passport_date) }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('driver.Passport issue agency') }}</b>
                    <span class="float-right">{{ ride.passport_agency }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans('driver.Phone') }}</b>
                    <span class="float-right">{{ ride.phone }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import DriverService from "../../../services/DriverService";
import User from "../../../models/User";

export default {
    props: ["ride", "winBet", "isEdit", "isProgress"],
    data() {
        return {
            drivers: [],
            noDrivers: false
        }
    },
    computed: {
        driversList() {
            return this.drivers.map(o => {
                return {id: o.id, label: User.fio(o)}
            });
        }
    },
    async mounted() {
        try {
            this.loadDrivers();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        async loadDrivers() {
            let response = await DriverService.getCompanyDrivers(this.winBet.company_id);
            this.drivers = response.data.data;
            if (!this.drivers.length) {
                this.noDrivers = true;
            }
        },
        changeSelectedDriver(driver_id) {
            let driver = this.drivers.find(o => o.id === driver_id);
            this.ride.driver_id = driver_id;

            let copyFields = [
                "surname", "name", "lastname",
                "passport_seria", "passport_number", "passport_date", "passport_agency", "phone",
            ];
            for (let fieldName of copyFields) {
                this.ride[fieldName] = driver[fieldName];
            }
        }
    }
}
</script>
