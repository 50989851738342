import Vue from "vue";

export default Vue.extend({
    template: `
        <select :value="currentValue" @change="valueChanged($event)" class="custom-select float-filter-select">
            <option value="-1">{{ trans("(all)") }}</option>
            <option value="1">{{ trans("yes") }}</option>
            <option value="0">{{ trans("no") }}</option>
        </select>
    `,
    data: function () {
        return {
            currentValue: ""
        };
    },
    methods: {
        valueChanged(event) {
            this.currentValue = event.target.value;
            let valueToUse = (this.currentValue === "-1") ? null : this.currentValue;
            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged('equal', valueToUse);
            });
        }
    }
});
