<template>
    <div>
        <DeleteItemModal ref="deleteModal"></DeleteItemModal>
        <RestoreItemModal ref="restoreModal"></RestoreItemModal>
        <AggridList ref="aggridlist"
                    :componentGridOptions="gridOptions"
                    :excelColumns='["id", "name", "inn", "cargoType.slug", "contractorType.slug", "created_at", "has_vat"]'
                    :service="service"
                    :deleted_filter="can('deleted-company-read')"
        >
            <template v-slot:buttons>
                <router-link
                    v-if="can('create-company')"
                    :to="{name: 'company-create'}" class="btn btn-sm btn-primary"><i
                    class="fas fa-plus-square"></i>
                    <span class="d-none d-sm-inline ml-1">
                        {{ trans("Create") }}
                    </span>
                </router-link>
            </template>
        </AggridList>
    </div>
</template>

<script>

/**
 * Если будут проблемы с overflow-hidde, это откючается так:
 * .ag-row.ag-row-focus {
 *    z-index: 1 !important;
 * }
 **/

import CompanyService from "../../services/CompanyService";
import FloatingYesNoFilter from "../../aggrid/filters/FloatingYesNoFilter";
import FloatingSelectFilter from "../../aggrid/filters/FloatingSelectFilter";
import IdCellRenderer from "../../aggrid/cells/IdCellRenderer";
import IdCellParams from "../../aggrid/cells/IdCellParams";
import CRUDCellRenderer from "../../aggrid/cells/CRUDCellRenderer";
import CRUDCellParams from "../../aggrid/cells/CRUDCellParams";
import UserCellParams from "../../aggrid/cells/UserCellParams";
import UserCellRenderer from "../../aggrid/cells/UserCellRenderer";

export default {
    data() {
        return {
            service: CompanyService,
            gridOptions: {
                suppressCellSelection: true,
                columnDefs: [
                    {
                        ...IdCellParams, cellRendererParams: {
                            getRoute(id) {
                                return {
                                    name: "company-detail",
                                    params: {id}
                                };
                            }
                        }
                    },
                    {
                        headerName: this.trans("company.Name"), field: "name", width: 240,
                        sort: "asc",
                        floatingFilter: true,
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            if (prop.data.deleted_at) {
                                return `<del>${prop.value}</del>`;
                            }
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("company.INN"), field: "inn", width: 120,
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("company.Cargo Type"),
                        field: "cargoType.slug",
                        width: 140,
                        sortable: false,
                        valueGetter(prop) {
                            try {
                                let value = prop.data.cargo_type;
                                if (!value) return null;
                                let names = value.map(it => it.name);
                                return names.join(", ");
                            } catch (e) {
                            }
                        },
                        suppressMenu: true,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingSelectFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                            itemList: [
                                {id: "vehicle", label: this.trans("cargoType.car")},
                                {id: "railway", label: this.trans("cargoType.railway")},
                            ]
                        }
                    },
                    {
                        headerName: this.trans("company.Contractor Type"),
                        field: "contractorType.slug",
                        width: 140,
                        sortable: false,
                        filter: "agTextColumnFilter",
                        suppressMenu: true,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingSelectFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                            itemList: [
                                {id: "shipper", label: this.trans("contractorType.shipper")},
                                {id: "transporter", label: this.trans("contractorType.transporter")},
                                {id: "consignee", label: this.trans("contractorType.consignee")},
                            ]
                        },
                        valueGetter(prop) {
                            try {
                                let value = prop.data.contractor_type;
                                if (!value) return null;
                                let names = value.map(it => it.name);
                                return names.join(", ");
                            } catch (e) {
                            }
                        }
                    },
                    {
                        headerName: this.trans("company.Creation Date"), field: "created_at", width: 170,
                        filter: "agDateColumnFilter",
                        floatingFilter: true,
                        valueGetter: (prop) => {
                            try {
                                return prop.data.created_at ? this.momentFullDateTime(prop.data.created_at) : null;
                            } catch (e) {
                            }
                        }
                    },
                    {
                        headerName: this.trans("company.Is Blocked"),
                        field: "is_blocked",
                        width: 110,
                        sortable: false,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingYesNoFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true
                        },
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;

                            let interval = "";
                            if (prop.data.block_start) {
                                interval = this.momentDate(prop.data.block_start);
                                if (prop.data.block_end) {
                                    interval += " - " + this.momentDate(prop.data.block_end);
                                }
                            }

                            return '<i class="fas fa-times text-danger pr-2"></i>' + interval;
                        }
                    },
                    {
                        ...UserCellParams, cellRendererParams: {
                            $router: this.$router
                        }
                    },
                    {
                        headerName: this.trans("Has Vat"), field: "has_vat", width: 120, maxWidth: 120,
                        filter: "agNumberColumnFilter",
                        suppressMenu: true,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingYesNoFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true
                        },
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            return prop.value ? '<i class="fas fa-check"></i>' : null;
                        }
                    },
                    {
                        ...CRUDCellParams,
                        cellRendererParams: {
                            canRead: (params) => {
                                return this.can("read-company", params.data);
                            },
                            readCb: (id) => {
                                this.$router.push({name: "company-detail", params: {id}});
                            },
                            canEdit: (params) => {
                                return this.can("update-company", params.data);
                            },
                            editCb: (id) => {
                                this.$router.push({name: "company-edit", params: {id}});
                            },
                            canDelete: (params) => {
                                return this.can("delete-company", params.data);
                            },
                            canRestore: (params) => {
                                return this.can("restore-company", params.data);
                            },
                            deleteCb: (id) => {
                                this.$refs.deleteModal.show(async () => {
                                    try {
                                        await CompanyService.remove(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("company.Company deleted"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Delete Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            },
                            restoreCb: (id) => {
                                this.$refs.restoreModal.show(async () => {
                                    try {
                                        await CompanyService.restore(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("company.Company restored"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Restore Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            }
                        }
                    },
                ],
                frameworkComponents: {
                    FloatingYesNoFilter, FloatingSelectFilter,
                    IdCellRenderer, CRUDCellRenderer, UserCellRenderer
                },
            }
        }
    }
}
</script>
