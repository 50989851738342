import axios from "../axios";
import AbstractService from "./AbstractService";

class LocalityTypeService extends AbstractService {
    getListIdNameAll() {
        return axios.get("locality-type?perPage=1000&fields[locality_type]=id,name&sort=name");
    }
}

export default new LocalityTypeService();
