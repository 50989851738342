var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[(_vm.successMessage)?_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"callout callout-success"},[_c('h5',[_vm._v(_vm._s(_vm.trans('Reset Password')))]),_vm._v(" "),_c('p',[_c('i',{staticClass:"icon fas fa-check"}),_vm._v(" "+_vm._s(_vm.successMessage))])]),_vm._v(" "),_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{name: 'login'}}},[_vm._v(_vm._s(_vm.trans("Sign in")))])],1):_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.trans('Reset Password')))]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('form-response-errors',{ref:"formResponseErrors"}),_vm._v(" "),_c('ValidationObserver',{staticClass:"d-block",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"overlay-wrapper",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[(_vm.isProgress)?_c('loading-spinner'):_vm._e(),_vm._v(" "),_c('validation-provider',{staticClass:"d-block",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4 col-form-label text-md-right",attrs:{"for":"email"}},[_vm._v("\n                                        "+_vm._s(_vm.trans('E-Mail'))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{'is-invalid': errors[0]},attrs:{"id":"email","type":"email","placeholder":_vm.trans('E-mail')},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"invalid-feedback",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(errors[0]))])]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"d-block",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4 col-form-label text-md-right",attrs:{"for":"password"}},[_vm._v("\n                                        "+_vm._s(_vm.trans('Password'))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{'is-invalid': errors[0]},attrs:{"id":"password","type":"password","autocomplete":"new-password","autofocus":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"invalid-feedback",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(errors[0]))])]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('validation-provider',{staticClass:"d-block",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-4 col-form-label text-md-right",attrs:{"for":"password-confirm"}},[_vm._v("\n                                        "+_vm._s(_vm.trans('Confirm Password'))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-md-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"form-control",class:{'is-invalid': errors[0]},attrs:{"id":"password-confirm","type":"password","autocomplete":"new-password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirmation=$event.target.value}}}),_vm._v(" "),(errors[0])?_c('span',{staticClass:"invalid-feedback",attrs:{"role":"alert"}},[_c('strong',[_vm._v(_vm._s(errors[0]))])]):_vm._e()])])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-group row mb-0"},[_c('div',{staticClass:"col-md-6 offset-md-4"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("\n                                        "+_vm._s(_vm.trans('Reset Password'))+"\n                                    ")])])])],1)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }