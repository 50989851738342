<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :loadDefaultData="loadDefaultData"
            :withTrashed="can('deleted-locality-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">
                <form-input :value.sync="item.name" :name="trans('locality.Name')"
                            :rules="'required'"></form-input>

                <form-select v-if="localityTypes.length"
                             :value="item.type_id" v-on:change="item.type_id = $event"
                             :name="trans('locality.Type')" :options="localityTypes"
                             :rules="'required'"></form-select>
                <loading-form-field v-else>{{ trans('locality.Type') }}</loading-form-field>

                <form-select v-if="regions.length"
                             :value="item.region_id" v-on:change="item.region_id = $event"
                             :name="trans('locality.Region')" :options="regions"
                             :rules="'required'"></form-select>
                <loading-form-field v-else>{{ trans('locality.Region') }}</loading-form-field>
            </template>
        </UniversalForm>
    </div>
</template>

<script>
import LocalityService from "../../services/LocalityService";
import RegionService from "../../services/RegionService";
import LocalityTypeService from "../../services/LocalityTypeService";

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            regions: [],
            localityTypes: [],
            service: LocalityService
        }
    },
    async mounted() {
        try {
            this.loadRegions();
            this.loadLocalityTypes();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", item.name);
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                name: "",
                type_id: null,
                region_id: null
            });
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "locality-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "locality"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "locality-detail", params: {id: vm.item.id}});
        },
        async loadRegions() {
            let response = await RegionService.getListIdNameAll();
            this.regions = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        async loadLocalityTypes() {
            let response = await LocalityTypeService.getListIdNameAll();
            this.localityTypes = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        }
    }
}
</script>
