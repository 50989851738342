import axios from "../axios";

class AbstractService {
    constructor() {
        this.filters = {};
    }

    addFilter(field, value) {
        this.filters[field] = value;
    }

    applyFilters(params) {
        for (let field in this.filters) {
            params[`filter[${field}]`] = this.filters[field];
        }
    }

    getEdit(id, withTrashed) {
        let params = {};
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`${this.getRoute()}/${id}`, {params});
    }

    patch(id, data) {
        return axios.patch(`${this.getRoute()}/${id}`, data);
    }

    post(data) {
        return axios.post(this.getRoute(), data);
    }

    async restore(id) {
        return axios.post(`${this.getRoute()}/${id}/restore`);
    }

    async remove(id) {
        return axios.delete(`${this.getRoute()}/${id}`);
    }
}

export default AbstractService;
