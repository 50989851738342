import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class TenderService extends AbstractService {

    getRoute() {
        return "tender";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["trashed"], omniFilter);
        params.include = "vehicleType,status,winBet";

        console.log(JSON.stringify(params));
        return axios.get("tender", {params});
    }

    getDetail(id, withTrashed) {
        let params = {
            include: "tenderType,tenderClass,routePoints.company,routePoints.locality,routePoints.locality.type,routePoints.srcAddress,vehicleType,status,winBet,winBet.company",
            append: "ride"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }

        return axios.get(`tender/${id}`, {params});
    }

    getEdit(id, withTrashed) {
        let params = {
            include: "routePoints.company,routePoints.locality,routePoints.srcAddress"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`tender/${id}`, {params});
    }

    downloadRequest(id) {
        this.downloadUri(`tender/${id}/files/request`, `request-${id}.pdf`, "application/pdf");
    }

    downloadRouteSheet(id) {
        this.downloadUri(`tender/${id}/files/route-sheet`, `route-sheet-${id}.pdf`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
    }

    downloadUri(uri, fileName, contentType) {
        axios.get(uri, {responseType: "blob"})
            .then(response => {
                const blob = new Blob([response.data], {type: contentType});
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(console.error);
    }
}

export default new TenderService();
