import VehicleList from "../views/vehicle/VehicleList";
import VehicleForm from "../views/vehicle/VehicleForm";
import VehicleDetail from "../views/vehicle/VehicleDetail";

let ico = '<i class="fas fa-truck mr-2"></i>';

export default [
    {
        path: "/vehicle",
        name: "vehicle",
        component: VehicleList,
        meta: {
            metaTags: {
                title: translate("vehicle.Vehicles"),
                h1: ico + translate("vehicle.Vehicles"),
            },
            breadcrumb: {
                label: translate("vehicle.Vehicles"),
                parent: "home"
            }
        }
    },
    {
        path: "/vehicle/:id/edit",
        name: "vehicle-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: VehicleForm,
        meta: {
            metaTags: {
                title: translate("vehicle.Vehicle"),
                h1: ico + translate("vehicle.Vehicle Editing"),
            },
            breadcrumb: {
                label: translate("vehicle.Vehicle"),
                parent: "vehicle"
            },
            rwBc: true
        }
    },
    {
        path: "/vehicle/:id/clone",
        name: "vehicle-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: VehicleForm,
        meta: {
            metaTags: {
                title: translate("vehicle.Vehicle"),
                h1: ico + translate("vehicle.Vehicle Cloning"),
            },
            breadcrumb: {
                label: translate("vehicle.Vehicle"),
                parent: "vehicle"
            },
            rwBc: true
        }
    },
    {
        path: "/vehicle/create",
        name: "vehicle-create",
        props: route => ({id: 0, view: "create"}),
        component: VehicleForm,
        meta: {
            metaTags: {
                title: translate("vehicle.Vehicle"),
                h1: ico + translate("vehicle.Vehicle Creation"),
            },
            breadcrumb: {
                label: translate("vehicle.Vehicle"),
                parent: "vehicle"
            }
        }
    },
    {
        path: "/vehicle/:id",
        name: "vehicle-detail",
        component: VehicleDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("vehicle.Vehicle"),
                h1: ico + translate("vehicle.Vehicle"),
            },
            breadcrumb: {
                label: translate("vehicle.Vehicle"),
                parent: "vehicle"
            },
            rwBc: true
        }
    }
];
