<template>
    <div class="col-lg-3 col-6">
        <div class="small-box" :class="bgClass">
            <div class="inner">
                <h3>
                    <template v-if="num">
                        {{ num }}
                    </template>
                    <template v-else>
                        <i class="spinner-border position-absolute"></i>
                        &nbsp;
                    </template>
                </h3>
                <slot></slot>
            </div>
            <slot name="link">
                <span class="small-box-footer exact-active active">&nbsp;</span>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ["num", "bgClass"]
}
</script>
