import Vue from 'vue';
import Vuex from 'vuex';
import {auth} from './auth.module';
import {meta} from './meta.module';
import {breadcrumbs} from "./breadcrumbs.module";
import {tenderForm} from './tenderForm.module';
import {tendering} from './tendering.module';
import {ws} from './ws.module';
import {userSettings} from './userSettings.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        meta,
        breadcrumbs,
        tenderForm,
        tendering,
        ws,
        userSettings,
    }
});
