import axios from "../axios";

class TenderingService {
    getList(params) {
        return axios.get(`tendering?perPage=${params.perPage}&page=${params.currentPage}&sort=-id`);
    }

    createBet(data) {
        return axios.post(`bet`, data);
    }
}

export default new TenderingService();
