import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class VehicleTypeService extends AbstractService {

    getRoute() {
        return "vehicle-type";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["trashed"], omniFilter);
        params.include = "class";

        console.log(JSON.stringify(params));
        return axios.get("vehicle-type", {params});
    }

    getListIdNameAll() {
        return axios.get("vehicle-type?perPage=1000&fields[vehicle_type]=id,name");
    }

    getDetail(id, withTrashed) {
        let params = {};
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`vehicle-type/${id}`, {params});
    }

}

export default new VehicleTypeService();
