<template>
    <div class="info-box bg-warning">
        <span class="info-box-icon"><i class="far fa-calendar-alt"></i></span>
        <div class="info-box-content">
            <h3 class="info-box-text">
                <slot>{{ trans("This section is under construction") }}</slot>
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: "under-construction"
}
</script>
