<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>
        <template v-if="!isLoading">
            <template v-if="logList.length">

                <div v-for="log in logList" class="post clearfix">
                    <div class="user-block">
                        <i class="fas fa-user fa-2x float-left"></i>
                        <span class="username">
                            <UserLink :user="log.user"></UserLink>
                        </span>
                        <span class="description">
                        <span class="badge badge-info">{{ momentFullDateTime(log.created_at) }}</span>
                            <!-- TODO доработать эту часть, может быть использовать таймлайн /AdminLTE/pages/examples/profile.html  -->
                            <!-- Объект: {{ log.object_type.slug }}-->
                            <!-- Действие: {{ log.action_type.slug }}-->
                        <span :class="{'text-danger': log.result !== 'success'}">{{ log.message }}</span>
                        <template v-if="log.error">
                            Ошибка: {{ log.error }}
                        </template>
                        <span class="badge badge-secondary float-right">IP: {{ log.ip }}</span>
                    </span>
                    </div>
                </div>
            </template>
            <template v-else>
                {{ trans("No results") }}
            </template>
        </template>
    </div>
</template>

<script>
import LogService from "../../services/LogService";
import UserLink from "../partial/links/UserLink";

export default {
    props: ["company_id"],
    components: {UserLink},
    data() {
        return {
            isLoading: true,
            logList: []
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        reload() {
            this.getData();
        },
        async getData() {
            this.$refs.formResponseErrors.hide();
            try {
                let resp = await LogService.getLast(this.company_id);
                this.logList = resp.data.data;
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
            this.isLoading = false;
        }
    }
}
</script>
