import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class CompanyService extends AbstractService {

    getRoute() {
        return "company";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["cargoType.slug", "contractorType.slug", "is_blocked", "trashed"], omniFilter);
        params.include = "users,cargoType,contractorType";
        // params["fields[company]"] = "id,name,inn,created_at,has_vat";

        console.log(JSON.stringify(params));
        return axios.get("company", {params});
    }

    getListIdNameAll() {
        return axios.get("company?perPage=1000&fields[company]=id,name");
    }

    getTransporterCompanies() {
        return axios.get("company?perPage=1000&filter[contractorType.slug]=transporter&fields[company]=id,name");
    }

    getDetail(id, withTrashed) {
        let params = {
            include: "cargo_type,contractor_type",
            append: "win_tender_cnt"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`company/${id}`, {params});
    }

    getEdit(id, withTrashed) {
        let params = {
            include: ["cargoType", "contractorType"]
        };
        if(withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`company/${id}`, {params});
    }

    block(id, data) {
        return axios.post(`company/${id}/block`, data);
    }
}

export default new CompanyService();
