<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :transformResponseCb="transformResponseCb"
            :loadDefaultData="loadDefaultData"
            :withTrashed="can('deleted-company-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">

                <form-input :value.sync="item.name" :name="trans('company.Name')" :rules="'required'"></form-input>
                <form-input :value.sync="item.inn" :name="trans('company.INN')" :rules="'required'"></form-input>

                <form-file v-on:change="item.stamp_base64 = $event"
                           :allowedMimeTypes='["image/jpeg", "image/png", "image/gif"]'
                           :ico="'fas fa-stamp'" :name="'Печать'" :hasPreview="true"></form-file>

                <form-select v-if="contractorTypes.length"
                             :value="item.contractor_type_ids" v-on:change="item.contractor_type_ids = $event"
                             :name="trans('company.Contractor Type')" :options="contractorTypes"
                             :multiple="true"
                             :rules="'required'"></form-select>
                <loading-form-field v-else>{{ trans('company.Contractor Type') }}</loading-form-field>

                <form-select v-if="cargoTypes.length"
                             :value="item.cargo_type_ids" v-on:change="item.cargo_type_ids = $event"
                             :name="trans('company.Cargo Type')" :options="cargoTypes"
                             :multiple="true"
                             :rules="'required'"></form-select>
                <loading-form-field v-else>{{ trans('company.Cargo Type') }}</loading-form-field>

                <div class="row">
                    <div class="col-md-6">
                        <form-input :value.sync="item.contract_number" :name="trans('company.Contract number')"
                                    :rules="''"></form-input>
                    </div>
                    <div class="col-md-6">
                        <form-date :value.sync="item.contract_date" :name="trans('company.Сontract date')"
                                   :rules="''"></form-date>
                    </div>
                </div>

                <form-date :value.sync="item.insurance_policy_end_date"
                           :name="trans('company.Insurance policy end date')" :rules="''"
                ></form-date>

                <template v-if="can('block-company')">
                    <div class="row">
                        <div class="col-md-6">
                            <form-date :value.sync="item.block_start" :name="trans('company.Block start')"
                                       :rules="''"></form-date>
                        </div>
                        <div class="col-md-6">
                            <form-date :value.sync="item.block_end" :name="trans('company.Block end')"
                                       :rules="''"></form-date>
                        </div>
                    </div>

                    <form-input :value.sync="item.block_reason" :name="trans('company.Block reason')"
                                :rules="''"></form-input>
                </template>

                <form-checkbox :value.sync="item.has_vat" :name="trans('Has Vat')"
                               :rules="{ required: { allowFalse: true } }"></form-checkbox>

            </template>
        </UniversalForm>
    </div>
</template>

<script>
import CompanyService from "../../services/CompanyService";
import CargoTypeService from "../../services/CargoTypeService";
import ContractorTypeService from "../../services/ContractorTypeService";

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            cargoTypes: [],
            contractorTypes: [],
            service: CompanyService
        }
    },
    async mounted() {
        try {
            this.loadCargoTypes();
            this.loadContractorTypes();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", item.name);
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "company-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "company"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "company-detail", params: {id: vm.item.id}});
        },
        transformResponseCb(response) {
            response.data.data.cargo_type_ids = response.data.data.cargo_type ? response.data.data.cargo_type.map(o => o.id) : [];
            response.data.data.contractor_type_ids = response.data.data.contractor_type ? response.data.data.contractor_type.map(o => o.id) : [];
            response.data.data.stamp_base64 = null;
            delete response.data.data.stamp;
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                name: "",
                inn: "",
                cargo_type_ids: [],
                contractor_type_ids: [],
                contract_number: "",
                contract_date: "",
                insurance_policy_end_date: "",
                block_start: "",
                block_end: "",
                block_reason: "",
                has_vat: true,
                stamp: null
            });
        },
        async loadCargoTypes() {
            let response = await CargoTypeService.getListIdNameAll();
            this.cargoTypes = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        async loadContractorTypes() {
            let response = await ContractorTypeService.getListIdNameAll();
            this.contractorTypes = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        }
    }
}
</script>
