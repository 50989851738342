<template>
    <div class="search-box input-group input-group-sm my-0 mr-4">
        <input :value="value" type="text"
               v-on:keyup.enter="search"
               @input="input"
               :placeholder="placeholder"
               class="form-control">
        <div class="input-group-append position-relative">
            <button type="submit" class="btn btn-default text-danger"
                    v-show="value"
                    @click="reset">
                <i class="fas fa-times"></i>
            </button>
            <button @click="search" type="submit" class="btn btn-default">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ["value", "placeholder"],
    methods: {
        search() {
            this.$emit("search");
        },
        reset() {
            this.$emit("reset");
        },
        input(e) {
            console.log(e);
            this.$emit("input", e);
        }
    }
}
</script>
