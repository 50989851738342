import Tendering from "../views/tendering/Tendering";

let ico = '<i class="fas icon-bid mr-2"></i>';

export default [
    {
        path: "/tendering",
        name: "tendering",
        component: Tendering,
        meta: {
            metaTags: {
                title: translate("menu.Tendering"),
                h1: ico + translate("menu.Tendering"),
            },
            breadcrumb: {
                label: translate("menu.Tendering"),
                parent: "home"
            }
        }
    }
];
