<template>

    <div>
        <form-response-errors ref="formResponseErrors" />
        <div class="card card-primary card-outline card-ride">
            <div class="card-body">
                <h3 class="profile-username text-center mb-4">
                    <span class="mr-4">Данные водителя и автомобиля</span>
                    <button type="button" class="btn btn-sm btn-warning" @click="edit">
                        <i class="fas fa-pencil-alt"></i> Редактировать
                    </button>
                </h3>

                <SelectDriverForm v-bind="{ride, winBet, isEdit, isProgress}"/>

                <SelectVehicleForm v-bind="{ride, winBet, isEdit, isProgress}"/>

                <div v-if="isEdit">
                    <button type="button" @click="cancel" :disabled="isProgress" class="btn btn-secondary">
                        {{ trans("Cancel") }}
                    </button>

                    <button type="button" @click="submit" :disabled="isProgress" class="btn btn-success float-right">
                        {{ trans("Save") }}
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import RideService from "../../../services/RideService";
import SelectDriverForm from "./SelectDriverForm";
import SelectVehicleForm from "./SelectVehicleForm";

export default {
    components: {SelectVehicleForm, SelectDriverForm},
    props: ["storedRide", "winBet"],
    data() {
        return {
            isEdit: false,
            isProgress: false,
            ride: {}
        }
    },
    mounted() {
        this.setRideFromStored();
    },
    methods: {
        edit() {
            this.setRideFromStored();
            this.isEdit = true;
            this.isProgress = false;
        },
        cancel() {
            this.setRideFromStored();
            this.isEdit = false;
            this.isProgress = false;
            this.$refs.formResponseErrors.hide();
        },
        async submit() {
            this.isProgress = true;
            this.$refs.formResponseErrors.hide();
            try {
                let response = await (this.ride.id ? RideService.patch(this.ride.id, this.ride) : RideService.post(this.ride));

                this.storedRide = response.data.data;
                this.setRideFromStored();

                this.isEdit = false;
                this.swalSuccess(this.trans("Data saved"));
            } catch (error) {
                let errorMsg = this.getHttpError(error, this.trans("Failed to save data"));
                this.swalError(errorMsg);
                this.$refs.formResponseErrors.show(error);
            }
            this.isProgress = false;
        },
        setRideFromStored() {
            /**
             TODO logistic_comment - "Мой комментарий" - комментарий перевозчика
             */
            this.ride = _.clone(this.storedRide ? this.storedRide : this.getDefaultData());
        },
        getDefaultData() {
            return {
                id: null,
                bet_id: this.winBet.id,
                driver_id: null,
                surname: null,
                name: null,
                lastname: null,
                passport_seria: null,
                passport_number: null,
                passport_date: null,
                passport_agency: null,
                phone: null,
                car_model: null,
                car_number: null,
                car_trailer_number: null,
                container_number: null,
                seal_number: null,
            };
        }
    }

}
</script>

<style lang="scss">
.card-ride {
    .card {
        box-shadow: none !important;
    }
}
</style>
