<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :loadDefaultData="loadDefaultData"
            :withTrashed="can('deleted-region-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">
                <form-input :value.sync="item.name" :name="trans('region.Name')"
                            :rules="'required'"></form-input>
            </template>
        </UniversalForm>
    </div>
</template>

<script>
import RegionService from "../../services/RegionService";

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            service: RegionService
        }
    },
    methods: {
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", item.name);
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                name: "",
            });
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "region-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "region"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "region-detail", params: {id: vm.item.id}});
        }
    }
}
</script>
