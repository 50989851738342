"use strict";

import bootstrap from "./bootstrap";

import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueSweetalert2);

/**
 * Vue - методы
 */

import translate from "./vue-components/translate";
import debounce from "./vue-components/debounce";
import {getHttpError, getHttpErrorList} from "./vue-components/getHttpError";
import swalPopups from "./vue-components/swalPopups";

Vue.prototype.trans = translate;
Vue.prototype.debounce = debounce;
Vue.prototype.can = function (action, ...args) {
    return this.$store.getters["auth/userCan"](action, ...args);
}

Vue.prototype.getRandId = (prefix = "control-") => {
    return prefix + (new Date()).getTime() + Math.ceil(Math.random() * 1000000);
};
Vue.prototype.getHttpError = getHttpError;
Vue.prototype.getHttpErrorList = getHttpErrorList;
Vue.prototype.swalSuccess = swalPopups.swalSuccess;
Vue.prototype.swalError = swalPopups.swalError;

/**
 * Moment
 */

import moment from "moment";

Vue.prototype.momentFullDateTime = (date) => date ? moment(date).utc(true).format("DD.MM.YY HH:mm:ss") : null;
Vue.prototype.momentDateTime = (date) => date ? moment(date).utc(true).format("DD.MM.YY HH:mm") : null;
Vue.prototype.momentDate = (date) => date ? moment(date).utc(true).format("DD.MM.YY") : null;
Vue.prototype.momentDateYYYY = (date) => date ? moment(date).utc(true).format("DD.MM.YYYY") : null;
Vue.prototype.momentFullTime = (date) => date ? moment(date).utc(true).format("HH:mm:ss") : null;
Vue.prototype.momentTime = (date) => date ? moment(date).utc(true).format("HH:mm") : null;

/**
 * Validation
 */

import validation from "./validation";
import {ValidationProvider, ValidationObserver} from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

/**
 *  Error pages
 */

import Page404 from "./views/page/Page404";
import Page500 from "./views/page/Page500";

Vue.component("Page404", Page404);
Vue.component("Page500", Page500);

/**
 * Breadcrumbs
 */

import VueBreadcrumbs from "vue-2-breadcrumbs";
import BreadcrumbsTemplate from "./views/partial/Breadcrubs";

Vue.use(VueBreadcrumbs, BreadcrumbsTemplate);

/**
 * Календарь "daterangepicker" заменен на "VueCtkDateTimePicker"
 * @link https://github.com/chronotruck/vue-ctk-date-time-picker
 */

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

/**
 * ag-grid
 * Модули @link https://www.ag-grid.com/javascript-grid-modules/
 */

import {ModuleRegistry} from '@ag-grid-community/core';
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model"
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {LicenseManager} from "@ag-grid-enterprise/core";

ModuleRegistry.registerModules([
    ServerSideRowModelModule,
    ExcelExportModule,
    MenuModule,
    ColumnsToolPanelModule
]);

import {AgGridVue} from "@ag-grid-community/vue";

LicenseManager.prototype.validateLicense = new Function;
Vue.component("AgGridVue", AgGridVue);

/**
 * Компоненты
 */

import store from "./store";
import router from "./router";
import vSelect from "vue-select";
import Login from "./views/auth/Login";
import LeftMenu from "./views/menu/LeftMenu";
import TopMenu from "./views/menu/TopMenu";
import AuthMenu from "./views/menu/AuthMenu";
import MainHeader from "./views/partial/MainHeader";
import Modal from "./views/modals/Modal";
import ConfirmModal from "./views/modals/ConfirmModal";
import DeleteItemModal from "./views/modals/DeleteItemModal";
import RestoreItemModal from "./views/modals/RestoreItemModal";
import RefreshTokenModal from "./views/modals/RefreshTokenModal";
import FormInput from "./views/partial/FormInput";
import FormFile from "./views/partial/FormFile";
import FormNumber from "./views/partial/FormNumber";
import FormMask from "./views/partial/FormMask";
import FormSelect from "./views/partial/FormSelect";
import FormCheckbox from "./views/partial/FormCheckbox";
import FormDate from "./views/partial/FormDate";
import FormDateTime from "./views/partial/FormDateTime";
import FormResponseErrors from "./views/partial/FormResponseErrors";
import FormEditCreate from "./views/partial/FormEditCreate";
import LoadingSpinner from "./views/partial/LoadingSpinner";
import LoadingFormField from "./views/partial/LoadingFormField";
import AggridList from "./views/partial/AggridList";
import UniversalForm from "./views/partial/UniversalForm";
import CompanyLink from "./views/partial/links/CompanyLink";
import UnderConstruction from "./views/UnderConstruction";
import UserNotifySettings from "./views/user/settings/UserNotifySettings";
import UserSettings from "./views/user/UserSettings";

Vue.component("v-select", vSelect);
Vue.component("Modal", Modal);
Vue.component("ConfirmModal", ConfirmModal);
Vue.component("DeleteItemModal", DeleteItemModal);
Vue.component("RestoreItemModal", RestoreItemModal);
Vue.component("RefreshTokenModal", RefreshTokenModal);
Vue.component("FormInput", FormInput);
Vue.component("FormFile", FormFile);
Vue.component("FormNumber", FormNumber);
Vue.component("FormMask", FormMask);
Vue.component("FormCheckbox", FormCheckbox);
Vue.component("FormDate", FormDate);
Vue.component("FormDateTime", FormDateTime);
Vue.component("FormSelect", FormSelect);
Vue.component("FormResponseErrors", FormResponseErrors);
Vue.component("FormEditCreate", FormEditCreate);
Vue.component("LoadingSpinner", LoadingSpinner);
Vue.component("LoadingFormField", LoadingFormField);
Vue.component("AggridList", AggridList);
Vue.component("UniversalForm", UniversalForm);
Vue.component("CompanyLink", CompanyLink);
Vue.component("UnderConstruction", UnderConstruction);
Vue.component("UserNotifySettings", UserNotifySettings);
Vue.component("UserSettings", UserSettings);

window.app = new Vue({
    el: "#js-app",
    store,
    router,
    components: {
        Login,
        LeftMenu,
        TopMenu,
        AuthMenu,
        MainHeader,
    },
    computed: {
        loggedIn() {
            return this.$store.getters["auth/loggedIn"];
        },
        noAuthPage() {
            try {
                return !!this.$route.meta.noAuth;
            } catch (e) {
                return false;
            }
        }
    }
});

/**
 * WS
 */
import listeners from "./websockets/listeners";
