import Vue from "vue";

export default Vue.extend({
    template: `
        <select :value="currentValue" @change="valueChanged($event)" class="custom-select float-filter-select">
            <option value="">{{ trans("(all)") }}</option>
            <option v-for="item in itemList" :value="item.id">{{ item.label }}</option>
        </select>
    `,
    data: function () {
        return {
            itemList: [],
            currentValue: 0
        };
    },
    async beforeMount() {
        this.itemList = typeof this.params.itemList === "function" ? await this.params.itemList() : this.params.itemList;
    },
    methods: {
        valueChanged(event) {
            this.currentValue = event.target.value;
            let valueToUse = (this.currentValue === "") ? null : this.currentValue;
            this.params.parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged('equal', valueToUse);
            });
        }
    }
});
