<template>
    <div class="modal fade" ref="popup">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ trans("company.Company block") }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-if="company">
                    <p>{{ trans("company.Are you sure you want to block the company?") }}</p>
                    <p><label>{{ trans("Company") }}:</label> {{ company.name }} </p>

                    <div class="row">
                        <div class="col-md-6">
                            <form-date :value.sync="blockData.block_start" :name="trans('company.Block start')"
                                       :rules="''"></form-date>
                        </div>
                        <div class="col-md-6">
                            <form-date :value.sync="blockData.block_end" :name="trans('company.Block end')"
                                       :rules="''"></form-date>
                        </div>
                    </div>

                    <form-input :value.sync="blockData.block_reason" :name="trans('company.Block reason')"
                                :rules="''"></form-input>

                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default"
                            data-dismiss="modal" @click="$emit('cancel', blockData)">
                        {{ trans("Cancel") }}
                    </button>
                    <button type="button" class="btn btn-outline-danger"
                            data-dismiss="modal" @click="$emit('block', blockData)">
                        {{ trans("company.To block") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            company: null,
            blockData: {}
        };
    },
    methods: {
        show(company) {
            this.company = company;
            this.blockData = {
                block_start: company.block_start,
                block_end: company.block_end,
                block_reason: company.block_reason
            };
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();
            let modal = $(this.$refs.popup).modal();
        }
    }
}
</script>
