<template>
    <button type="button" class="btn tendering-bell"
            :class="{'tendering-bell--active': isActive}"
            @click="isActive = !isActive"
    >
        <i class="far fa-bell"></i>
    </button>
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        }
    }
}
</script>
