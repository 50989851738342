export const breadcrumbs = {
    namespaced: true,
    state: {
        title: null
    },
    actions: {
        reset({commit}) {
            commit("setTitle", null);
        },
        setTitle({commit}, title) {
            commit("setTitle", title);
        }
    },
    mutations: {
        setTitle(state, title) {
            state.title = title;
        }
    }
};
