import VehicleTypeList from "../views/vehicleType/VehicleTypeList";
import VehicleTypeForm from "../views/vehicleType/VehicleTypeForm";
import VehicleTypeDetail from "../views/vehicleType/VehicleTypeDetail";

let ico = '<i class="fas fa-truck mr-2"></i>';

export default [
    {
        path: "/vehicle-type",
        name: "vehicle-type",
        component: VehicleTypeList,
        meta: {
            metaTags: {
                title: translate("vehicleType.Vehicle types"),
                h1: ico + translate("vehicleType.Vehicle types"),
            },
            breadcrumb: {
                label: translate("vehicleType.Vehicle types"),
                parent: "home"
            }
        }
    },
    {
        path: "/vehicle-type/:id/edit",
        name: "vehicle-type-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: VehicleTypeForm,
        meta: {
            metaTags: {
                title: translate("vehicleType.Vehicle type"),
                h1: ico + translate("vehicleType.Vehicle type Editing"),
            },
            breadcrumb: {
                label: translate("vehicleType.Vehicle type"),
                parent: "vehicle-type"
            },
            rwBc: true
        }
    },
    {
        path: "/vehicle-type/:id/clone",
        name: "vehicle-type-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: VehicleTypeForm,
        meta: {
            metaTags: {
                title: translate("vehicleType.Vehicle type"),
                h1: ico + translate("vehicleType.Vehicle type Cloning"),
            },
            breadcrumb: {
                label: translate("vehicleType.Vehicle type"),
                parent: "vehicle-type"
            },
            rwBc: true
        }
    },
    {
        path: "/vehicle-type/create",
        name: "vehicle-type-create",
        props: route => ({id: 0, view: "create"}),
        component: VehicleTypeForm,
        meta: {
            metaTags: {
                title: translate("vehicleType.Vehicle type"),
                h1: ico + translate("vehicleType.Vehicle type Creation"),
            },
            breadcrumb: {
                label: translate("vehicleType.Vehicle type"),
                parent: "vehicle-type"
            }
        }
    },
    {
        path: "/vehicle-type/:id",
        name: "vehicle-type-detail",
        component: VehicleTypeDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("vehicleType.Vehicle type"),
                h1: ico + translate("vehicleType.Vehicle type"),
            },
            breadcrumb: {
                label: translate("vehicleType.Vehicle type"),
                parent: "vehicle-type"
            },
            rwBc: true
        }
    }
];
