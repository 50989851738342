<template>

    <div class="form-group">
        <validation-provider :rules="rules" v-slot="{ errors }" :vid="vid" class="d-block">
            <div class="custom-control custom-checkbox">
                <input :id="id" type="checkbox"
                       class="custom-control-input"
                       :class="{'is-invalid': errors[0]}"
                       :disabled="disabled"
                       v-model="value" @change="$emit('update:value', value)"
                >
                <label :for="id"
                       class="custom-control-label">{{ name }}</label>
            </div>
            <div class="text-danger small mt-1">{{ errors[0] }}</div>
        </validation-provider>
    </div>


</template>

<script>
export default {
    name: "form-checkbox",
    props: ["name", "value", "rules", "vid", "disabled"],
    data() {
        return {
            id: this.getRandId("checkbox-")
        };
    }
}
</script>
