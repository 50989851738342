/**
 * Правила валидации
 */

import {extend, setInteractionMode} from 'vee-validate';
import {digits, email, min_value, required} from 'vee-validate/dist/rules';

/**
 * @link https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-modes
 */
setInteractionMode("eager");

extend('required', {
    ...required,
    message: translate("This field is required")
});

extend('email', {
    ...email,
    message: translate("Enter your email")
});

extend('digits', {
    ...digits,
    message: translate("Enter the correct value")
});

extend('min_value', {
    ...min_value,
    message: translate("Too small value entered")
});
