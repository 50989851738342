<template>
    <div class="modal fade" ref="popup">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ trans("Delete item") }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot>{{ trans("Are you sure you want to remove this item?") }}</slot>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default"
                            data-dismiss="modal" @click="callCb('cancelCb')">
                        {{ trans("Cancel") }}
                    </button>
                    <button type="button" class="btn btn-outline-danger"
                            data-dismiss="modal" @click="callCb('deleteCb')">
                        {{ trans("Delete") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            deleteCb: null,
            cancelCb: null
        };
    },
    methods: {
        show(deleteCb, cancelCb) {
            this.deleteCb = deleteCb;
            this.cancelCb = cancelCb;
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();
            let modal = $(this.$refs.popup).modal();
        },
        callCb(cbName) {
            if (typeof this[cbName] === "function") {
                this[cbName]();
            }
        }
    }
}
</script>
