import LocalityList from "../views/locality/LocalityList";
import LocalityForm from "../views/locality/LocalityForm";
import LocalityDetail from "../views/locality/LocalityDetail";

let ico = '<i class="fas fa-city mr-2"></i>';

export default [
    {
        path: "/locality",
        name: "locality",
        component: LocalityList,
        meta: {
            metaTags: {
                title: translate("locality.Localities"),
                h1: ico + translate("locality.Localities"),
            },
            breadcrumb: {
                label: translate("locality.Localities"),
                parent: "home"
            }
        }
    },
    {
        path: "/locality/:id/edit",
        name: "locality-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: LocalityForm,
        meta: {
            metaTags: {
                title: translate("locality.Locality"),
                h1: ico + translate("locality.Locality Editing"),
            },
            breadcrumb: {
                label: translate("locality.Locality"),
                parent: "locality"
            },
            rwBc: true
        }
    },
    {
        path: "/locality/:id/clone",
        name: "locality-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: LocalityForm,
        meta: {
            metaTags: {
                title: translate("locality.Locality"),
                h1: ico + translate("locality.Locality Cloning"),
            },
            breadcrumb: {
                label: translate("locality.Locality"),
                parent: "locality"
            },
            rwBc: true
        }
    },
    {
        path: "/locality/create",
        name: "locality-create",
        props: route => ({id: 0, view: "create"}),
        component: LocalityForm,
        meta: {
            metaTags: {
                title: translate("locality.Locality"),
                h1: ico + translate("locality.Locality Creation"),
            },
            breadcrumb: {
                label: translate("locality.Locality"),
                parent: "locality"
            }
        }
    },
    {
        path: "/locality/:id",
        name: "locality-detail",
        component: LocalityDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("locality.Locality"),
                h1: ico + translate("locality.Locality"),
            },
            breadcrumb: {
                label: translate("locality.Locality"),
                parent: "locality"
            },
            rwBc: true
        }
    }
];
