<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
                <router-link to="/" exact class="nav-link">{{ trans("menu.Home") }}</router-link>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
                <router-link to="/tendering" class="nav-link">{{ trans("menu.Tendering") }}
                    <i class="fas fa-circle ws-status"
                       :title="connectionState"
                       :class="{'text-green': connectionState === 'connected', 'text-red': connectionState === 'disconnected' || connectionState === 'unavailable' }"
                    ></i>
                </router-link>
            </li>
        </ul>
        <Notification></Notification>
    </nav>
</template>

<script>
import Notification from "./Notification";
import {mapState} from "vuex";

export default {
    components: {Notification},
    computed: {
        ...mapState("ws", [
            "connectionState"
        ])
    }
}
</script>

<style lang="scss">
.ws-status {
    position: absolute;
    font-size: 0.6rem;
    bottom: 22px;
    right: 5px;
}
</style>
