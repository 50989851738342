<template>
    <span>
        <router-link v-if="can('read-user', user)"
                     :to="{name: 'user-detail', params: {id: user.id}}">
            {{ fio(user) }}
        </router-link>
        <template v-else>
            {{ fio(user) }}
        </template>
    </span>
</template>

<script>
import User from "../../../models/User";

export default {
    props: ["user"],
    methods: {
        fio(user) {
            return User.fio(user);
        }
    }
}
</script>