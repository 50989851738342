/**
 * @see \App\Domain\Vehicle\Policies\VehiclePolicy
 */
export default {
    'create-vehicle': (user) => {
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-create", "vehicle-own-admin", "vehicle-own-create"]);
    },
    'read-vehicle': (user, vehicle) => {
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-read"]) ||
            (user.company_id === vehicle.company_id && user.hasAccess(["vehicle-own-admin", "vehicle-own-read"]));
    },
    'read-vehicle-list': (user) => {
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-read", "vehicle-own-admin", "vehicle-own-read"]);
    },
    'update-vehicle': (user, vehicle) => {
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-update"]) ||
            (user.company_id === vehicle.company_id && user.hasAccess(["vehicle-own-admin", "vehicle-own-update"]));
    },
    'delete-vehicle': (user, vehicle) => {
        if (vehicle.deleted_at) return false;
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-delete"]) ||
            (user.company_id === vehicle.company_id && user.hasAccess(["vehicle-own-admin", "vehicle-own-delete"]));
    },
    'restore-vehicle': (user, vehicle) => {
        return !!vehicle.deleted_at && (user.hasAccess(["vehicle-all-admin", "vehicle-all-restore"]) ||
            (user.company_id === vehicle.company_id && user.hasAccess(["vehicle-own-admin", "vehicle-own-restore"])));
    },
    'pick-any-vehicle-company': (user) => {
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-create"]);
    },
    'block-vehicle': (user) => {
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-block"]);
    },
    'deleted-vehicle-read': (user) => {
        return user.hasAccess(["vehicle-all-admin", "vehicle-all-delete", "vehicle-own-admin", "vehicle-own-delete"]);
    },
};
