import axios from "axios";

const axiosInstance = axios.create();

class PageService {

    fromStorage(slug) {
        return axios.get(`storage/page/${slug}.html`);
    }

}

export default new PageService;
