<template>
    <span class="current-bid"
          :class="{'current-bid-green': green, 'current-bid-red': !green, 'animated-bid-green': isGreen, 'animated-bid-red': isRed}">
        <i class="fas text-sm" :class="{'fa-check': green, 'fa-arrow-down': !green}"></i>
        {{ value }}
    </span>
</template>

<script>
export default {
    data() {
        return {
            interval: false,
            isGreen: false,
            isRed: false
        }
    },
    props: ["value", "green"],
    watch: {
        value() {
            this.animate(this.green);
        }
    },
    methods: {
        animate(isGreen) {
            clearTimeout(this.interval);
            this.isGreen = isGreen;
            this.isRed = !isGreen;
            this.interval = setTimeout(() => {
                this.isGreen = false;
                this.isRed = false;
            }, 500);
        }
    }
}
</script>

<style lang="scss">
.current-bid {
    padding: 0 2px;
}

.current-bid-red {
    color: #dc3545;
}

.current-bid-green {
    color: #28a745;
}

.animated-bid-red {
    animation: bid-red .5s;
}

.animated-bid-green {
    animation: bid-green .5s;
}

@keyframes bid-red {
    0% {
        background: #dc3545;
        color: #fff;
    }
    99% {
        background: #dc3545;
        color: #fff;
    }
    100% {
        background: transparent;
        color: #dc3545;
    }
}

@keyframes bid-green {
    0% {
        background: #28a745;
        color: #fff;
    }
    99% {
        background: #28a745;
        color: #fff;
    }
    100% {
        background: transparent;
        color: #28a745;
    }
}
</style>
