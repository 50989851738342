<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="login-box">
                <div class="card">
                    <div class="card-body login-card-body">
                        <p class="login-box-msg">{{ trans('Sign in') }}</p>

                        <form-response-errors ref="formResponseErrors"></form-response-errors>

                        <ValidationObserver v-slot="{ handleSubmit }">
                            <form name="form" @submit.prevent="handleSubmit(submit)">
                                <validation-provider mode="passive" rules="required|email" v-slot="{ errors }"
                                                     class="d-block">
                                    <div class="input-group mb-3">
                                        <input autofocus autocomplete="email"
                                               class="form-control"
                                               :class="{'is-invalid': errors.length}"
                                               v-model="credentials.email"
                                               :placeholder="trans('E-mail')">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas fa-envelope"></span>
                                            </div>
                                        </div>
                                        <span class="invalid-feedback" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </div>
                                </validation-provider>

                                <validation-provider mode="passive" rules="required" v-slot="{ errors }"
                                                     class="d-block">
                                    <div class="input-group mb-3">
                                        <input :type="showPassword ? 'text' : 'password'"
                                               autocomplete="current-password"
                                               class="form-control"
                                               :class="{'is-invalid': errors.length}"
                                               v-model="credentials.password"
                                               :placeholder="trans('Password')">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas"
                                                      @click="showPassword = !showPassword"
                                                      :class="{'fa-eye-slash': showPassword, 'fa-eye': !showPassword}">
                                                </span>
                                            </div>
                                        </div>
                                        <span class="invalid-feedback" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </div>
                                </validation-provider>

                                <div class="row">
                                    <div class="col-4 offset-8">
                                        <button type="submit"
                                                class="btn btn-primary btn-block"
                                                :disabled="loading"
                                        >{{ trans('Sign in') }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>

                        <div class="social-auth-links text-center mb-3">
                            <p>{{ trans("login.or_sign_up_social") }}</p>
                            <a href="#" class="btn btn-block btn-warning">
                                <i class="fab fa-vk mr-2"></i>
                                {{ trans("auth.Sign in using VK") }}
                            </a>
                            <a href="#" class="btn btn-block btn-warning">
                                <i class="fab fa-yandex-international mr-2"></i>
                                {{ trans("auth.Sign in using Yandex") }}
                            </a>
                        </div>


                        <p class="mb-1">
                            <router-link to="/password/reset">
                                {{ trans('Restore password') }}
                            </router-link>
                        </p>


                        <p class="mb-0">
                            <router-link to="/register">
                                {{ trans('Sign up') }}
                            </router-link>
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    mounted() {
        this.redirectIfAuthorized();
    },
    data() {
        return {
            showPassword: false,
            credentials: {
                email: "",
                password: ""
            },
            loading: false
        };
    },
    methods: {
        redirectIfAuthorized() {
            if (this.$store.getters["auth/loggedIn"] && this.$route.path === "/login") {
                this.$router.push({name: "user-detail", params: {id: this.$store.state.auth.user.id}});
            }
        },
        async submit() {
            this.loading = true;
            this.$refs.formResponseErrors.hide();

            if (this.credentials.email && this.credentials.password) {
                try {
                    await this.$store.dispatch("auth/login", this.credentials);
                    this.redirectIfAuthorized();
                } catch (error) {
                    this.$refs.formResponseErrors.show(error);
                }
                this.loading = false;
            }
        }
    }
};
</script>
