/**
 * @see \App\Domain\Ride\Policies\RidePolicy
 */
export default {
    'read-ride-list': (user) => {
        return user.hasAccess(["ride-all-admin", "ride-all-read", "ride-own-admin", "ride-own-read"]);
    },
    'read-ride': (user, ride) => {
        return user.hasAccess(["ride-all-admin", "ride-all-read"]) ||
            (ride && ride.bet && ride.bet.company_id && user.company_id === ride.bet.company_id && user.hasAccess(["ride-own-admin", "ride-own-read"]));
    },
    'create-ride': (user) => {
        return user.hasAccess(["ride-all-admin", "ride-own-admin"]);
    },
    'update-ride': (user, ride) => {
        return user.hasAccess(["ride-all-admin"]) ||
            (ride && ride.bet && ride.bet.company_id && user.company_id === ride.bet.company_id && user.hasAccess(["ride-own-admin"]));
    },
};
