import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

/**
 * https://laravel.su/docs/5.4/broadcasting
 * https://beyondco.de/docs/laravel-websockets/basic-usage/pusher
 */

class Websockets {
    constructor() {
        this.isInit = false;
    }

    async onInit(cb) {
        await this.init();
        cb(this.Echo);
    }

    async init() {
        if (this.isInit) return;
        if (!this.echoPromise) {
            this.echoPromise = this.echoInit();
        }
        return this.echoPromise;
    }

    async echoInit() {
        return new Promise(async (resolve, reject) => {
            try {
                let config = window.config.ws;

                /*
                    Сниппет параметров инициации @see src/node_modules/laravel-echo/src/connector/connector.ts
                    private _defaultOptions: any = {
                        auth: {
                            headers: {},
                        },
                        authEndpoint: '/broadcasting/auth',
                        broadcaster: 'pusher',
                        csrfToken: null,
                        host: null,
                        key: null,
                        namespace: 'App.Events',
                    };
                */

                // Pusher.logToConsole = true;

                this.Echo = new Echo({
                    broadcaster: "pusher",
                    key: config.key,

                    httpHost: config.wsHost,
                    httpPort: config.wsPort,
                    // activityTimeout: 5000, // чаще пинговать - для проверки переподключения по WS после сетевого сбоя
                    pongTimeout: 10000,

                    wsHost: config.wsHost,
                    wsPath: "/ws",
                    wsPort: config.wsPort,
                    forceTLS: config.forceTLS,
                    disableStats: config.disableStats,
                });

                this.isInit = true;
                resolve();
                // console.log("WS init done ...");
            } catch (err) {
                reject(err);
            }
        });
    }

}

const WsInstance = new Websockets();

export default WsInstance;
