<template>
    <div>
        <DeleteItemModal ref="deleteModal"></DeleteItemModal>
        <RestoreItemModal ref="restoreModal"></RestoreItemModal>
        <AggridList ref="aggridlist"
                    :componentGridOptions="gridOptions"
                    :excelColumns='["id", "name", "class_id"]'
                    :service="service"
                    :deleted_filter="can('deleted-vehicle-read')"
        >
            <template v-slot:buttons>
                <router-link
                    v-if="can('create-vehicle-type')"
                    :to="{name: 'vehicle-type-create'}" class="btn btn-sm btn-primary"><i
                    class="fas fa-plus-square"></i>
                    <span class="d-none d-sm-inline ml-1">
                        {{ trans("Create") }}
                    </span>
                </router-link>
            </template>
        </AggridList>
    </div>
</template>

<script>

import VehicleTypeService from "../../services/VehicleTypeService";
import FloatingSelectFilter from "../../aggrid/filters/FloatingSelectFilter";
import IdCellRenderer from "../../aggrid/cells/IdCellRenderer";
import IdCellParams from "../../aggrid/cells/IdCellParams";
import CRUDCellRenderer from "../../aggrid/cells/CRUDCellRenderer";
import CRUDCellParams from "../../aggrid/cells/CRUDCellParams";
import VehicleClassService from "../../services/VehicleClassService";

export default {
    data() {
        return {
            service: VehicleTypeService,
            gridOptions: {
                columnDefs: [
                    {
                        ...{...IdCellParams, sort: "asc"}, cellRendererParams: {
                            getRoute(id) {
                                return {
                                    name: "vehicle-type-detail",
                                    params: {id}
                                };
                            }
                        }
                    },
                    {
                        headerName: this.trans("vehicleType.Name"), field: "name", width: 400,
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("vehicleType.Type"), field: "class_id", width: 400,
                        suppressMenu: true,
                        floatingFilter: true,
                        floatingFilterComponent: "FloatingSelectFilter",
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                            itemList: async () => {
                                let response = await VehicleClassService.getListIdNameAll();
                                return response.data.data.map(o => {
                                    return {id: o.id, label: o.name}
                                });
                            }
                        },
                        valueGetter: (prop) => {
                            try {
                                return prop.data.class.name;
                            } catch (e) {
                            }
                        }
                    },
                    {
                        ...CRUDCellParams,
                        cellRendererParams: {
                            canRead: (params) => {
                                return this.can("read-vehicle-type", params.data);
                            },
                            readCb: (id) => {
                                this.$router.push({name: "vehicle-type-details", params: {id}});
                            },
                            canEdit: (params) => {
                                return this.can("update-vehicle-type", params.data);
                            },
                            editCb: (id) => {
                                this.$router.push({name: "vehicle-type-edit", params: {id}});
                            },
                            canDelete: (params) => {
                                return this.can("delete-vehicle-type", params.data);
                            },
                            canRestore: (params) => {
                                return this.can("restore-vehicle-type", params.data);
                            },
                            deleteCb: (id) => {
                                this.$refs.deleteModal.show(async () => {
                                    try {
                                        await VehicleTypeService.remove(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("vehicleType.Vehicle type deleted"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Delete Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            },
                            restoreCb: (id) => {
                                this.$refs.restoreModal.show(async () => {
                                    try {
                                        await VehicleTypeService.restore(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("vehicleType.Vehicle type restored"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Restore Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            }
                        }
                    },

                ],
                frameworkComponents: {
                    FloatingSelectFilter, IdCellRenderer, CRUDCellRenderer
                }
            }
        }
    }
}
</script>
