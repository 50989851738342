import AddressList from "../views/address/AddressList";
import AddressForm from "../views/address/AddressForm";
import AddressDetail from "../views/address/AddressDetail";

export default [
    {
        path: "/address",
        name: "address",
        component: AddressList,
        meta: {
            metaTags: {
                title: translate("address.Address"),
                h1: '<i class="fas fa-map-marked-alt"></i> ' + translate("address.Addresses"),
            },
            breadcrumb: {
                label: translate("address.Address"),
                parent: "home"
            }
        }
    },
    {
        path: "/address/:id/edit",
        name: "address-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: AddressForm,
        meta: {
            metaTags: {
                title: translate("address.Address"),
                h1: '<i class="fas fa-map-marked-alt"></i> ' + translate("address.Address Editing"),
            },
            breadcrumb: {
                label: translate("address.Address"),
                parent: "address"
            },
            rwBc: true
        }
    },
    {
        path: "/address/:id/clone",
        name: "address-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: AddressForm,
        meta: {
            metaTags: {
                title: translate("address.Address"),
                h1: '<i class="fas fa-map-marked-alt"></i> ' + translate("address.Address Cloning"),
            },
            breadcrumb: {
                label: translate("address.Address"),
                parent: "address"
            },
            rwBc: true
        }
    },
    {
        path: "/address/create",
        name: "address-create",
        props: route => ({id: 0, view: "create"}),
        component: AddressForm,
        meta: {
            metaTags: {
                title: translate("address.Address"),
                h1: '<i class="fas fa-map-marked-alt"></i> ' + translate("address.Address Creation"),
            },
            breadcrumb: {
                label: translate("address.Address"),
                parent: "address"
            }
        }
    },
    {
        path: "/address/:id",
        name: "address-detail",
        component: AddressDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("address.Address"),
                h1: '<i class="fas fa-map-marked-alt"></i> ' + translate("address.Address"),
            },
            breadcrumb: {
                label: translate("address.Address"),
                parent: "address"
            },
            rwBc: true
        }
    }
];
