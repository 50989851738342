import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class UserService extends AbstractService {

    getRoute() {
        return "user";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["company.name", "trashed"], omniFilter);
        params.include = "company";

        this.applyFilters(params);

        console.log(JSON.stringify(params));
        return axios.get("user", {params});
    }

    getDetail(id, withTrashed) {
        let params = {
            include: "company,roles"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`user/${id}`, {params});
    }

    getConfig(id) {
        return axios.get(`user/${id}`);
    }

    setConfig(id, config) {
        return axios.patch(`user/${id}`, {config});
    }

    getEdit(id, withTrashed) {
        let params = {include: "roles"};
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`user/${id}`, {params});
    }

    // Отключено по причине 404 при редактировании удаленной записи, так как не используется
    // параметр withTrashed. Все равно идет переход на детальную форму
    // async patch(id, data) {
    //     /**
    //      * Патч-запрос не возвращает roles, поэтому еще раз загружаем данные
    //      */
    //     await axios.patch(`user/${id}`, data);
    //     return this.getEdit(id);
    // }

}

export default new UserService();
