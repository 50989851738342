<template>
    <div>
        <form-checkbox :name="'Компактный вид'" :value.sync="settings.isCompact"></form-checkbox>

        <h5>Показ колонок</h5>

        <div class="row">
            <div class="col-md-3">

                <form-checkbox class="mb-0" :name="'ID'"
                               :value.sync="settings.cols.id"></form-checkbox>
                <form-checkbox class="mb-0" :name="'Окончание торгов'"
                               :value.sync="settings.cols.bet_end"></form-checkbox>
                <form-checkbox class="mb-0" :name="'Тип ТС'"
                               :value.sync="settings.cols.vehicle_type"></form-checkbox>
                <form-checkbox class="mb-0" :name="'Общий вес и объем'"
                               :value.sync="settings.cols.cargo_weight_volume"></form-checkbox>

            </div>
            <div class="col-md-3">
                <form-checkbox class="mb-0" :name="'Примечание'"
                               :value.sync="settings.cols.comment"></form-checkbox>
                <form-checkbox class="mb-0" :name="'Шаг торга'"
                               :value.sync="settings.cols.auction_step"></form-checkbox>
                <form-checkbox class="mb-0" :name="'С НДС'"
                               :value.sync="settings.cols.has_vat"></form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    computed: {
        ...mapState("tendering", [
            "settings"
        ]),
    },
}
</script>

