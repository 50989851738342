<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :loadDefaultData="loadDefaultData"
            :withTrashed="can('deleted-vehicle-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">
                <template v-if="can('pick-any-vehicle-company')">
                    <form-select v-if="companies.length"
                                 :value="item.company_id" v-on:change="item.company_id = $event"
                                 :name="trans('Company')" :options="companies"
                                 :rules="'required'"></form-select>
                    <loading-form-field v-else>{{ trans('Company') }}</loading-form-field>
                </template>

                <form-input :value.sync="item.car_model" :name="trans('vehicle.Car model')"
                            :rules="'required'"></form-input>

                <form-input :value.sync="item.car_number" :name="trans('vehicle.Car number')"
                            :rules="'required'"></form-input>

                <form-input :value.sync="item.car_trailer_number" :name="trans('vehicle.Car trailer number')"
                ></form-input>

            </template>
        </UniversalForm>
    </div>
</template>

<script>
import VehicleService from "../../services/VehicleService";
import CompanyService from "../../services/CompanyService";

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            companies: [],
            service: VehicleService
        }
    },
    async mounted() {
        try {
            if (this.can('pick-any-vehicle-company')) {
                this.loadCompanies();
            }
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", item.car_number);
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                company_id: null,
                car_model: "",
                car_number: "",
                car_trailer_number: ""
            });
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "vehicle-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "vehicle"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "vehicle-detail", params: {id: vm.item.id}});
        },
        async loadCompanies() {
            let response = await CompanyService.getListIdNameAll();
            this.companies = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
    }
}
</script>
