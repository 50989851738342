import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class VehicleService extends AbstractService {

    getRoute() {
        return "vehicle";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["company.name", "trashed"], omniFilter);
        params.include = "company";

        console.log(JSON.stringify(params));
        return axios.get("vehicle", {params});
    }

    getDetail(id, withTrashed) {
        let params = {
            include: "company"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`vehicle/${id}`, {params});
    }

    getCompanyVehicles(company_id) {
        return axios.get(`vehicle?filter[company_id]=${company_id}&perPage=1000`);
    }

}

export default new VehicleService();
