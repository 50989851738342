import axios from "../axios";
import AbstractService from "./AbstractService";

class VehicleClassService extends AbstractService {
    getListIdNameAll() {
        return axios.get("vehicle-class?perPage=1000&fields[vehicle_class]=id,name");
    }
}

export default new VehicleClassService();
