<template>
    <div class="modal fade" ref="popup">
        <div class="modal-dialog">
            <div class="modal-content" :class="getClassModal()">
                <div class="modal-header">
                    <h4 class="modal-title">{{ item.title }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ item.body }}
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn" :class="getClassOk()" data-dismiss="modal">
                        {{ trans("Close") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            item: {},
            type: "error",
            cb: null,
            hiddenCbSettedOnce: false
        };
    },
    methods: {
        default(params) {
            this.type = "default";
            this.show(params);
        },
        primary(params) {
            this.type = "primary";
            this.show(params);
        },
        secondary(params) {
            this.type = "secondary";
            this.show(params);
        },
        info(params) {
            this.type = "info";
            this.show(params);
        },
        warning(params) {
            this.type = "warning";
            this.show(params);
        },
        success(params) {
            this.type = "success";
            this.show(params);
        },
        danger(params) {
            this.type = "danger";
            this.show(params);
        },
        show(params) {
            this.item = params.item;
            this.cb = params.cb;
            $('.modal').modal('hide');
            $('.modal-backdrop').remove();

            let modal = $(this.$refs.popup).modal();

            // вызов hidden.bs.modal добавляет cb каждый раз, поэтому ставим cb только один раз

            if (!this.hiddenCbSettedOnce && typeof this.cb === "function") {
                this.hiddenCbSettedOnce = true;
                modal.on("hidden.bs.modal", () => {
                    this.cb();
                });
            }
        },
        getClassModal() {
            return {
                'default': '',
                'primary': 'bg-primary',
                'secondary': 'bg-secondary',
                'info': 'bg-info',
                'warning': 'bg-warning',
                'success': 'bg-success',
                'danger': 'bg-danger',
            }[this.type];
        },
        getClassOk() {
            return {
                'default': 'btn-default',
                'primary': 'btn-outline-light',
                'secondary': 'btn-outline-light',
                'info': 'btn-outline-light',
                'warning': 'btn-outline-dark',
                'success': 'btn-outline-light',
                'danger': 'btn-outline-light',
            }[this.type];
        }
    }
}
</script>
