export const meta = {
    namespaced: true,
    state: {
        h1: translate("Tenders Online"),
        title: translate("Tenders Online"),
    },
    actions: {
        set({commit}, params) {
            commit("setMeta", params);
        }
    },
    mutations: {
        setMeta(state, params) {
            document.title = params.title;
            state.h1 = params.h1;
            state.title = params.title;
        }
    },
    getters: {}
};
