<template>
    <div>
        <ConfirmModal ref="confirmModal">{{ confirmationText }}</ConfirmModal>
        <form-response-errors ref="formResponseErrors"/>
        <AggridList ref="aggridlist"
                    :componentGridOptions="gridOptions"
                    :excelColumns='["id", "title", "feature", "description", "active_at"]'
                    :service="service"
        >
        </AggridList>
    </div>
</template>

<script>

import FloatingYesNoFilter from "../../aggrid/filters/FloatingYesNoFilter";
import FeatureFlagService from "../../services/FeatureFlagService";
import IdCellParams from "../../aggrid/cells/IdCellParams";
import FFTurnParams from "../../aggrid/cells/FFTurnParams";
import FFCellRenderer from "../../aggrid/cells/FFCellRenderer";

export default {
    data() {
        return {
            confirmationText: null,
            service: FeatureFlagService,
            gridOptions: {
                columnDefs: [
                    {
                        ...{...IdCellParams, cellRenderer: null}
                    },
                    {
                        headerName: this.trans("featureFlag.Feature"), field: "feature", width: 400,
                        sort: "asc",
                        floatingFilter: true,
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("featureFlag.Title"), field: "title", width: 400,
                        floatingFilter: true,
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("featureFlag.Description"), field: "description", width: 400,
                        floatingFilter: true,
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("featureFlag.Active at"), field: "active_at", width: 220,
                        valueGetter: (prop) => {
                            try {
                                return prop.data.active_at ? this.momentFullDateTime(prop.data.active_at) : null;
                            } catch (e) {
                            }
                        }
                    },
                    {
                        ...FFTurnParams,
                        headerName: this.trans("featureFlag.Accessible"),
                        cellRendererParams: {
                            canTurn: (params) => {
                                return this.can("update-feature-flag", params.data);
                            },
                            changeAccessible: (accessible, params) => {
                                this.confirmationText = this.trans(accessible ? "Turn on" : "Turn off") + ": " + params.data.title;
                                this.$refs.confirmModal.show(async () => {
                                    try {
                                        await FeatureFlagService.changeAccessible(params.data.id, accessible);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("Successfully"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error);
                                        this.$refs.formResponseErrors.show(error);
                                    }
                                });
                            }
                        }
                    }
                ],
                frameworkComponents: {
                    FloatingYesNoFilter, FFCellRenderer
                }
            }
        }
    }
}
</script>