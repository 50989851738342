<template>

    <div class="form-group">
        <validation-provider :rules="rules" v-slot="{ errors }" :vid="vid" class="d-block">
            <label v-if="name" :for="id" v-html="name" :class="{'text-muted': disabled}"></label>
            <div class="input-group">
                <div v-if="ico" class="input-group-prepend">
                    <span class="input-group-text"><i :class="ico"></i></span>
                </div>
                <input type="text" class="form-control"
                       :id="id"
                       :class="{'is-invalid': errors[0]}"
                       :disabled="disabled"
                       v-model="value" @input="$emit('update:value', value)"
                >
            </div>
            <div class="text-danger small mt-1">{{ errors[0] }}</div>
        </validation-provider>
    </div>

</template>

<script>
export default {
    name: "form-input",
    props: ["name", "value", "rules", "vid", "ico", "disabled"],
    data() {
        return {
            id: this.getRandId("input-")
        };
    }
}
</script>
