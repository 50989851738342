import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class AddressService extends AbstractService {

    getRoute() {
        return "address";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["company.name", "locality.name", "locality.region.name", "trashed"], omniFilter);
        params.include = "company,locality,locality.region";

        console.log(JSON.stringify(params));
        return axios.get("address", {params});
    }

    getDetail(id, withTrashed) {
        let params = {
            include: "company,locality"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`address/${id}`, {params});
    }

    /**
     * Поиск адресов по тексту и типу (загрузка , разгрузка)
     *
     * @param text
     * @param type is_loading, is_unloading
     * @param limit
     * @returns {Promise<AxiosResponse<T>>}
     */
    searchByTypeAndText(text, type, limit = 20) {
        return axios.get(`address?include=company,locality&filter[${type}]=true&filter[omniFilter]=${text}&perPage=${limit}`);
    }

    /**
     * Получение списка популярных адресов
     *
     * @param type is_loading, is_unloading
     * @param limit
     */
    getTopByType(type, limit = 20) {
        return axios.get(`address?include=company,locality&sort=-id&filter[${type}]=true&perPage=${limit}`);
    }

}

export default new AddressService();
