/**
 * @see \App\Domain\Locality\Policies\LocalityPolicy
 */
export default {
    "read-locality-list": (user) => {
        return user.hasAccess(["locality-all-admin", "locality-all-read"]);
    },
    "read-locality": (user) => {
        return user.hasAccess(["locality-all-admin", "locality-all-read"]);
    },
    "create-locality": (user) => {
        return user.hasAccess(["locality-all-admin", "locality-all-create"]);
    },
    "update-locality": (user) => {
        return user.hasAccess(["locality-all-admin", "locality-all-update"]);
    },
    "delete-locality": (user, locality) => {
        if (locality.deleted_at) return false;
        return user.hasAccess(["locality-all-admin", "locality-all-delete"]);
    },
    "restore-locality": (user, locality) => {
        return !!locality.deleted_at && user.hasAccess(["locality-all-admin", "locality-all-restore"]);
    },
    "deleted-locality-read": (user) => {
        return user.hasAccess(["locality-all-admin", "locality-all-delete"]);
    },
};
