<template>

    <div class="form-group">
        <validation-provider :rules="rules" :vid="vid" v-slot="{ errors }" class="d-block">
            <label :for="id" v-html="name"></label>
            <div class="input-group">
                <div v-if="ico" class="input-group-prepend">
                    <span class="input-group-text"><i :class="ico"></i></span>
                </div>
                <input type="number" class="form-control"
                       :id="id"
                       :min="min"
                       :max="max"
                       :step="step"
                       :class="{'is-invalid': errors[0]}"
                       v-model="value" @input="$emit('update:value', value)"
                >
            </div>
            <div class="text-danger small mt-1">{{ errors[0] }}</div>
        </validation-provider>
    </div>

</template>

<script>
export default {
    name: "form-number",
    props: ["name", "value", "rules", "ico", "vid", "min", "max", "step"],
    data() {
        return {
            id: this.getRandId("input-")
        };
    }
}
</script>
