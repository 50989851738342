<template>
    <div>
        <AggridList
            :componentGridOptions="gridOptions"
            :excelColumns='["id", "name", "slug"]'
            :service="service"
        >
        </AggridList>
    </div>
</template>

<script>

import TenderStatusService from "../../services/TenderStatusService";
import IdCellRenderer from "../../aggrid/cells/IdCellRenderer";
import IdCellParams from "../../aggrid/cells/IdCellParams";

export default {
    data() {
        return {
            service: TenderStatusService,
            gridOptions: {
                columnDefs: [
                    {
                        ...{...IdCellParams, sort: "asc"}
                    },
                    {
                        headerName: this.trans("tenderStatus.Name"), field: "name", width: 400,
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("tenderStatus.Slug"), field: "slug", width: 400,
                        floatingFilter: true,
                    },
                ],
                frameworkComponents: {
                    IdCellRenderer
                },
            }
        }
    }
}
</script>
