import RegionList from "../views/region/RegionList";
import RegionForm from "../views/region/RegionForm";
import RegionDetail from "../views/region/RegionDetail";

let ico = '<i class="fas fa-globe mr-2"></i>';

export default [
    {
        path: "/region",
        name: "region",
        component: RegionList,
        meta: {
            metaTags: {
                title: translate("Regions"),
                h1: ico + translate("Regions"),
            },
            breadcrumb: {
                label: translate("Regions"),
                parent: "home"
            }
        }
    },
    {
        path: "/region/:id/edit",
        name: "region-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: RegionForm,
        meta: {
            metaTags: {
                title: translate("Region"),
                h1: ico + translate("region.Region Editing"),
            },
            breadcrumb: {
                label: translate("Region"),
                parent: "region"
            },
            rwBc: true
        }
    },
    {
        path: "/region/:id/clone",
        name: "region-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: RegionForm,
        meta: {
            metaTags: {
                title: translate("Region"),
                h1: ico + translate("region.Region Cloning"),
            },
            breadcrumb: {
                label: translate("Region"),
                parent: "region"
            },
            rwBc: true
        }
    },
    {
        path: "/region/create",
        name: "region-create",
        props: route => ({id: 0, view: "create"}),
        component: RegionForm,
        meta: {
            metaTags: {
                title: translate("Region"),
                h1: ico + translate("region.Region Creation"),
            },
            breadcrumb: {
                label: translate("Region"),
                parent: "region"
            }
        }
    },
    {
        path: "/region/:id",
        name: "region-detail",
        component: RegionDetail,
        props: true,
        meta: {
            metaTags: {
                title: translate("Region"),
                h1: ico + translate("Region"),
            },
            breadcrumb: {
                label: translate("Region"),
                parent: "region"
            },
            rwBc: true
        }
    }
];
