import Vue from "vue";

export default Vue.extend({
    template: `
        <div class="custom-control custom-checkbox mt-3"
             @click="canTurn && params.changeAccessible(!params.data.accessible, params)"
             :title="trans(params.data.accessible ? 'Turn off': 'Turn on')">
        <label class="custom-control-label"
               :class="{checked: params.data.accessible, 'cursor-pointer': canTurn}"></label>
        </div>
    `,
    computed: {
        canTurn() {
            try {
                return this.params.canTurn(this.params);
            } catch (e) {
                return false;
            }
        }
    }
});
