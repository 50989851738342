import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";

class TenderStatusService {
    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, [], omniFilter);
        params.include = "";

        console.log(JSON.stringify(params));
        return axios.get("tender-status", {params});
    }

    getListIdNameAll() {
        return axios.get("tender-status?perPage=1000&fields[tender_status]=id,name");
    }

    getDetail(id) {
        let params = {};
        return axios.get(`tender-status/${id}`, {params});
    }
}

export default new TenderStatusService();
