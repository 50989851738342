import Logout from "../views/auth/Logout";
import Login from "../views/auth/Login";
import PasswordReset from "../views/auth/PasswordReset";
import PasswordResetByToken from "../views/auth/PasswordResetByToken";
import Register from "../views/auth/Register";

export default [
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        meta: {
            noAuth: true
        }
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            noAuth: true
        }
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            noAuth: true
        }
    },
    // форма сброса пароля с отправкой токена по почте
    {
        path: "/password/reset",
        name: "password-reset",
        component: PasswordReset,
        meta: {
            noAuth: true
        }
    },
    // сброс пароля по токену из письма
    {
        path: "/password/reset/:token",
        name: "password-reset-by-token",
        component: PasswordResetByToken,
        props: true,
        meta: {
            noAuth: true
        }
    },
];
