<template>
    <div class="card card-primary">
        <div class="card-header">
            <h3 class="card-title"><i class="nav-icon icon-bid"></i> {{ trans("tender.Winning bet") }} </h3>
        </div>
        <div class="card-body">

            <ul class="list-group list-group-flush list-group-unbordered">
                <li class="list-group-item">
                    <b>{{ trans("tender.Bid") }}</b>
                    <span class="float-right">{{ win_bet.bid }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans("Date") }}</b>
                    <span class="float-right">{{ momentFullDateTime(win_bet.created_at) }}</span>
                </li>
                <li class="list-group-item">
                    <b>{{ trans("Company") }}</b>
                    <span class="float-right"><CompanyLink :company="win_bet.company" :ico="true"></CompanyLink></span>
                </li>
            </ul>

        </div>
    </div>
</template>

<script>
export default {
    props: ["win_bet"]
}
</script>
