export default {
    headerName: "",
    field: "id",
    width: 85,
    minWidth: 85,
    maxWidth: 85,
    sortable: false,
    suppressMenu: true,
    cellRenderer: "CRUDCellRenderer",
    cellClass: "aggrid-cell-actions"
}
