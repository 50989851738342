window._ = require('lodash');

window.objectByString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    let a = s.split('.');

    /**
     * Для случая, если текст оканчивается на точку, например
     * "The given data was invalid."
     */
    if (a.length > 1 && a[a.length - 1] === "") {
        a.splice(-1, 1);
        a[a.length - 1] += ".";
    }

    for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // При необходимости - включить назад эти библиотеки
    // window.Popper = require('popper.js').default;
    // window.$ = window.jQuery = require('jquery');
    // require('bootstrap');
} catch (e) {
}
