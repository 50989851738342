<template>
    <div>
        <form-response-errors ref="formResponseErrors"></form-response-errors>
        <div class="row">
            <div class="col-xl-6">
                <form-edit-create v-bind="{title, isSaving, cancel, submit}">
                    <slot v-bind="{ item }"></slot>
                </form-edit-create>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["id", "view", "service", "loadDefaultData", "withTrashed", "transformResponseCb"],
    data() {
        return {
            isSaving: false,
            item: this.loadDefaultData(),
            viewParams: {
                edit: {title: "Editing"},
                clone: {title: "Cloning"},
                create: {title: "Creation"}
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        cancel() {
            this.$emit("cancel", this);
        },
        async submit() {
            this.isSaving = true;
            this.$refs.formResponseErrors.hide();

            try {
                let response;

                switch (this.view) {
                    case "edit":
                        response = await this.service.patch(this.id, this.item);
                        break;
                    case "clone":
                    case "create":
                        response = await this.service.post(this.item);
                        break;
                }

                this.setItem(response);
                this.swalSuccess(this.trans("Data saved"));
                this.$emit("aftersubmit", this);
            } catch (error) {
                let errorMsg = this.getHttpError(error, this.trans("Failed to save data"));
                this.swalError(errorMsg);
                this.$refs.formResponseErrors.show(error);
            }
            this.isSaving = false;
        },
        async getData() {
            this.$refs.formResponseErrors.hide();

            switch (this.view) {
                case "edit":
                case "clone":
                    await this.loadRequestData();
                    break;
                case "create":
                    // загружается при mount(): this.loadDefaultData();
                    break;
            }
            this.$emit("dataLoaded", this.item, this);
        },
        async loadRequestData() {
            try {
                let response = await this.service.getEdit(this.id, this.withTrashed);
                this.setItem(response);
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
        },
        setItem(response) {
            if (typeof this.transformResponseCb === "function") {
                this.transformResponseCb(response, this);
            }
            this.item = response.data.data;
        }
    },
    computed: {
        title() {
            return this.trans(this.viewParams[this.view].title);
        }
    }
}
</script>
