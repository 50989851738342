<template>
    <div class="user-panel mt-3 pb-3 mb-3">
        <template v-if="loggedIn">
            <router-link :to="{ name: 'user-detail', params: { id: currentUser.id }}" class="d-block text-center">
                {{ currentUser.fi() }}
            </router-link>

            <p v-for="role in currentUser.roles" class="small text-info text-center my-0"><i
                class="fas fa-user-tag mr-2"></i> {{ role }}
            </p>

            <div class="text-center mt-3">
                <router-link to="/logout" class="btn btn-danger btn-sm" style="color: #fff">
                    <i class="nav-icon fas fa-sign-out-alt fa-inverse"></i> {{ trans('menu.Logout') }}
                </router-link>
            </div>

        </template>
        <template v-else>
            <nav>
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-header text-uppercase">{{ trans("menu.Authorization") }}</li>
                    <li class="nav-item">
                        <router-link :to="{name: 'login'}" class="nav-link"><i
                            class="nav-icon fas fa-sign-in-alt"></i> {{ trans('menu.Sign in') }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{name: 'register'}" class="nav-link"><i
                            class="nav-icon fas fa-user-plus"></i> {{ trans('menu.Sign up') }}
                        </router-link>
                    </li>
                </ul>
            </nav>
        </template>
    </div>
</template>

<script>
export default {
    computed: {
        loggedIn() {
            return this.$store.getters["auth/loggedIn"];
        },
        currentUser() {
            return this.$store.state.auth.user;
        }
    }
}
</script>
