<template>
    <div>
        <DeleteItemModal ref="deleteModal"></DeleteItemModal>
        <RestoreItemModal ref="restoreModal"></RestoreItemModal>
        <AggridList ref="aggridlist"
                    :componentGridOptions="gridOptions"
                    :excelColumns='["id", "company.name", "car_model", "car_number", "car_trailer_number"]'
                    :service="service"
                    :deleted_filter="can('deleted-vehicle-type-read')"
        >
            <template v-slot:buttons>
                <router-link
                    v-if="can('create-vehicle')"
                    :to="{name: 'vehicle-create'}" class="btn btn-sm btn-primary"><i
                    class="fas fa-plus-square"></i>
                    <span class="d-none d-sm-inline ml-1">
                        {{ trans("Create") }}
                    </span>
                </router-link>
            </template>
        </AggridList>
    </div>
</template>

<script>

import VehicleService from "../../services/VehicleService";
import IdCellParams from "../../aggrid/cells/IdCellParams";
import IdCellRenderer from "../../aggrid/cells/IdCellRenderer";
import CRUDCellRenderer from "../../aggrid/cells/CRUDCellRenderer";
import CRUDCellParams from "../../aggrid/cells/CRUDCellParams";

export default {
    data() {
        return {
            service: VehicleService,
            gridOptions: {
                columnDefs: [
                    {
                        ...{...IdCellParams, sort: "desc"}, cellRendererParams: {
                            getRoute(id) {
                                return {
                                    name: "vehicle-detail",
                                    params: {id}
                                };
                            }
                        }
                    },
                    {
                        headerName: this.trans("Company"), field: "company.name", width: 220,
                        floatingFilter: true,
                        suppressMenu: true,
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                        },
                        cellRenderer: (prop) => {
                            if (!prop.value) return null;
                            if (prop.data.company.deleted_at) {
                                return `<del>${prop.value}</del>`;
                            }
                            return prop.value;
                        }
                    },
                    {
                        headerName: this.trans("vehicle.Car model"), field: "car_model", width: 400,
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("vehicle.Car number"), field: "car_number", width: 400,
                        floatingFilter: true,
                    },
                    {
                        headerName: this.trans("vehicle.Car trailer number"),
                        field: "car_trailer_number",
                        width: 400,
                        floatingFilter: true,
                    },
                    {
                        ...CRUDCellParams,
                        cellRendererParams: {
                            canRead: (params) => {
                                return this.can("read-vehicle", params.data);
                            },
                            readCb: (id) => {
                                this.$router.push({name: "vehicle-details", params: {id}});
                            },
                            canEdit: (params) => {
                                return this.can("update-vehicle", params.data);
                            },
                            editCb: (id) => {
                                this.$router.push({name: "vehicle-edit", params: {id}});
                            },
                            canDelete: (params) => {
                                return this.can("delete-vehicle", params.data);
                            },
                            canRestore: (params) => {
                                return this.can("restore-vehicle", params.data);
                            },
                            deleteCb: (id) => {
                                this.$refs.deleteModal.show(async () => {
                                    try {
                                        await VehicleService.remove(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("vehicle.Vehicle deleted"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Delete Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            },
                            restoreCb: (id) => {
                                this.$refs.restoreModal.show(async () => {
                                    try {
                                        await VehicleService.restore(id);
                                        this.$refs.aggridlist.reload();
                                        this.swalSuccess(this.trans("vehicle.Vehicle restored"));
                                    } catch (error) {
                                        let errorMsg = this.getHttpError(error, this.trans("Restore Error"));
                                        this.swalError(errorMsg);
                                    }
                                });
                            }
                        }
                    },
                ],
                frameworkComponents: {
                    IdCellRenderer, CRUDCellRenderer
                },
            }
        }
    }
}
</script>
