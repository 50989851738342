<template>
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-md-8" v-if="successMessage">
                <div class="callout callout-success">
                    <h5>{{ trans('Restore password') }}</h5>
                    <p><i class="icon fas fa-check"></i> {{ successMessage }}</p>
                </div>
            </div>

            <div v-else class="login-box">
                <div class="card">
                    <div class="card-body login-card-body">

                        <p class="login-box-msg">{{ trans("Restore password") }}</p>

                        <form-response-errors ref="formResponseErrors"></form-response-errors>

                        <ValidationObserver v-slot="{ handleSubmit }" class="d-block">
                            <form @submit.prevent="handleSubmit(submit)" class="overlay-wrapper">
                                <loading-spinner v-if="isProgress"></loading-spinner>

                                <validation-provider mode="passive" rules="required|email" v-slot="{ errors }"
                                                     class="d-block">
                                    <div class="input-group mb-3">
                                        <input type="text"
                                               v-model="email"
                                               class="form-control"
                                               :class="{'is-invalid': errors[0]}"
                                               :placeholder="trans('E-mail')"
                                               autocomplete="email" autofocus
                                        >
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas fa-envelope"></span>
                                            </div>
                                        </div>
                                        <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                            <strong>{{ errors[0] }}</strong>
                                        </span>
                                    </div>
                                </validation-provider>

                                <div class="row">
                                    <div class="col-12">
                                        <button type="submit"
                                                class="btn btn-primary btn-block">{{ trans("Restore password") }}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>


                        <p class="mt-3 mb-1">
                            <router-link :to="{name: 'login'}">{{ trans('Sign in') }}</router-link>
                        </p>


                        <p class="mb-0">
                            <router-link :to="{name: 'register'}">{{ trans('Sign up') }}</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isProgress: false,
            successMessage: "",
            email: ""
        };
    },
    mounted() {
        //    admin@tendera.online
        this.redirectIfAuthorized();
    },
    methods: {
        redirectIfAuthorized() {
            if (this.$store.getters["auth/loggedIn"]) {
                this.$router.push({name: "user-detail", params: {id: this.$store.state.auth.user.id}});
            }
        },
        async submit() {
            this.isProgress = true;
            this.$refs.formResponseErrors.hide();
            try {
                let response = await this.$store.dispatch("auth/resetPassword", this.email);
                this.successMessage = response.data.message;
            } catch (error) {
                let errorMsg = this.getHttpError(error, this.trans("Failed to save data"));
                this.swalError(errorMsg);
                this.$refs.formResponseErrors.show(error);
            }
            this.isProgress = false;
        }
    }
}
</script>
