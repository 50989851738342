<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit }" class="d-block">
            <form @submit.prevent="handleSubmit(submit)" class="overlay-wrapper">
                <loading-spinner v-if="isSaving"></loading-spinner>

                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">
                            {{ title }}
                        </h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                    data-toggle="tooltip"
                                    title="Collapse">
                                <i class="fas fa-minus"></i></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <slot>Form</slot>
                    </div>
                </div>

                <!-- эта кнопка была исправлена на div, так как button @click вызывает submit-->
                <!-- спустя время воспроизвести ошибку не смог, вернул button и добавил type="button"  -->
                <button type="button" @click="cancel" :disabled="isSaving" class="btn btn-secondary">
                    {{ trans("Cancel") }}
                </button>

                <button type="submit" :disabled="isSaving" class="btn btn-success float-right">
                    {{ trans("Save") }}
                </button>

            </form>
        </ValidationObserver>
    </div>
</template>

<script>
export default {
    props: ["title", "isSaving", "cancel", "submit"]
}
</script>
