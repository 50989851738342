/**
 * @see \App\Domain\Address\Policies\AddressPolicy
 */
export default {
    'read-address-list': (user) => {
        return user.hasAccess(["address-all-admin", "address-all-read", "address-own-admin", "address-own-read"]);
    },
    'read-address': (user, address) => {
        return user.hasAccess(["address-all-admin", "address-all-read"]) ||
            (
                user.company_id === address.company_id && user.hasAccess(["address-own-admin", "address-own-read"])
            );
    },
    'pick-address-company': (user) => {
        return user.hasAccess(["address-all-admin", "address-all-create"]);
    },
    'create-address': (user) => {
        return user.hasAccess(["address-all-admin", "address-all-create", "address-own-admin", "address-own-create"]);
    },
    'update-address': (user, address) => {
        return user.hasAccess(["address-all-admin", "address-all-update"]) ||
            (
                user.company_id === address.company_id && user.hasAccess(["address-own-admin", "address-own-update"])
            );
    },
    'delete-address': (user, address) => {
        if (address.deleted_at) return false;
        return user.hasAccess(["address-all-admin", "address-all-delete"]) ||
            (
                user.company_id === address.company_id && user.hasAccess(["address-own-admin", "address-own-delete"])
            );
    },
    'restore-address': (user, address) => {
        return !!address.deleted_at && (user.hasAccess(["address-all-admin", "address-all-restore"]) ||
            (user.company_id === address.company_id && user.hasAccess(["address-own-admin", "address-own-restore"])));
    },
    'deleted-address-read': (user) => {
        return user.hasAccess(["address-all-admin", "address-all-delete", "address-own-admin", "address-own-delete"]);
    },
};
