<template>
    <div class="form-group loading-form-field">
        <label>
            <slot></slot>
        </label>
        <div class="input-group">
            <div class="input-group-prepend">
                    <span class="input-group-text">
                      <img alt="" src="/img/wheel.svg" width="22" height="22">
                    </span>
            </div>
            <input type="text" class="form-control" disabled>
        </div>
    </div>
</template>

<script>
export default {
    name: "loading-form-field"
}
</script>
