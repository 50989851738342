<template>
    <div class="container">
        <div class="row justify-content-center">

            <div class="col-md-8" v-if="successMessage">
                <div class="callout callout-success">
                    <h5>{{ trans('register.title') }}</h5>
                    <p><i class="icon fas fa-check"></i> {{ successMessage }}</p>
                </div>
                <router-link :to="{name: 'login'}" class="btn btn-primary">{{ trans("Sign in") }}</router-link>
            </div>

            <div v-else class="register-box">
                <div class="card">
                    <div class="card-body register-card-body">
                        <p class="login-box-msg">{{ trans("register.title") }}</p>

                        <form-response-errors ref="formResponseErrors"></form-response-errors>

                        <ValidationObserver v-slot="{ handleSubmit }" class="d-block">
                            <form @submit.prevent="handleSubmit(submit)" class="overlay-wrapper">
                                <loading-spinner v-if="isProgress"></loading-spinner>

                                <validation-provider rules="required" v-slot="{ errors }" class="d-block">
                                    <div class="input-group mb-3">
                                        <input type="text"
                                               v-model="surname"
                                               class="form-control"
                                               :class="{'is-invalid': errors[0]}"
                                               :placeholder="trans('user.surname')"
                                               autocomplete="surname" autofocus
                                        >
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas fa-user"></span>
                                            </div>
                                        </div>

                                        <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                        <strong>{{ errors[0] }}</strong>
                                    </span>
                                    </div>
                                </validation-provider>

                                <validation-provider rules="required" v-slot="{ errors }" class="d-block">
                                    <div class="input-group mb-3">
                                        <input type="text"
                                               v-model="name"
                                               class="form-control"
                                               :class="{'is-invalid': errors[0]}"
                                               :placeholder="trans('user.name')"
                                               autocomplete="name"
                                        >
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas fa-user"></span>
                                            </div>
                                        </div>

                                        <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                        <strong>{{ errors[0] }}</strong>
                                    </span>
                                    </div>
                                </validation-provider>

                                <validation-provider rules="required" v-slot="{ errors }" class="d-block">
                                    <div class="input-group mb-3">
                                        <input type="text"
                                               v-model="lastname"
                                               class="form-control"
                                               :class="{'is-invalid': errors[0]}"
                                               :placeholder="trans('user.lastname')"
                                               autocomplete="lastname"
                                        >
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas fa-user"></span>
                                            </div>
                                        </div>

                                        <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                        <strong>{{ errors[0] }}</strong>
                                    </span>
                                    </div>
                                </validation-provider>

                                <validation-provider rules="required|email" v-slot="{ errors }" class="d-block">
                                    <div class="input-group mb-3">
                                        <input type="text"
                                               v-model="email"
                                               class="form-control"
                                               :class="{'is-invalid': errors[0]}"
                                               :placeholder="trans('user.email')"
                                               autocomplete="email">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas fa-envelope"></span>
                                            </div>
                                        </div>
                                        <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                        <strong>{{ errors[0] }}</strong>
                                    </span>
                                    </div>
                                </validation-provider>

                                <validation-provider rules="required" v-slot="{ errors }" class="d-block">
                                    <div class="input-group mb-3">
                                        <input :type="showPassword ? 'text' : 'password'"
                                               v-model="password"
                                               class="form-control"
                                               :class="{'is-invalid': errors[0]}"
                                               :placeholder="trans('user.password')"
                                               autocomplete="new-password">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                <span class="fas"
                                                      @click="showPassword = !showPassword"
                                                      :class="{'fa-eye-slash': showPassword, 'fa-eye': !showPassword}">
                                                </span>
                                            </div>
                                        </div>
                                        <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                        <strong>{{ errors[0] }}</strong>
                                    </span>
                                    </div>
                                </validation-provider>

                                <validation-provider v-slot="{ errors }" class="d-block"
                                                     :rules="{ required: { allowFalse: false } }">
                                    <div class="input-group mb-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" id="agreeTerms"
                                                   v-model="terms"
                                                   value="agree"
                                                   class="custom-control-input"
                                                   :class="{'is-invalid': errors[0]}"
                                            >
                                            <label for="agreeTerms" class="custom-control-label">
                                                {{ trans("register.I agree to") }}
                                                <router-link :to="{name: 'page', params: {slug: 'terms'}}"
                                                             target="_blank">
                                                    {{ trans("register.the terms") }}
                                                </router-link>
                                            </label>
                                            <span v-if="errors[0]" class="invalid-feedback" role="alert">
                                                <strong>{{ errors[0] }}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </validation-provider>

                                <div class="input-group mb-3">
                                    <button type="submit"
                                            class="btn btn-primary btn-block">{{ trans("register.btn") }}
                                    </button>
                                </div>
                            </form>
                        </ValidationObserver>

                        <div class="social-auth-links text-center">
                            <p>{{ trans("register.or_sign_up_social") }}</p>
                            <a href="#" class="btn btn-block btn-warning">
                                <i class="fab fa-vk mr-2"></i>
                                {{ trans("auth.Sign up using VK") }}
                            </a>
                            <a href="#" class="btn btn-block btn-warning">
                                <i class="fab fa-yandex-international mr-2"></i>
                                {{ trans("auth.Sign up using Yandex") }}
                            </a>
                        </div>

                        <router-link :to="{name: 'login'}" class="text-center">
                            {{ trans('register.already_registered') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showPassword: false,
            isProgress: false,
            successMessage: "",
            surname: "",
            name: "",
            lastname: "",
            email: "",
            password: "",
            terms: true
        }
    },
    methods: {
        async submit() {
            this.isProgress = true;
            this.$refs.formResponseErrors.hide();
            try {
                let data = {
                    surname: this.surname,
                    name: this.name,
                    lastname: this.lastname,
                    email: this.email,
                    password: this.password,
                    terms: this.terms ? "agree" : null,
                };
                let response = await this.$store.dispatch("auth/register", data);
                this.successMessage = response.data.message;
            } catch (error) {
                let errorMsg = this.getHttpError(error, this.trans("Failed to save data"));
                this.swalError(errorMsg);
                this.$refs.formResponseErrors.show(error);
            }
            this.isProgress = false;
        }
    }
}
</script>
