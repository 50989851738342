<template>
    <ol class="breadcrumb float-sm-right" v-if="this.$breadcrumbs.length > 1">
        <li class="breadcrumb-item" v-for="(crumb, key) in $breadcrumbs" :key="key" v-if="crumb.meta.breadcrumb">
            <router-link :to="{ path: getPath(crumb) }">
                {{ getTitle(crumb) }}
            </router-link>
        </li>
    </ol>
</template>

<script>
/**
 * Как работает механизм динамических хлебных крошек на примере редактирования пользователя?
 * Используется Vuex
 * 1. определен router.beforeEach(), который при каждом переходе делает "breadcrumbs/reset", чтобы не
 * возникали артифакты
 * 2. после загрузки данных в UniversalForm вызывается событие this.$emit("dataLoaded", this.item, this);
 * 3. это событие перехватывается в UserForm и назначается заголовок:
 * this.$store.dispatch("breadcrumbs/setTitle", User.fio(item));
 * 4. getTitle работает с Vuex, отслеживая изменение заголовка, если rwBc === true
 */
export default {
    mixins: [
        {
            methods: {
                getTitle(crumb) {
                    if (crumb.meta.rwBc) {
                        let title = this.$store.state.breadcrumbs.title;
                        if (title) return title;
                    }

                    return this.getBreadcrumb(crumb.meta.breadcrumb);
                }
            }
        }
    ]
}
</script>
