<template>
    <div>
        <div v-if="!isSameDay" class="timeline-it time-label">
            <span class="bg-danger">
              {{ this.momentDateYYYY(point.arrival_date_plan) }}
            </span>
        </div>
        <div class="timeline-it">
            <i v-if="point.is_loading" class="fas icon-loading bg-olive active"></i>
            <i v-if="point.is_unloading" class="fas icon-unloading bg-blue active"></i>

            <div class="timeline-item">
                <span class="time float-left"><i class="far fa-clock"></i> {{ this.momentTime(point.arrival_date_plan) }}</span>

                <h3 class="timeline-header">{{ point.short_name }}</h3>

                <div class="timeline-body">

                    <div class="row">
                        <div class="col-xl-3 text-bold">
                            {{ trans("tenderForm." + (this.point.is_loading ? "Shipper" : "Consignee")) }}
                        </div>
                        <div class="col-xl-9">{{ point.company.name }}</div>

                        <div class="col-xl-3 text-bold">{{ trans("tenderForm.Locality") }}</div>
                        <div class="col-xl-9">{{ point.locality.type.short_name }} {{ point.locality.name }}</div>

                        <div class="col-xl-3 text-bold">{{ trans("tenderForm.Address") }}</div>
                        <div class="col-xl-9">{{ point.address }}</div>

                        <div class="col-xl-3 text-bold">{{ trans("tenderForm.Contacts") }}</div>
                        <div class="col-xl-9">{{ point.contacts }}</div>

                        <div class="col-xl-3 text-bold"></div>
                        <div class="col-xl-9"></div>
                    </div>

                </div>
                <div class="timeline-footer">

                    <a disabled class="btn btn-primary btn-sm">
                        <i class="fa fa-check"></i>
                        {{ trans("tender.Mark arrival time") }}
                    </a>

                    <a disabled class="btn btn-primary btn-sm">
                        <i class="fa fa-check"></i>
                        {{ trans("tender.Mark departure time") }}
                    </a>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["point", "prevPoint"],
    computed: {
        isSameDay() {
            if (!this.prevPoint) return false;
            let prevDate = this.prevPoint.arrival_date_plan;
            let pointDate = this.point.arrival_date_plan;
            return this.momentDate(prevDate) === this.momentDate(pointDate);
        }
    }
}
</script>

