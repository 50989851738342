import axios from "../axios";

class LogService {

    getLast(id) {
        let params = {
            sort: "-id",
            include: "objectType,actionType,user"
        };
        return axios.get(`log/company?filter[company_id]=${id}`, {params});
    }

}

export default new LogService();
