import axios from "../axios";
import AbstractService from "./AbstractService";

class RideService extends AbstractService {

    getRoute() {
        return "ride";
    }

}

export default new RideService();
