import moment from "moment";

export default class Tender {

    constructor(props = {}) {

        this.bet_start = props.bet_start || moment().utc(true);

        if (props.bet_end) {
            this.bet_end = props.bet_end;
        } else {
            this.bet_end = moment().utc(true);

            if (this.bet_end.hour() >= 12) {
                this.bet_end.add(1, "days").set("hour", 15).set("minute", 0);
            } else {
                this.bet_end.add(3, "hours");
            }
        }

        this.id = props.id || 0;
        this.tender_type_id = props.tender_type_id || null;
        this.tender_class_id = props.tender_class_id || null;
        this.tender_status_id = props.tender_status_id || null;
        this.appointed_company_id = props.appointed_company_id || null;
        this.vehicle_type_id = props.vehicle_type_id || null;
        this.cargo_weight = props.cargo_weight || null;
        this.cargo_volume = props.cargo_volume || null;
        this.starting_bid = props.starting_bid || null;
        this.auction_step = props.auction_step || null;
        this.company_min_bet = null;
        this.min_bet = null;
        this.win_bet = null;
        this.has_vat = typeof props.has_vat === "undefined" ? true : props.has_vat;
        this.is_movizor = typeof props.is_movizor === "undefined" ? false : props.is_movizor;
        this.route_points = props.route_points || [];
        this.comment = props.comment || "";
    }

    collectRequestData() {
        let copyFields = [
            "tender_type_id", "tender_class_id",
            "appointed_company_id", "vehicle_type_id",
            "cargo_weight", "cargo_volume", "starting_bid", "auction_step",
            "bet_start", "bet_end", "has_vat", "is_movizor", "comment"
        ];
        let data = _.pick(this, copyFields);

        data.route_points = this.route_points.map(o => {
            let data = _.pick(o,
                [
                    "id", "address", "company_id", "point_number", "src_address_id", "short_name",
                    "locality_id", "contacts", "is_loading", "is_unloading", "arrival_date_plan"
                ]
            );
            // меняем "new_*" точки на "null"
            data.id = typeof data.id === "string" ? null : data.id;
            return data;
        });
        return data;
    }
}
