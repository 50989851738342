import jwt_decode from "jwt-decode";

/**
 * Как обратиться к текущему пользователю:
 * let user = this.state.auth.user;
 */

export default class User {
    static fromAuthResponse(response) {
        let payload = jwt_decode(response.access_token);
        // console.log(payload.exp)

        let user = new User;
        user.id = payload.sub;
        user.access_token = response.access_token;
        user.refresh_token = response.refresh_token;
        user.expires_at = response.expires_at;
        user.scopes = payload.scopes;
        user.roles = payload.roles;
        user.email = payload.email;
        user.surname = payload.surname;
        user.name = payload.name;
        user.lastname = payload.lastname;
        user.company_id = payload.company_id;
        user.config = payload.config;
        return user;
    }

    static fromStoredData(data) {
        let user = new User;
        user = Object.assign(user, data);
        return user;
    }

    fio() {
        return [this.surname, this.name, this.lastname].join(" ");
    }

    static fio(user) {
        return [user.surname, user.name, user.lastname].join(" ");
    }

    fi() {
        return [this.surname, this.name].join(" ");
    }

    static fi(user) {
        return [user.surname, user.name].join(" ");
    }

    /**
     * @param neededScopesList
     * @returns {boolean}
     *
     * @see \App\Domain\User\Models\User::hasAccess
     */
    hasAccess(neededScopesList) {
        if (this.scopes.indexOf("*") !== -1) return true;

        for (let neededScope of neededScopesList) {
            if (this.scopes.indexOf(neededScope) !== -1) return true;
        }

        return false;
    }
}
