<template>
    <div>
        <div v-for="bet in bets" class="progress-group mb-4">
            <b class="mr-1">{{ bet.bid }}</b>
            <span class="text-muted">{{ bet.company.name }}</span>
            <span class="float-right small">{{ momentDateTime(bet.created_at) }}</span>
            <div class="progress progress-sm">
                <div class="progress-bar" :style="{width: (bet.bid / maxBid * 100) + '%' }" :class="bgColor(bet)"></div>
            </div>
        </div>
    </div>
</template>

<script>
import BetService from "../../../services/BetService";

export default {
    props: ["tenderId"],
    data() {
        return {
            bets: []
        }
    },
    computed: {
        maxBid() {
            let maxBet = _.maxBy(this.bets, o => o.bid);
            return maxBet ? maxBet.bid : 1000;
        },
        minBid() {
            let minBet = _.minBy(this.bets, o => o.bid);
            return minBet ? minBet.bid : 0;
        },
        bgIntervals() {
            let diff = this.maxBid - this.minBid;
            let step = diff / 3;
            return [
                {bg: "bg-success", p: this.minBid + step},
                {bg: "bg-warning", p: this.minBid + 2 * step},
                {bg: "bg-danger", p: this.maxBid},
            ];
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            let response = await BetService.allBets(this.tenderId);
            this.bets = response.data.data;
        },
        bgColor(bet) {
            for (let i in this.bgIntervals) {
                if (bet.bid <= this.bgIntervals[i].p) {
                    return this.bgIntervals[i].bg;
                }
            }
            return "bg-success";
        }
    }
}
</script>
