<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>

        <div class="row" v-if="region.id">
            <div class="col-lg-6 col-xl-4">

                <div class="card card-primary card-outline" :class="{'card-danger': region.deleted_at }">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <i v-if="region.deleted_at" class="fas fa-trash-alt fa-2x text-danger"></i>
                            <i v-else class="fas fa-globe fa-2x"></i>
                        </div>

                        <h3 class="profile-username text-center">{{ region.name }}</h3>

                        <router-link v-if="can('update-region')" :to="{name: 'region-edit', params: {id: region.id}}"
                                     class="btn btn-sm btn-primary mr-1">
                            <i class="fas fa-pencil-alt"></i>
                        </router-link>

                        <template v-if="can('create-region')">
                            <router-link :to="{ name: 'region-clone', params: { id: region.id } }"
                                         class="btn btn-sm btn-primary mr-1">
                                <i class="fas fa-clone"></i>
                            </router-link>
                        </template>

                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xl-8">
                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class="nav-link active" href="#localities" data-toggle="tab">
                                    {{ trans("region.Localities") }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="active tab-pane" id="localities">
                                <LocalityList :region_id="id">
                                </LocalityList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Vue from "vue";
import RegionService from "../../services/RegionService";
import LocalityList from "../locality/LocalityList";

Vue.component("LocalityList", LocalityList);

export default {
    props: ['id'],
    data() {
        return {
            region: {}
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        reload() {
            this.getData();
        },
        async getData() {
            try {
                this.$refs.formResponseErrors.hide();
                let response = await RegionService.getDetail(this.id, this.can('deleted-region-read'));
                this.region = response.data.data;
                this.$store.dispatch("breadcrumbs/setTitle", this.region.name);
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
        }
    }
}
</script>
