/**
 * @see \App\Domain\Vehicle\Policies\VehicleTypePolicy
 */
export default {
    'create-vehicle-type': (user) => {
        return user.hasAccess(["vehicle-type-all-admin", "vehicle-type-all-create"]);
    },
    'read-vehicle-type': (user) => {
        return user.hasAccess(["vehicle-type-all-admin", "vehicle-type-all-read"]);
    },
    'read-vehicle-type-list': (user) => {
        return user.hasAccess(["vehicle-type-all-admin", "vehicle-type-all-read"]);
    },
    'update-vehicle-type': (user) => {
        return user.hasAccess(["vehicle-type-all-admin", "vehicle-type-all-update"]);
    },
    'delete-vehicle-type': (user, vehicleType) => {
        if (vehicleType.deleted_at) return false;
        return user.hasAccess(["vehicle-type-all-admin", "vehicle-type-all-delete"]);
    },
    'restore-vehicle-type': (user, vehicleType) => {
        return !!vehicleType.deleted_at && user.hasAccess(["vehicle-type-all-admin", "vehicle-type-all-restore"]);
    },
    'deleted-vehicle-type-read': (user) => {
        return user.hasAccess(["vehicle-type-all-admin", "vehicle-type-all-delete"]);
    },
};
