export default {
    headerName: "ID",
    field: "id",
    width: 80,
    minWidth: 80,
    maxWidth: 120,
    filter: "agNumberColumnFilter",
    floatingFilter: true,
    cellRenderer: "IdCellRenderer",
    cellClass: "aggrid-cell-id"
}
