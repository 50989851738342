<template>
    <div>

        <div class="card card-tendering">

            <div class="card-header">
                <div class="card-tools float-none">
                    <div class="form-inline float-left">
                        <SearchBox :value="omniFilter"
                                   :placeholder="trans('Search')"
                                   v-on:search="omniFilterSearch"
                                   v-on:reset="omniFilterReset"
                                   v-on:input="updateOmniFilter"
                        ></SearchBox>
                    </div>

                    <div class="float-right ml-4">
                        <span class="btn btn-sm btn-tool mr-1" @click="toggleSettings">
                            <i aria-hidden="true" class="fa fa-cog"></i>
                        </span>
                        <span class="btn btn-sm btn-tool mr-1" @click="loadData"><i aria-hidden="true"
                                                                                    class="fa fa-redo"></i></span>
                    </div>

                    <div class="float-right">
                        <BPagination
                            class="pagination m-0 ml-4 float-right"
                            :value="currentPage"
                            v-on:change="setCurrentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            size="sm"
                            first-number
                            last-number
                        ></BPagination>

                        <PerPageSelect :value="perPage" v-on:change="setPerPage" size="sm" class="m-0"></PerPageSelect>
                    </div>
                </div>
            </div>

            <div class="card-body p-0">

                <div v-if="tenders.length" class="table-responsive">
                    <table class="table tendering-table"
                           :class="{'table-sm': settings.isCompact, 'text-sm': settings.isCompact}">
                        <thead>
                        <tr class="tendering-table-tr">
                            <th v-if="settings.cols.id">ID</th>
                            <!-- Окончание торгов -->
                            <th v-if="settings.cols.bet_end">{{ trans("tender.End of trading") }}</th>
                            <!-- Маршрут -->
                            <th v-if="settings.cols.route_points">{{ trans("tender.Route") }}</th>
                            <!-- Тип ТС -->
                            <th v-if="settings.cols.vehicle_type">{{ trans("tender.Vehicle type") }}</th>
                            <!-- Общий вес и объем -->
                            <th v-if="settings.cols.cargo_weight_volume">
                                {{ trans("tender.Total cargo weight & volume") }}
                            </th>
                            <!-- Примечание -->
                            <th v-if="settings.cols.comment">{{ trans("Note") }}</th>
                            <!-- Шаг аукциона -->
                            <th v-if="settings.cols.auction_step">{{ trans("tender.Auction step") }}</th>
                            <!-- С НДС -->
                            <th v-if="settings.cols.has_vat">{{ trans("Has Vat") }}</th>
                            <!-- Моя ставка -->
                            <th v-if="settings.cols.my_bet">{{ trans("tender.My Bet") }}</th>
                            <!-- Актуальная ставка -->
                            <th v-if="settings.cols.min_bid">{{ trans("tender.Actual Bet") }}</th>
                            <!-- Новая ставка -->
                            <th v-if="settings.cols.new_bid">{{ trans("tender.New Bet") }}</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr class="tendering-table-tr" v-for="(tender, idx) in tenders"
                            :class="{'active': tender.guiIsShow, 'closing': tender.guiIsHide}"
                            :key="tender.id"
                        >
                            <td v-if="settings.cols.id">
                                <div>{{ tender.id }}</div>
                            </td>
                            <td v-if="settings.cols.bet_end">
                                <div class="text-muted text-sm">
                                    {{ momentDate(tender.bet_end) }}<br>
                                    {{ momentTime(tender.bet_end) }}
                                </div>
                            </td>
                            <td v-if="settings.cols.route_points">
                                <TenderingRoute
                                    v-bind="{route: tender.route_points}"></TenderingRoute>
                            </td>
                            <td v-if="settings.cols.vehicle_type">
                                <div>{{ tender.vehicle_type.name }}</div>
                            </td>
                            <td v-if="settings.cols.cargo_weight_volume">
                                <div>
                                    <i class="fas fa-weight-hanging text-muted text-sm"></i>
                                    {{ tender.cargo_weight }}
                                    <br>
                                    <i class="fas fa-cube text-muted text-sm"></i>
                                    {{ tender.cargo_volume }}
                                </div>
                            </td>
                            <td v-if="settings.cols.comment">
                                <div>{{ tender.comment }}</div>
                            </td>
                            <td v-if="settings.cols.auction_step">
                                <div>{{ tender.auction_step }}</div>
                            </td>
                            <td v-if="settings.cols.has_vat">
                                <div><i class="fas fa-check"></i></div>
                            </td>
                            <td v-if="settings.cols.my_bet">
                                <div v-if="tender.company_min_bet">{{ tender.company_min_bet.bid }}</div>
                            </td>
                            <td v-if="settings.cols.min_bid">
                                <div v-if="tender.min_bet">
                                    <AnimatedBid :value="tender.min_bet.bid"
                                                 :green="isCompanySameBid(tender)"></AnimatedBid>
                                </div>
                            </td>
                            <td v-if="settings.cols.new_bid">
                                <div class="d-flex tendering-buttons">
                                    <TenderingBid class="mr-2" v-bind="{tender}"></TenderingBid>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="card-footer clearfix">

                <BPagination
                    class="pagination m-0 ml-4 float-right"
                    :value="currentPage"
                    v-on:change="setCurrentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                ></BPagination>

                <PerPageSelect :value="perPage" v-on:change="setPerPage"></PerPageSelect>

            </div>
        </div>
    </div>
</template>

<script>

/**
 * Анимация https://stackoverflow.com/questions/37375625/how-to-animate-toggling-of-table-rows#37376274
 * Grid @link https://css-tricks.com/snippets/css/complete-guide-grid/
 *
 */

import {BPagination} from 'bootstrap-vue';
import {mapActions, mapGetters, mapState} from "vuex";
import TenderingBell from "./TenderingBell";
import TenderingBid from "./TenderingBid";
import TenderingRoute from "./TenderingRoute";
import TenderingSettings from "../settings/TenderingSettings";
import SearchBox from "../../partial/SearchBox";
import PerPageSelect from "../partial/PerPageSelect";
import AnimatedBid from "../partial/AnimatedBid";

export default {
    components: {
        PerPageSelect, TenderingSettings, TenderingRoute, TenderingBid, TenderingBell, SearchBox, BPagination,
        AnimatedBid
    },
    computed: {
        ...mapState("tendering", [
            "error", "settings", "omniFilter", "perPage", "currentPage", "totalRows"
        ]),
        ...mapGetters("tendering", ["tenders"])
    },
    methods: {
        ...mapActions("tendering", [
            "initState", "loadData", "toggleSettings", "updateOmniFilter", "omniFilterSearch", "omniFilterReset",
            "setPerPage", "setCurrentPage"
        ]),
        isCompanySameBid(tender) {
            return tender.min_bet && tender.company_min_bet && tender.min_bet.bid === tender.company_min_bet.bid;
        }
    },
    async mounted() {
        await this.initState();
    }
}
</script>


<style lang="scss">

.card-tendering {
    .tendering-per-page {
        font-weight: normal !important;

        select {
            width: auto;
        }
    }

    .tendering-buttons {
        align-items: center;
        min-width: 180px;
    }

    .tendering-table {
        .tendering-table-tr {
            transition: background-color 0.2s;

            // Если делать анимацию цветом, то наверное через frames

            td {
                padding: 0 !important;
            }

            td > div {
                max-height: 0;
                padding: 0 10px;
                box-sizing: border-box;
                overflow: hidden;
                transition: padding .1s linear, max-height 1s linear;
            }

            &.active td > div {
                max-height: 200px;
                padding: 10px 10px;
            }

            &.closing td > div {
                max-height: 0;
                padding: 10px 10px;
            }

            &:hover {
                background: #e7f2ff;
            }
        }
    }
}
</style>
