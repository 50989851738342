<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>

        <div class="row" v-if="vehicle.id">
            <div class="col-lg-6 col-xl-4">

                <div class="card card-primary card-outline" :class="{'card-danger': vehicle.deleted_at }">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <i v-if="vehicle.deleted_at" class="fas fa-trash-alt fa-2x text-danger"></i>
                            <i v-else class="fas fa-truck fa-2x"></i>
                        </div>

                        <h3 class="profile-username text-center">
                            {{ vehicle.car_model }}
                            <span class="border rounded pl-2 pr-2">
                                {{ vehicle.car_number }}
                            </span>
                        </h3>

                        <p class="text-muted text-center" v-if="vehicle.company">
                            <CompanyLink :company="vehicle.company" :ico="true"></CompanyLink>
                        </p>

                        <ul class="list-group list-group-unbordered mt-3 mb-3">
                            <li class="list-group-item">
                                <b>{{ trans("vehicle.Car model") }}</b>
                                <span class="float-right">{{ vehicle.car_model }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans("vehicle.Car number") }}</b>
                                <span class="float-right">{{ vehicle.car_number }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans("vehicle.Car trailer number") }}</b>
                                <span class="float-right">{{ vehicle.car_trailer_number }}</span>
                            </li>
                        </ul>

                        <router-link v-if="can('update-vehicle')"
                                     :to="{name: 'vehicle-edit', params: {id: vehicle.id}}"
                                     class="btn btn-sm btn-primary mr-1">
                            <i class="fas fa-pencil-alt"></i>
                        </router-link>

                        <template v-if="can('create-vehicle')">
                            <router-link :to="{ name: 'vehicle-clone', params: { id: vehicle.id } }"
                                         class="btn btn-sm btn-primary mr-1">
                                <i class="fas fa-clone"></i>
                            </router-link>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VehicleService from "../../services/VehicleService";

export default {
    props: ['id'],
    data() {
        return {
            vehicle: {}
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        reload() {
            this.getData();
        },
        async getData() {
            try {
                this.$refs.formResponseErrors.hide();
                let response = await VehicleService.getDetail(this.id, this.can('deleted-vehicle-read'));
                this.vehicle = response.data.data;
                this.$store.dispatch("breadcrumbs/setTitle", this.vehicle.car_number);
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
        }
    }
}
</script>
