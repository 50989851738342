import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";
import AbstractService from "./AbstractService";

class LocalityService extends AbstractService {

    getRoute() {
        return "locality";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["region.name", "trashed"], omniFilter);
        params.include = "region";

        this.applyFilters(params);

        console.log(JSON.stringify(params));
        return axios.get("locality", {params});
    }

    getListIdNameAll() {
        return axios.get("locality?perPage=1000&fields[locality]=id,name");
    }

    getDetail(id, withTrashed) {
        let params = {
            include: "region,type"
        };
        if (withTrashed) {
            params["filter[trashed]"] = "with";
        }
        return axios.get(`locality/${id}`, {params});
    }

}

export default new LocalityService();
