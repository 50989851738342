/**
 * @see \App\Domain\Config\Policies\FeatureFlagsPolicy
 */
export default {
    'read-feature-flag-list': (user) => {
        return user.hasAccess(["feature-flag-read", "feature-flag-admin"]);
    },
    'update-feature-flag': (user) => {
        return user.hasAccess(["feature-flag-admin"]);
    },
};
