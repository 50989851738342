import axios from "axios";
import jwt_decode from "jwt-decode";

const axiosInstance = axios.create({
    baseURL: "/api/"
});

function noAuthRoute(uri) {
    return /^(auth\/|password\/|register$)/.test(uri);
}

/**
 * Начало отправки запроса:
 * проверка просрочки токена, выдача нового перед отправкой запроса
 */
axiosInstance.interceptors.request.use(async function (config) {
    return new Promise(async resolve => {
        // все запросы на авторизацию пропускаем без доп. обработки
        if (noAuthRoute(config.url)) {
            resolve(config);
            return;
        }

        try {
            let user = window.app.$store.state.auth.user;
            let payload = jwt_decode(user.access_token);
            let expTime = payload.exp * 1000;
            let curTime = new Date().getTime();

            // 3 секунды
            if (expTime - curTime <= 3000) {
                // console.log("await refreshToken ....");
                await window.app.$store.dispatch("auth/refreshToken");
            }

            /**
             * Авто-добавление заголовков авторизации
             */
            config.headers = {...config.headers, ...window.app.$store.getters["auth/authHeader"]};

            resolve(config);
        } catch (err) {
            console.log("axiosInstance.interceptors.request.use", err);
            resolve(config);
        }
    });
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

/**
 * Получение ответа:
 * если запрос отправлен, но сервер все равно вернул 401 - показываем модальное окно повторного входа
 */
axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    try {
        if (noAuthRoute(error.response.config.url)) {
            return Promise.reject(error);
        }

        if (error.response.status === 401) {
            window.app.$refs.refreshTokenModal.show({
                // cb() {
                //     window.location.reload();
                // },
            });
        }
    } catch (e) {

    }

    return Promise.reject(error);
});

export default axiosInstance;
