/**
 * @see \App\Domain\Driver\Policies\DriverPolicy
 */
export default {
    'create-driver': (user) => {
        return user.hasAccess(["driver-all-admin", "driver-all-create", "driver-own-admin", "driver-own-create"]);
    },
    'read-driver': (user, driver) => {
        return user.hasAccess(["driver-all-admin", "driver-all-read"]) ||
            (
                user.company_id === driver.company_id && user.hasAccess(["driver-own-admin", "driver-own-read"])
            );
    },
    'read-driver-list': (user) => {
        return user.hasAccess(["driver-all-admin", "driver-all-read", "driver-own-admin", "driver-own-read"]);
    },
    'update-driver': (user, driver) => {
        return user.hasAccess(["driver-all-admin", "driver-all-update"]) ||
            (
                user.company_id === driver.company_id && user.hasAccess(["driver-own-admin", "driver-own-update"])
            );
    },
    'delete-driver': (user, driver) => {
        if (driver.deleted_at) return false;
        return user.hasAccess(["driver-all-admin", "driver-all-delete"]) ||
            (
                user.company_id === driver.company_id && user.hasAccess(["driver-own-admin", "driver-own-delete"])
            );
    },
    'restore-driver': (user, driver) => {
        return !!driver.deleted_at && (user.hasAccess(["driver-all-admin", "driver-all-restore"]) ||
            (user.company_id === driver.company_id && user.hasAccess(["driver-own-admin", "driver-own-restore"])));
    },
    'pick-any-driver-company': (user) => {
        return user.hasAccess(["driver-all-admin", "driver-all-create"]);
    },
    'block-driver': (user) => {
        return user.hasAccess(["driver-all-admin", "driver-all-block"]);
    },
    'deleted-driver-read': (user) => {
        return user.hasAccess(["driver-all-admin", "driver-all-delete", "driver-own-admin", "driver-own-delete"]);
    }
};
