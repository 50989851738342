import AuthService from "../services/AuthService";
import User from "../models/User";

import Company from "./../policies/Company";
import Region from "./../policies/Region";
import Locality from "./../policies/Locality";
import Address from "./../policies/Address";
import Driver from "./../policies/Driver";
import Vehicle from "./../policies/Vehicle";
import Tender from "./../policies/Tender";
import TenderStatus from "./../policies/TenderStatus";
import VehicleType from "./../policies/VehicleType";
import Ride from "./../policies/Ride";
import UserPolicy from "../policies/User";
import FeatureFlag from "../policies/FeatureFlag";

let policies = {
    ...Company,
    ...UserPolicy,
    ...Region,
    ...Locality,
    ...Address,
    ...Driver,
    ...Vehicle,
    ...Tender,
    ...TenderStatus,
    ...VehicleType,
    ...Ride,
    ...FeatureFlag
};

const storedUserData = JSON.parse(localStorage.getItem("user"));
const initialState = storedUserData ? {user: User.fromStoredData(storedUserData)} : {user: null};
let refreshTokenPromise;

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        async login({commit}, credentials) {
            return new Promise((resolve, reject) => {
                AuthService.login(credentials).then(
                    response => {
                        let user = User.fromAuthResponse(response.data);
                        localStorage.setItem("user", JSON.stringify(user));
                        commit("loginSuccess", user);
                        resolve(user);
                    },
                    error => {
                        commit("loginFailure");
                        reject(error);
                    }
                );
            });
        },
        logout({commit}) {
            localStorage.removeItem("user");
            commit("logout");
        },
        async refreshToken({commit, state}) {
            if(refreshTokenPromise) return refreshTokenPromise;

            refreshTokenPromise = new Promise((resolve, reject) => {
                AuthService.refreshToken(state.user.refresh_token).then(response => {
                    let user = User.fromAuthResponse(response.data);
                    localStorage.setItem("user", JSON.stringify(user));
                    commit("loginSuccess", user);
                    resolve(user);
                    refreshTokenPromise = null;
                }).catch(err => {
                    commit("loginFailure");
                    reject(err);
                    refreshTokenPromise = null;
                });
            });

            return refreshTokenPromise;
        },
        async resetPassword({commit}, email) {
            return new Promise((resolve, reject) => {
                AuthService.resetPassword({email}).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });

            });
        },
        async resetPasswordByToken({commit}, data) {
            return new Promise((resolve, reject) => {
                AuthService.resetPasswordByToken(data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        async register({commit}, data) {
            return new Promise((resolve, reject) => {
                AuthService.register(data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    },
    getters: {
        authHeader: state => {
            let user = state.user;
            if (user.access_token) {
                return {Authorization: 'Bearer ' + user.access_token};
            }
            return {};
        },
        loggedIn: state => !!state.user,
        userCan: (state) => (action, ...args) => {
            /**
             * Blade-подобный синтаксис @can благодаря
             * Vue.prototype.can = function (action, ...args) { return this.$store.getters["auth/userCan"](action, ...args); }
             *
             * <template v-if="can()">
             *     ...
             * </template>
             *
             * Во Vuex:
             *
             * let can = context.rootGetters["auth/userCan"];
             * can(...)
             *
             */

            let user = state.user;
            if (!user) return false;

            let policy = policies[action];
            if (!policy) {
                console.error(`Policy '${action}' is not defined`);
                return false;
            }


            /**
             * При отрисовке компонента часть объектов может не существовать.
             * Например при вызове `update-company`, компания `company` могла еще не загрузиться.
             * В этом случае стоит либо откладывать показ компонента, либо использовать try...catch
             */
            let isGranted = false;
            try {
                isGranted = policy(user, ...args);
                if (typeof isGranted !== "boolean") {
                    console.error(`Policy '${action}' return non bool value: ${isGranted}`);
                }
            } catch (err) {
                // Раскоментировать для отладки
                console.error("policy call", err);
            }

            // console.warn(`Policy: '${action}' is '${isGranted}'`);

            return !!isGranted;
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.user = user;
        },
        loginFailure(state) {
            state.user = null;
        },
        logout(state) {
            state.user = null;
        }
    }
};
