<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">

                    <v-select v-model="point.src_address" :options="options"
                              :filter="filter"
                              @search="onSearch"
                              @input="changeSrsAddress"
                              label="short_name"
                    >
                        <template #no-options="{ search, searching, loading }">
                            <span class="text-blue">{{ trans("tenderForm.Enter the address to search") }}</span>
                        </template>
                        <template #option="option">
                            <div>
                                <span class="mr-2" v-html="highlight(option.short_name)"></span>
                                <template v-if="option.is_loading && option.is_unloading">
                                    <i class="icon-bidirectional text-yellow active"></i>
                                </template>
                                <template v-else>
                                    <i v-if="option.is_loading" class="icon-loading text-olive active"></i>
                                    <i v-if="option.is_unloading" class="icon-unloading text-blue active"></i>
                                </template>
                                <span class="small text-muted" v-html="highlight(option.company.name)"></span>
                            </div>
                            <div>
                                <span class="fas fa-map-marker-alt mr-1"></span>
                                <span class="mr-2" v-html="highlight(option.locality.name)"></span>
                                <span v-html="highlight(option.address)"></span>
                            </div>
                            <div>
                                <span class="small" v-html="highlight(option.contacts)"></span>
                            </div>
                        </template>
                    </v-select>

                </div>
            </div>
        </div>

        <transition name="bounce-11" v-if="point">
            <div v-if="point && point.company">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                {{ trans("tenderForm." + (this.point.is_loading ? "Shipper" : "Consignee")) }}
                            </label>
                            <input class="form-control" disabled v-model="point.company.name">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>{{ trans("tenderForm.Locality") }}</label>
                            <input class="form-control" disabled v-model="point.locality.name">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>{{ trans("tenderForm.Address") }}</label>
                            <input class="form-control" disabled v-model="point.address">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>{{ trans("tenderForm.Contacts") }}</label>
                            <input class="form-control" v-model="point.contacts">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

/**
 *************************************
 *               Адреса
 *************************************
 *
 * В выпадающем списке показываются ТОП-адреса
 * Остальные адреса доступны через поиск
 *
 * 1. Если в Vuex уже есть данные, они попадают в options как ТОП-адреса
 * 2. Если нет, то watch.topList наблюдает за computed.topList,
 * и при появлении значения выставляет options
 *
 * 1-й механизм срабатывает при нажатии "добавить точку", 2-й - при загрузке страницы
 *
 * При смене типа загрузка <=> разгрузка срабатывает watch.type
 *
 *************************************
 *           Другие свойства
 *************************************
 *
 * Такие поля, как
 * - address
 * - company
 * - locality
 * - contacts
 * хранятся в БД в таблице `route` и при открытии тендера на редактирование должны
 * проставляться из нее. Это связано с тем, что исходный адрес `src_address_id`
 * может быть отредактирован, и это вызовет нежелательное изменение данных в тендере.
 * При выборе же из выпадаюещего списка, используются его справочные данные `changeSrsAddress`
 * При добавлении новой точки вызывается `getPointTemplate`
 */


import AddressService from "../../../services/AddressService";

export default {
    props: ["point", "type"],
    data() {
        return {
            options: [],
            searchText: ""
        };
    },
    computed: {
        topListGetter() {
            return "top_" + (this.point.is_unloading ? "un" : "") + "loading";
        },
        topList() {
            return this.$store.state.tenderForm[this.topListGetter];
        },
    },
    watch: {
        topList() {
            if (this.options.length || !this.topList.length) return;
            this.options = this.topList;
        },
        type() {
            // TODO проверять возможность оставить тот же адрес
            this.options = this.topList;
        }
    },
    mounted() {
        this.options = this.$store.state.tenderForm[this.topListGetter];
    },
    methods: {
        changeSrsAddress(address) {
            let copyFields = [
                "address", "company", "locality", "contacts", "company_id",
                "locality_id", "short_name"
            ];
            if (address) {
                for (let fieldName of copyFields) {
                    this.point[fieldName] = address[fieldName];
                }
                this.point.src_address_id = address.id;
            } else {
                for (let fieldName of copyFields) {
                    this.point[fieldName] = null;
                }
            }
        },
        onSearch(text, loading) {
            this.searchText = text;
            this.search(text, loading, this);
        },
        search: debounce(async (text, loading, vm) => {
            loading(true);
            let resp = await AddressService.searchByTypeAndText(
                text, vm.point.is_loading ? "is_loading" : "is_unloading"
            );
            vm.options = resp.data.data;
            loading(false);
        }, 200),
        highlight(text) {
            if (!text) return null;
            if (!this.searchText) return text;
            let regex = new RegExp('(' + this.escapeRegExp(this.searchText) + ')', "ig");
            return text.replace(regex, "<span class='bg-yellow'>$1</span>");
        },
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        },
        filter(options, search) {
            return options;
        }
    }
}
</script>
