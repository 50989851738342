<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :loadDefaultData="loadDefaultData"
            :withTrashed="can('deleted-address-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">
                <form-input :value.sync="item.short_name" :name="trans('address.Short name')"
                            :rules="'required'"></form-input>

                <form-select v-if="localities.length"
                             :value="item.locality_id" v-on:change="item.locality_id = $event"
                             :name="trans('locality.Locality')" :options="localities"
                             :rules="'required'"></form-select>
                <loading-form-field v-else>{{ trans('locality.Locality') }}</loading-form-field>

                <form-input :value.sync="item.address" :name="trans('address.Address')"
                            :rules="'required'"></form-input>
                <form-checkbox :value.sync="item.is_loading" :name="trans('address.Is loading')"
                               :rules="{ required: { allowFalse: true } }"></form-checkbox>
                <form-checkbox :value.sync="item.is_unloading" :name="trans('address.Is unloading')"
                               :rules="{ required: { allowFalse: true } }"></form-checkbox>
                <form-input :value.sync="item.contacts" :name="trans('address.Contacts')"
                            :rules="'required'"></form-input>
                <template v-if="can('pick-address-company')">
                    <form-select v-if="companies.length"
                                 :value="item.company_id" v-on:change="item.company_id = $event"
                                 :name="trans('Company')" :options="companies"
                                 :rules="'required'"></form-select>
                    <loading-form-field v-else>{{ trans('Company') }}</loading-form-field>
                </template>
            </template>
        </UniversalForm>
    </div>
</template>

<script>
import AddressService from "../../services/AddressService";
import LocalityService from "../../services/LocalityService";
import CompanyService from "../../services/CompanyService";

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            localities: [],
            companies: [],
            service: AddressService
        }
    },
    async mounted() {
        try {
            this.loadLocalities();

            if (this.can('pick-address-company')) {
                this.loadCompanies();
            }
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", item.short_name);
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                locality_id: null,
                short_name: "",
                is_loading: false,
                is_unloading: false,
                contacts: "",
                company_id: null,
            });
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "address-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "address"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "address-detail", params: {id: vm.item.id}});
        },
        async loadLocalities() {
            let response = await LocalityService.getListIdNameAll();
            this.localities = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        async loadCompanies() {
            let response = await CompanyService.getListIdNameAll();
            this.companies = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
    }
}
</script>
