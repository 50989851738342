/**
 * @see \App\Domain\User\Policies\UserPolicy
 */
export default {
    "create-user": (user) => {
        return user.hasAccess(["user-all-admin", "user-all-create", "user-own-admin", "user-own-create"]);
    },
    "read-user": (user, user2) => {
        return user.hasAccess(["user-all-admin", "user-all-read"]) ||
            (user.id === user2.id && user.hasAccess(["user-self-read"])) ||
            (user.company_id && (user.company_id === user2.company_id) && user.hasAccess(["user-own-admin", "user-own-read"]));
    },
    "read-user-list": (user) => {
        return user.hasAccess(["user-all-admin", "user-all-read", "user-own-admin", "user-own-read"]);
    },
    "update-user": (user, user2) => {
        return !user2.is_system &&
            (
                user.hasAccess(["user-all-admin", "user-all-update"]) ||
                (user.id === user2.id && user.hasAccess(["user-self-update"])) ||
                (user.company_id && (user.company_id === user2.company_id) && user.hasAccess(["user-own-admin", "user-own-update"]))
            );
    },
    "delete-user": (user, user2) => {
        if (user2.deleted_at) return false;
        return !user2.is_system && user.id !== user2.id &&
            (
                user.hasAccess(["user-all-delete", "user-own-delete"]) ||
                (user.company_id && (user.company_id === user2.company_id) && user.hasAccess(["user-own-admin", "user-own-delete"]))
            );
    },
    "restore-user": (user, user2) => {
        return !!user2.deleted_at && (
            user.hasAccess(["user-all-restore", "user-own-restore"]) ||
            (user.company_id && (user.company_id === user2.company_id) && user.hasAccess(["user-own-admin", "user-own-restore"]))
        );
    },
    "login-as-user": (user, user2) => {
        return user.hasAccess(["user-all-admin", "user-all-read"]) ||
            (user.company_id && (user.company_id === user2.company_id) && user.hasAccess(["user-own-admin"]));
    },
    "pick-any-user-company": (user) => {
        return user.hasAccess(["user-all-admin", "user-all-create"]);
    },
    "set-user-company": (user, company) => {
        return user.hasAccess(["user-all-admin", "user-all-create"]) ||
            (user.hasAccess["user-own-admin"] && user.company_id === company.id);
    },
    "pick-any-user-role": (user) => {
        return user.hasAccess(["user-all-admin", "user-all-create"]);
    },
    "pick-own-user-role": (user) => {
        return user.hasAccess(["user-all-admin", "user-all-create", "user-own-admin"]) && user.company_id;
    },
    "pick-user-role": (user) => {
        /**
         * pick-any-user-role || pick-own-user-role
         */
        return user.hasAccess(["user-all-admin", "user-all-create"]) ||
            (user.hasAccess(["user-all-admin", "user-all-create", "user-own-admin"]) && user.company_id)
    },
    "deleted-user-read": (user) => {
        return user.hasAccess(["user-all-admin", "user-all-delete", "user-own-admin", "user-own-delete"]);
    },
};
