<template>

    <div class="form-group">
        <validation-provider :rules="rules" v-slot="{ errors }" class="d-block" :vid="vid">
            <label v-if="name">{{ name }}</label>

            <v-select v-model="value" :options="options" :reduce="o => o.id"
                      :filter="fuseSearch"
                      :multiple="multiple"
                      @input="$emit('change', value)">
                <template #no-options="{ search, searching, loading }">
                    {{ trans("No results") }}
                </template>
            </v-select>

            <div class="text-danger small mt-1">{{ errors[0] }}</div>
        </validation-provider>
    </div>


</template>

<script>
import Fuse from "fuse.js";

export default {
    name: "form-select",
    props: ["vid", "name", "value", "rules", "options", "multiple"],
    methods: {
        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["label"],
                shouldSort: true
            });
            return search.length
                ? fuse.search(search).map(({item}) => item)
                : fuse.list;
        }
    }
}
</script>
