import axios from "../axios";
import ApiAggridRequestParams from "../aggrid/api/ApiAggridRequestParams";

class FeatureFlagService {
    getRoute() {
        return "feature-flag";
    }

    getListAggrid(perPage, requestParams, omniFilter) {
        let params = new ApiAggridRequestParams(perPage, requestParams, ["accessible"], omniFilter);

        console.log(JSON.stringify(params));
        return axios.get("feature-flag", {params});
    }

    changeAccessible(id, accessible) {
        return axios.patch(`${this.getRoute()}/${id}`, {accessible});
    }
}

export default new FeatureFlagService();
