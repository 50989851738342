import Vue from "vue";
import User from "../../models/User";

export default Vue.extend({
    template: `
        <div v-if="firstUser">
            <template v-if="canRead(firstUser)">
                <span @click="params.$router.push({name: 'user-detail', params: {id: firstUser.id}})"
                      class="text-blue cursor-pointer mr-1">
                   {{ fio(firstUser) }}
                </span>
                <span v-if="anotherUsersCnt > 0" class="badge badge-light cursor-pointer"
                      @click="params.$router.push({name: 'company-detail', params: {id: firstUser.company_id}})">
                    + {{ params.data.users.length }} <i class="fas fa-user"></i>
                </span>
            </template>
            <template v-else>
                {{ fio(firstUser) }}
            </template>
        </div>
    `,
    methods: {
        fio(user) {
            return User.fi(user);
        },
        canRead(user) {
            return this.can("read-user", user);
        }
    },
    computed: {
        firstUser() {
            try {
                return this.params.data.users[0];
            } catch (e) {
                return null;
            }
        },
        anotherUsersCnt() {
            return this.params.data.users.length - 1;
        }
    }
});
