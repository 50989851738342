<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>

        <div class="row" v-if="tender.id">
            <div class="col-lg-6 col-xl-4">

                <div class="card card-primary card-outline" :class="{'card-danger': tender.deleted_at }">
                    <div class="card-body box-profile">
                        <h3 v-if="tender.tender_type" class="profile-username text-center">
                            <i v-if="tender.deleted_at" class="fas fa-trash-alt text-danger"></i>
                            {{ tenderName }}
                        </h3>
                        <p v-if="tender.status" class="text-muted text-center mb-0">
                            {{ tender.status.name }}
                        </p>

                        <ul class="list-group list-group-unbordered mt-3 mb-3">
                            <li class="list-group-item">
                                <b>{{ trans('tender.Class') }}</b>
                                <span class="float-right" v-if="tender.tender_class">{{
                                        tender.tender_class.name
                                    }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans('tender.Vehicle type') }}</b>
                                <span class="float-right" v-if="tender.vehicle_type">{{
                                        tender.vehicle_type.name
                                    }}</span>
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-weight-hanging text-muted text-sm"></i>
                                <b>{{ trans('tender.Total cargo weight (tn)') }}</b>
                                <span class="float-right">{{ tender.cargo_weight }}</span>
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-cube text-muted text-sm"></i>
                                <b v-html="trans('tender.Total cargo volume')"></b>
                                <span class="float-right">{{ tender.cargo_volume }}</span>
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-calendar-alt text-muted text-sm"></i>
                                <b>{{ trans('tender.Start of trading') }}</b>
                                <span class="float-right">{{ this.momentDateTime(tender.bet_start) }}</span>
                            </li>
                            <li class="list-group-item">
                                <i class="fas fa-calendar-alt text-muted text-sm"></i>
                                <b>{{ trans('tender.End of trading') }}</b>
                                <span class="float-right">{{ this.momentDateTime(tender.bet_end) }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans('tender.Auction step') }}</b>
                                <span class="float-right">{{ tender.auction_step }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans('tender.Starting bid') }}</b>
                                <span class="float-right">{{ tender.starting_bid }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans('Has Vat') }}</b>
                                <span class="float-right">
                                    <template v-if="tender.has_vat">
                                        <i class="fas fa-check"></i>
                                        {{ trans("yes") }}
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-times"></i>
                                        {{ trans("no") }}
                                    </template>
                                </span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans('tender.Connect Movizor') }}</b>
                                <span class="float-right">
                                    <template v-if="tender.is_movizor">
                                        <i class="fas fa-check"></i>
                                        {{ trans("yes") }}
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-times"></i>
                                        {{ trans("no") }}
                                    </template>
                                </span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans('Note') }}</b>
                                <span class="float-right">{{ tender.comment }}</span>
                            </li>
                        </ul>

                        <div class="mb-4">
                            <button type="button" class="btn btn-warning mr-2"
                                    v-if="canDownloadRequest"
                                    @click="downloadRequest">
                                <i class="fas fa-file-signature"></i>
                                {{ trans("tender.Request") }}
                            </button>
                            <button type="button" class="btn btn-warning"
                                    v-if="canDownloadRouteSheet"
                                    @click="downloadRouteSheet">
                                <i class="fas fa-route mr-2"></i>
                                {{ trans("tender.Route sheet") }}
                            </button>
                        </div>


                        <router-link v-if="can('update-tender', tender)"
                                     :to="{name: 'tender-edit', params: {id: tender.id}}"
                                     class="btn btn-sm btn-primary mr-1">
                            <i class="fas fa-pencil-alt"></i>
                        </router-link>

                        <template v-if="can('create-tender')">
                            <router-link :to="{ name: 'tender-clone', params: { id: tender.id } }"
                                         class="btn btn-sm btn-primary mr-1">
                                <i class="fas fa-clone"></i>
                            </router-link>
                        </template>

                    </div>
                </div>

                <WinBet v-if="tender.win_bet" :win_bet="tender.win_bet"></WinBet>

            </div>
            <div class="col-lg-6 col-xl-8">
                <RideForm v-if="tender.win_bet && (can('create-ride') || can('update-ride', tender.ride) || can('read-ride', tender.ride))"
                          :storedRide="tender.ride" :winBet="tender.win_bet"></RideForm>

                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link active" href="#route"
                                                    data-toggle="tab">{{ trans("tender.Route") }}</a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="#tender-bets"
                                                    data-toggle="tab">{{ trans("tender.Tender bets") }}</a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="#history"
                                                    data-toggle="tab">{{ trans("tender.Bet history") }}</a></li>
                        </ul>
                    </div>
                    <div class="card-body p-0">
                        <div class="tab-content">
                            <div class="tab-pane p-4 active" id="route">
                                at_point_id: {{ tender.at_point_id }}
                                <RouteTimeline :points="tender.route_points"></RouteTimeline>
                            </div>

                            <div class="tab-pane" id="tender-bets">
                                <TenderBets :tenderId="tender.id"/>
                            </div>

                            <div class="tab-pane p-4" id="history">
                                <BetHistory :tenderId="tender.id"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <router-link class="btn btn-primary"
                     :to="{name: 'tender'}">{{ trans("tender.Back to the list of tenders") }}
        </router-link>
    </div>
</template>

<script>
import Vue from "vue";
import TenderService from "../../services/TenderService";
import RouteTimeline from "./route-timeline/RouteTimeline";
import WinBet from "./bet/WinBet";
import RideForm from "./ride/RideForm";
import BetHistory from "./bet/BetHistory";
import TenderBets from "./bet/TenderBets";

Vue.component("RouteTimeline", RouteTimeline);

export default {
    components: {TenderBets, BetHistory, RideForm, WinBet},
    props: ['id'],
    data() {
        return {
            tender: {}
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        canDownloadRequest() {
            if (!this.tender.status) return false;
            return ["new", "open"].indexOf(this.tender.status.slug) === -1;
        },
        canDownloadRouteSheet() {
            return this.canDownloadRequest;
        },
        tenderName() {
            return this.tender.tender_type ? `${this.tender.tender_type.name} ${this.tender.id}` : null;
        }
    },
    methods: {
        reload() {
            this.getData();
        },
        async getData() {
            try {
                this.$refs.formResponseErrors.hide();
                let response = await TenderService.getDetail(this.id, this.can('deleted-tender-read'));
                this.tender = response.data.data;
                this.$store.dispatch("breadcrumbs/setTitle", this.tenderName);
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
        },
        downloadRequest() {
            TenderService.downloadRequest(this.tender.id);
        },
        downloadRouteSheet() {
            TenderService.downloadRouteSheet(this.tender.id);
        }
    }
}
</script>
