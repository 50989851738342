<template>
    <div>
        <section class="content">
            <div class="error-page">
                <h2 class="headline text-warning">404</h2>

                <div class="error-content">
                    <h3><i class="fas fa-exclamation-triangle text-warning"></i> {{ trans("Not Found") }}</h3>

                    <p>
                        {{ details }}
                    </p>

                    <form class="search-form">
                        <div class="input-group">
                            <input type="text" name="search" class="form-control" :placeholder="trans('Search')">

                            <div class="input-group-append">
                                <button type="submit" name="submit" class="btn btn-warning"><i
                                    class="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: ["rawError"],
    computed: {
        details() {
            return this.getHttpError(this.rawError);
        }
    }
}
</script>
