<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>
        <div class="content pb-4">
            <div class="container-fluid">
                <div class="row">
                    <loading-spinner v-if="isLoading" class="mx-auto"></loading-spinner>
                    <template v-else>
                        <div v-if="text" class="card">
                            <div class="card-body" v-html="text"></div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageService from "../../services/PageService";

export default {
    props: ["slug"],
    data() {
        return {
            text: null,
            isLoading: true
        };
    },
    methods: {
        reload() {
            this.getData();
        },
        async getData() {
            this.isLoading = true;
            this.$refs.formResponseErrors.hide();
            try {
                let resp = await PageService.fromStorage(this.slug);
                this.text = resp.data;
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
            this.isLoading = false;
        }
    },
    mounted() {
        this.getData();
    }
}
</script>
