import Vue from "vue";

export default Vue.extend({
    template: `
        <div>
            <template v-if="canEdit || canDelete || canRestore">
                <span v-if="canEdit" @click="params.editCb(params.value, params)"
                      class="aggrid-btn btn btn-xs btn-success" :class="{'mr-2': canDelete || canRestore}" :title="trans('Edit')">
                    <i class="fas fa-pencil-alt"></i>
                </span><span v-if="canDelete" @click="params.deleteCb(params.value, params)"
                             class="aggrid-btn btn btn-xs btn-danger" :title="trans('Delete')">
                    <i class="fas fa-trash-alt"></i>
                </span><span v-if="canRestore" @click="params.restoreCb(params.value, params)"
                             class="aggrid-btn btn btn-xs btn-dark" :title="trans('Restore')">
                    <i class="fas fa-trash-restore"></i>
                </span>
            </template>
            <template v-else-if="canRead">
                <span @click="params.readCb(params.value, params)"
                      class="aggrid-btn btn btn-xs btn-primary" :title="trans('View')">
                    <i class="fas fa-eye"></i>
                </span>
            </template>
        </div>
    `,
    computed: {
        canRead() {
            try {
                return this.params.canRead(this.params) && typeof this.params.readCb === "function";
            } catch (e) {
                return false;
            }
        },
        canEdit() {
            try {
                return this.params.canEdit(this.params) && typeof this.params.editCb === "function";
            } catch (e) {
                return false;
            }
        },
        canDelete() {
            try {
                return this.params.canDelete(this.params) && typeof this.params.deleteCb === "function";
            } catch (e) {
                return false;
            }
        },
        canRestore() {
            try {
                return this.params.canRestore(this.params) && typeof this.params.restoreCb === "function";
            } catch (e) {
                return false;
            }
        }
    }
});
