import VueRouter from "vue-router";
// import Page404 from "../views/page/Page404"
import Company from "./Company";
import Address from "./Address";
import Driver from "./Driver";
import Vehicle from "./Vehicle";
import User from "./User";
import Region from "./Region";
import Locality from "./Locality";
import TenderStatus from "./TenderStatus";
import VehicleType from "./VehicleType";
import Tender from "./Tender";
import Tendering from "./Tendering";
import AuthService from "./AuthService";
import Page from "./Page";
import Home from "../views/home/Home";
import FeatureFlag from "./FeatureFlag";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            metaTags: {
                title: translate("menu.Home"),
            },
            breadcrumb: translate("menu.Home"),
        },
    },
    ...Company,
    ...Address,
    ...Driver,
    ...Vehicle,
    ...User,
    ...Region,
    ...Locality,
    ...TenderStatus,
    ...VehicleType,
    ...Tender,
    ...Tendering,
    ...Page,
    ...AuthService,
    ...FeatureFlag,
    /**
     * 404
     * Последний роут, так как матчит все адреса
     */
    // {
    //     path: "*",
    //     name: "page-404",
    //     component: Page404,
    //     meta: {
    //         noAuth: true
    //     }
    // },
];

const router = new VueRouter({
    mode: "history",
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active"
});

let isFirstHit = true;

/**
 * Мета-информация: title, h1
 *
 * идея: @link https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
 * как отправлять на /login неавторизованных: @link https://pusher.com/tutorials/authentication-vue-vuex
 */
router.beforeEach((to, from, next) => {
    /**
     * При возникновении серверной ошибки при загрузке страницы, включается @yield('content').
     * Это содержимое находится в секции `id="server-content"`.
     * В дальнейшем, чтобы не мешать работе SPA-приложения, эта секция удаляется.
     */
    if (!isFirstHit) {
        let element = document.getElementById("server-content");
        if (element) {
            element.remove();
        }
    }
    isFirstHit = false;

    router.app.$store.dispatch("breadcrumbs/reset");
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta.metaTags);

    // предыдущий роут, с которого произошел переход
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta.metaTags);

    if (nearestWithMeta) {
        router.app.$store.dispatch("meta/set", nearestWithMeta.meta.metaTags);
    } else {
        router.app.$store.dispatch("meta/set", {
            h1: "",
            title: translate("Tenders Online")
        });
    }

    next();
});

export default router;
