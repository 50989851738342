/**
 * @see \App\Domain\Tender\Policies\TenderPolicy
 */
export default {
    'create-tender': (user) => {
        return user.hasAccess(["tender-all-admin", "tender-all-create"]);
    },
    'read-tender': (user, tender) => {
        return user.hasAccess(["tender-all-admin", "tender-all-read"]);
    },
    'read-tender-list': (user) => {
        return user.hasAccess(["tender-all-admin", "tender-all-read"]);
    },
    'update-tender': (user, tender) => {
        return user.hasAccess(["tender-all-admin", "tender-all-update"]);
    },
    'delete-tender': (user, tender) => {
        if (tender.deleted_at) return false;
        return user.hasAccess(["tender-all-admin", "tender-all-delete"]);
    },
    'restore-tender': (user, tender) => {
        return !!tender.deleted_at && user.hasAccess(["tender-all-admin", "tender-all-restore"]);
    },
    'deleted-tender-read': (user) => {
        return user.hasAccess(["tender-all-admin", "tender-all-delete"]);
    },
};
