<template>
    <!-- Родитель должен иметь класс  class="overlay-wrapper" -->
    <!--    <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin text-primary"></i></div>-->
    <div class="overlay"><img alt="loading" src="/img/wheel.svg" width="200" height="200"></div>

</template>

<script>
export default {
    name: "loading-spinner"
}
</script>
