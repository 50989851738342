<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <router-link :to="{name: 'home'}" class="brand-link clearfix">
            <img src="/img/logo-site.svg" alt="Logo"
                 class="brand-image"
            >
        </router-link>

        <!-- Sidebar -->
        <div class="sidebar">
            <auth-menu></auth-menu>

            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item" v-if="can('read-tender-list')">
                        <router-link to="/tendering" class="nav-link">
                            <i class="nav-icon icon-bid"></i>
                            {{ trans("menu.Tendering") }}
                        </router-link>
                    </li>

                    <li class="nav-item" v-if="can('read-tender-list')">
                        <router-link to="/tender" class="nav-link">
                            <i class="nav-icon fas fa-business-time"></i>
                            {{ trans("menu.Tenders") }}
                        </router-link>
                    </li>

                    <li class="nav-item" v-if="can('read-company-list')">
                        <router-link to="/company" class="nav-link">
                            <i class="nav-icon fas fa-handshake"></i>
                            <p>{{ trans('menu.Companies') }}</p>
                        </router-link>
                    </li>

                    <li class="nav-item" v-if="can('read-address-list')">
                        <router-link to="/address" class="nav-link">
                            <i class="nav-icon fas fa-map-marked-alt"></i>
                            <p>{{ trans('menu.Addresses') }}</p>
                        </router-link>
                    </li>

                    <li class="nav-item" v-if="can('read-driver-list')">
                        <router-link to="/driver" class="nav-link">
                            <i class="nav-icon icon-driver"></i>
                            <p>{{ trans('menu.Drivers') }}</p>
                        </router-link>
                    </li>

                    <li class="nav-item" v-if="can('read-vehicle-list')">
                        <router-link to="/vehicle" class="nav-link">
                            <i class="nav-icon fas fa-truck"></i>
                            <p>{{ trans('menu.Vehicles') }}</p>
                        </router-link>
                    </li>

                    <li class="nav-item" v-if="can('read-user-list')">
                        <router-link to="/user" class="nav-link">
                            <i class="nav-icon fas fa-users"></i>
                            <p>{{ trans('menu.Users') }}</p>
                        </router-link>
                    </li>

                    <li class="nav-header text-uppercase"
                        v-if="showDirectories">
                        {{ trans("menu.Directories") }}
                    </li>

                    <li class="nav-item has-treeview" :class="{'menu-open': inGeoMenu}"
                        v-if="can('read-region-list') || can('read-locality-list')"
                    >
                        <a href="#" class="nav-link" :class="{'active': inGeoMenu}">
                            <i class="nav-icon fas fa-map"></i>
                            <p>
                                {{ trans('menu.Geo') }}
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item" v-if="can('read-region-list')">
                                <router-link to="/region" class="nav-link">
                                    <i class="nav-icon fas fa-globe"></i>
                                    <p>{{ trans('menu.Region') }}</p>
                                </router-link>
                            </li>

                            <li class="nav-item" v-if="can('read-locality-list')">
                                <router-link to="/locality" class="nav-link">
                                    <i class="nav-icon fas fa-city"></i>
                                    <p>{{ trans('menu.Locality') }}</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item" v-if="can('read-tender-status-list')">
                        <router-link to="/tender-status" class="nav-link">
                            <i class="nav-icon icon-status"></i>
                            <p>{{ trans('menu.Tender Statuses') }}</p>
                        </router-link>
                    </li>

                    <li class="nav-item" v-if="can('read-vehicle-type-list')">
                        <router-link to="/vehicle-type" class="nav-link">
                            <i class="nav-icon fas fa-truck"></i>
                            <p>{{ trans('menu.Vehicle Types') }}</p>
                        </router-link>
                    </li>

                    <li class="nav-header text-uppercase"
                        v-if="showConfig">
                        {{ trans("menu.Config") }}
                    </li>

                    <li class="nav-item" v-if="can('read-feature-flag-list') || can('update-feature-flag')">
                        <router-link to="/feature-flag" class="nav-link">
                            <i class="nav-icon fas fa-check-circle"></i>
                            <p>{{ trans('menu.Feature flags') }}</p>
                        </router-link>
                    </li>

                </ul>
            </nav>

        </div>

    </aside>
</template>

<script>
import AuthMenu from "./AuthMenu";

export default {
    components: {AuthMenu},
    computed: {
        inGeoMenu() {
            return !!this.$route.path.match(/^\/(region|locality)/);
        },
        showDirectories() {
            return this.can('read-region-list') || this.can('read-locality-list')
                || this.can('read-tender-status-list') || this.can('read-vehicle-type-list');
        },
        showConfig() {
            return this.can('read-feature-flag-list') || this.can('update-feature-flag');
        }
    }
}
</script>
