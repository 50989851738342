import UserService from "../services/UserService";
import User from "../models/User";

export const userSettings = {
    namespaced: true,
    state: {
        user: {},
        error: null,
    },
    actions: {
        async initState(context, payload) {
            context.dispatch("loadData", payload);
        },
        async loadData({commit}, payload) {
            try {
                let resp = await UserService.getConfig(payload.id);
                let user = new User;
                user = Object.assign(user, resp.data.data);
                commit("setUser", user);
            } catch (error) {
                commit("throwError", error);
            }
        },
        setNotify(context, payload) {
            if (context.state.user.config === null) {
                context.commit("initConfig");
            }

            let via = payload.via;
            let type = payload.type;
            let value = payload.value;

            let config = _.cloneDeep(context.state.user.config);

            if (!config.notify.via[type]) {
                config.notify.via[type] = [];
            }

            let viaList = config.notify.via[type];

            if (value) {
                if (viaList.indexOf(via) === -1) {
                    viaList.push(via);
                }
            } else {
                config.notify.via[type] = _.remove(viaList, (o) => {
                    return o !== via;
                });
            }

            context.commit("setConfig", config);
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        throwError(state, error) {
            state.error = error;
        },
        initConfig(state) {
            state.user.config = {
                notify: {
                    via: {}
                }
            };
        },
        async setConfig(state, config) {
            state.user.config = config;
            console.log(JSON.stringify(config));
            try {
                let resp = await UserService.setConfig(state.user.id, config);
            } catch (error) {
                state.error = error;
            }
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        config(state) {
            return state.user.config;
        }
    }
}