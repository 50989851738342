import UserList from "../views/user/UserList";
import UserDetail from "../views/user/UserDetail";
import UserForm from "../views/user/UserForm";

let ico = '<i class="fas fa-users mr-2"></i>';

export default [
    {
        path: "/user",
        name: "user",
        component: UserList,
        meta: {
            metaTags: {
                title: translate("user.Users"),
                h1: ico + translate("user.Users"),
            },
            breadcrumb: {
                label: translate("user.Users"),
                parent: "home"
            }
        }
    },
    {
        path: "/user/:id/edit",
        name: "user-edit",
        props: route => ({id: route.params.id, view: "edit"}),
        component: UserForm,
        meta: {
            metaTags: {
                title: translate("user.User"),
                h1: ico + translate("user.User Editing"),
            },
            breadcrumb: {
                label: translate("user.User"),
                parent: "user"
            },
            rwBc: true
        }
    },
    {
        path: "/user/:id/clone",
        name: "user-clone",
        props: route => ({id: route.params.id, view: "clone"}),
        component: UserForm,
        meta: {
            metaTags: {
                title: translate("user.User"),
                h1: ico + translate("user.User Cloning"),
            },
            breadcrumb: {
                label: translate("user.User"),
                parent: "user"
            },
            rwBc: true
        }
    },
    {
        path: "/user/create",
        name: "user-create",
        props: route => ({id: 0, view: "create"}),
        component: UserForm,
        meta: {
            metaTags: {
                title: translate("user.User"),
                h1: ico + translate("user.User Creation"),
            },
            breadcrumb: {
                label: translate("user.User"),
                parent: "user"
            }
        }
    },
    {
        path: "/user/:id",
        name: "user-detail",
        props: true,
        component: UserDetail,
        meta: {
            metaTags: {
                title: translate("user.User"),
                h1: ico + translate("user.User"),
            },
            breadcrumb: {
                label: translate("user.User"),
                parent: "user"
            },
            rwBc: true
        }
    }
];
