<template>
    <div id="tender-form">
        <form-response-errors ref="formResponseErrors"></form-response-errors>

        <ValidationObserver v-slot="{ handleSubmit }" class="d-block" ref="validationObserver">
            <form @submit.prevent="onSubmitPrevent(handleSubmit, submit)" class="overlay-wrapper">

                <div class="row">
                    <div class="col-md-6">

                        <div class="card card-primary">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <span class="icon-bid mr-2"></span>
                                    {{ trans("Tender") }}
                                </h3>

                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                            data-toggle="tooltip"
                                            title="Collapse">
                                        <i class="fas fa-minus"/></button>
                                </div>
                            </div>
                            <div class="card-body">

                                <!-- Тип-->
                                <form-select v-if="tenderTypesSelect.length"
                                             :value="tender.tender_type_id" v-on:change="tender.tender_type_id = $event"
                                             :name="trans('tender.Type')" :options="tenderTypesSelect"
                                             :rules="'required'" vid="tender_type_id"></form-select>
                                <loading-form-field v-else>{{ trans('tender.Type') }}</loading-form-field>


                                <transition name="bounce-11">
                                    <template v-if="tenderTypeSlug === 'appointed'">
                                        <!-- Компания-->
                                        <form-select v-if="appointedCompaniesSelect.length"
                                                     :value="tender.appointed_company_id"
                                                     @change="tender.appointed_company_id = $event"
                                                     :name="trans('Company')" :options="appointedCompaniesSelect"
                                                     :rules="'required'" vid="appointed_company_id"></form-select>
                                        <loading-form-field v-else>{{ trans('Company') }}</loading-form-field>
                                    </template>
                                </transition>

                                <!-- Тип перевозок-->
                                <form-select v-if="tenderClassesSelect.length"
                                             :value="tender.tender_class_id"
                                             @change="tender.tender_class_id = $event"
                                             :name="trans('tender.Class')" :options="tenderClassesSelect"
                                             :rules="'required'" vid="tender_class_id"></form-select>
                                <loading-form-field v-else>{{ trans('tender.Class') }}</loading-form-field>


                                <!--                        Грузоотправитель перенесен в Маршрут-->

                                <!-- Тип автомобиля-->
                                <form-select v-if="vehicleTypesSelect.length"
                                             :value="tender.vehicle_type_id"
                                             @change="tender.vehicle_type_id = $event"
                                             :name="trans('tender.Vehicle type')" :options="vehicleTypesSelect"
                                             :rules="'required'" vid="vehicle_type_id"></form-select>
                                <loading-form-field v-else>{{ trans('tender.Vehicle type') }}</loading-form-field>


                                <div class="row">
                                    <div class="col-lg-6">
                                        <!-- Общий вес груза (тн)-->
                                        <form-number vid="cargo_weight" rules="required|min_value:0.1"
                                                     :value.sync="tender.cargo_weight"
                                                     :min="0" :step="0.1"
                                                     :name="trans('tender.Total cargo weight (tn)')">
                                        </form-number>
                                    </div>
                                    <div class="col-lg-6">
                                        <!-- Общий объем груза (м3)-->
                                        <form-number vid="cargo_volume" rules="required|min_value:0.1"
                                                     :value.sync="tender.cargo_volume"
                                                     :min="0" :step="0.1"
                                                     :name="trans('tender.Total cargo volume')">
                                        </form-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <!-- Начало торгов-->
                                        <form-date-time :value.sync="tender.bet_start" vid="bet_start"
                                                        :name="trans('tender.Start of trading')"
                                                        :rules="'required'"></form-date-time>
                                    </div>
                                    <div class="col-lg-6">
                                        <!-- Окончание торгов-->
                                        <form-date-time :value.sync="tender.bet_end" vid="bet_end"
                                                        :name="trans('tender.End of trading')"
                                                        :rules="'required'"></form-date-time>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <!-- Шаг аукциона-->
                                        <form-number vid="auction_step" rules="required|min_value:1000"
                                                     :value.sync="tender.auction_step"
                                                     :min="0" :step="1000"
                                                     :name="trans('tender.Auction step')">
                                        </form-number>
                                    </div>
                                    <div class="col-lg-6">
                                        <!-- Стартовая цена-->
                                        <form-number vid="starting_bid" rules="required|min_value:1000"
                                                     :value.sync="tender.starting_bid"
                                                     :min="0" :step="1000"
                                                     :name="trans('tender.Starting bid')">
                                        </form-number>
                                    </div>
                                </div>

                                <form-checkbox :value.sync="tender.has_vat" :name="trans('Has Vat')"
                                               vid="has_vat"></form-checkbox>

                                <form-checkbox :value.sync="tender.is_movizor"
                                               :name="trans('tender.Connect Movizor')" vid="is_movizor"></form-checkbox>

                                <form-input :value.sync="tender.comment" :name="trans('Note')"
                                            vid="comment"></form-input>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card card-secondary">
                            <div class="card-header">
                                <h3 class="card-title"><span class="fas fa-route mr-2"/>
                                    {{ trans("tender.Route") }}
                                </h3>
                            </div>
                        </div>

                        <route-point-list :points="tender.route_points"/>

                    </div>
                </div>


                <div class="row">
                    <div class="col-md-6 offset-md-6">
                        <div @click="cancel" :disabled="isSaving" class="btn btn-secondary mr-4">
                            {{ trans("Cancel") }}
                        </div>
                        <button type="submit" :disabled="isSaving" class="btn btn-success">
                            {{ trans("Save") }}
                        </button>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import Vue from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import RoutePointList from "./route/RoutePointList";
import {isObserverValid} from "../../validation/Helper";
import TenderRequestValidator from "../../validation/TenderRequestValidator";

Vue.component("RoutePointList", RoutePointList);

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    computed: {
        ...mapState("tenderForm", [
            "tender", "error",
            "isSaving"
        ]),
        ...mapGetters("tenderForm", [
            "tenderTypesSelect", "tenderClassesSelect", "vehicleTypesSelect", "appointedCompaniesSelect",
            "tenderTypeSlug"
        ])
    },
    watch: {
        error() {
            /**
             * Механизм показа ошибок из модуля tenderForm
             */
            this.$refs.formResponseErrors.show(this.error);
        }
    },
    methods: {
        ...mapActions("tenderForm", ["initState", "saveData"]),
        async onSubmitPrevent(handleSubmit, submit) {
            await handleSubmit(submit);
            // bugfix: this.$refs.validationObserver становится undefined при прохождении валидации
            if (typeof this.$refs.validationObserver !== "undefined" && !isObserverValid(this.$refs.validationObserver)) {
                this.scrollTop();
                this.swalError(this.trans("Check the form is filled out correctly"));
            }
        },
        scrollTop() {
            let element = document.getElementById("tender-form");
            let top = element.offsetTop;
            window.scrollTo({left: 0, top, behavior: "smooth"});
        },
        async submit() {
            try {
                let requestData = this.tender.collectRequestData();
                let tenderFormValidator = new TenderRequestValidator(requestData);
                let errors = tenderFormValidator.validate();

                if (Object.keys(errors).length !== 0) {
                    this.$refs.validationObserver.setErrors(errors);
                    this.swalError(Object.values(errors)[0]);
                    return;
                }

                let id = await this.saveData(requestData);
                this.swalSuccess(this.trans("Data saved"));
                this.$router.push({name: "tender-detail", params: {id}});
            } catch (error) {
                /**
                 * Обработка серверных ошибок
                 * - formResponseErrors показывает ошибки (через watch.error())
                 * - по возможности, подсвечиваются поля
                 */
                try {
                    this.$refs.validationObserver.setErrors(error.response.data.errors);
                } catch (err) {
                }

                this.scrollTop();
                let errorMsg = this.getHttpError(error, this.trans("Delete Error"));
                this.swalError(errorMsg);
            }
        },
        cancel() {
            if (this.tender.id) {
                this.$router.push({name: "tender-detail", params: {id: this.tender.id}});
                return;
            }
            this.$router.push({name: "tender"});
        },
    },
    async mounted() {
        this.initState({id: this.id, view: this.view, withTrashed: this.can('deleted-tender-read')});
    }
}
</script>
