<template>
    <div>
        <transition-group name="flip-list-03" tag="div">
            <route-point v-for="(point, idx) in points"
                         v-bind="{point, idx, pointsCnt: points.length}"
                         :key="point.id"
                         @delete="deletePoint"
                         @reorder="reorderPoint"
                         @changeFirstPoint="recalcStartingBid"
            ></route-point>
        </transition-group>
        <button type="button" class="btn btn-primary mb-4" @click.prevent="addPoint">
            <i class="fas fa-plus-square mr-1"></i> {{ trans("tenderForm.add point") }}
        </button>
    </div>
</template>

<script>
import Vue from "vue";
import RoutePoint from "./RoutePoint";

Vue.component("RoutePoint", RoutePoint);

export default {
    name: "route-point-list",
    props: ["points"],
    data() {
        return {
            newPointsCounter: 0
        };
    },
    watch: {
        points() {
            this.recalcPointNumbers();
        }
    },
    methods: {
        deletePoint(idx) {
            this.points.splice(idx, 1);
            if (idx == 0) this.recalcStartingBid();
        },
        reorderPoint(params) {
            let arr = this.points;

            let idx1 = params.idx;
            let idx2 = params.dir === "up" ? idx1 - 1 : idx1 + 1;
            if (idx2 < 0 || idx2 >= arr.length) return;

            let el1 = arr[idx1];
            let el2 = arr[idx2];

            arr.splice(idx1, 1, el2);
            arr.splice(idx2, 1, el1);

            if (idx1 == 0 || idx2 == 0) this.recalcStartingBid();
        },
        recalcPointNumbers() {
            for (let idx in this.points) {
                this.points[idx].point_number = parseInt(idx);
            }
        },
        recalcStartingBid() {
        },
        addPoint() {
            this.points.push(this.getPointTemplate());
        },
        getPointTemplate() {
            /**
             * Шаблон точки соответствует структуре данных, которая загружается
             * при редактировании/клонировании
             * При выборе конкретного адреса `changeSrsAddress`, эти свойства будут перезаписаны
             */
            let is_loading = this.points.length === 0;
            let is_unloading = !is_loading;

            let id = "new_" + this.newPointsCounter++;
            return {
                id, // ссылка в БД, для обновления(целое) / создания(new_{x})
                is_loading,
                is_unloading,
                arrival_date_plan: null,
                src_address: null,
                address: null,
                company: null,
                locality: null,
                contacts: "",
                company_id: null,
                locality_id: null,
                point_number: null,
                short_name: null,
                src_address_id: null,
            };
        }
    }
}
</script>
