<template>
    <div>
        <table class="table table-sm">
            <thead>
            <tr>
                <th>Дата ставки</th>
                <th>Компания</th>
                <th>Сотрудник</th>
                <th>KPI</th>
                <th>Ставка</th>
                <th>Действие</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="bet in bets">
                <td>{{ momentDateTime(bet.created_at) }}</td>
                <td><CompanyLink :company="bet.company"></CompanyLink></td>
                <td>
                    <UserLink :user="bet.creator"></UserLink>
                </td>
                <td>{{ bet.company.kpi }}%</td>
                <td>{{ bet.bid }}</td>
                <td><button class="btn btn-primary btn-sm">Назначить</button></td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import BetService from "../../../services/BetService";
import UserLink from "../../partial/links/UserLink";

export default {
    components: {UserLink},
    props: ["tenderId"],
    data() {
        return {
            bets: []
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            let response = await BetService.actualBets(this.tenderId);
            this.bets = response.data.data;
        }
    }
}
</script>
