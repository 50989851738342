<template>
    <div>
        <UniversalForm
            :id="id" :view="view"
            :service="service"
            :loadDefaultData="loadDefaultData"
            :withTrashed="can('deleted-vehicle-type-read')"
            @dataLoaded="dataLoaded"
            @cancel="cancel"
            @aftersubmit="aftersubmit"
        >
            <template v-slot="{ item }">
                <form-input :value.sync="item.name" :name="trans('vehicle-type.Name')"
                            :rules="'required'"></form-input>

                <form-select
                    :value="item.class_id" v-on:change="item.class_id = $event"
                    :name="trans('vehicleType.Type')" :options="vehicleClasses"
                    :rules="'required'"></form-select>

            </template>
        </UniversalForm>
    </div>
</template>

<script>
import VehicleTypeService from "../../services/VehicleTypeService";
import VehicleClassService from "../../services/VehicleClassService";

export default {
    props: ["id", "view"], // данные берутся из Vue.$router
    data() {
        return {
            service: VehicleTypeService,
            vehicleClasses: []
        }
    },
    async mounted() {
        try {
            this.loadVehicleClasses();
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        async loadVehicleClasses() {
            let response = await VehicleClassService.getListIdNameAll();
            this.vehicleClasses = response.data.data.map(o => {
                return {id: o.id, label: o.name}
            });
        },
        dataLoaded(item) {
            this.$store.dispatch("breadcrumbs/setTitle", item.name);
        },
        loadDefaultData() {
            return _.clone({
                id: 0,
                name: "",
                class_id: null
            });
        },
        cancel(vm) {
            if (vm.item.id) {
                this.$router.push({name: "vehicle-type-detail", params: {id: vm.item.id}});
                return;
            }
            this.$router.push({name: "vehicle-type"});
        },
        aftersubmit(vm) {
            this.$router.push({name: "vehicle-type-detail", params: {id: vm.item.id}});
        },
    }
}
</script>
