export default {
    field: "accessible",
    width: 120,
    minWidth: 120,
    maxWidth: 120,
    filter: "agNumberColumnFilter",
    suppressMenu: true,
    floatingFilter: true,
    floatingFilterComponent: "FloatingYesNoFilter",
    floatingFilterComponentParams: {
        suppressFilterButton: true
    },
    sortable: false,
    cellRenderer: "FFCellRenderer",
    cellClass: "aggrid-cell-actions"
}
