<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>

        <div class="row" v-if="address.id">
            <div class="col-lg-6 col-xl-4">

                <div class="card card-primary card-outline" :class="{'card-danger': address.deleted_at }">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <i v-if="address.deleted_at" class="fas fa-trash-alt fa-2x text-danger"></i>
                            <i v-else class="fas fa-map-marked-alt fa-2x"></i>
                        </div>

                        <h3 class="profile-username text-center">{{ address.short_name }}</h3>

                        <p class="text-muted text-center" v-if="address.company">
                            <CompanyLink :company="address.company" :ico="true"></CompanyLink>
                        </p>

                        <ul class="list-group list-group-unbordered mt-3 mb-3">
                            <li class="list-group-item">
                                <b>{{ trans("address.Address") }}</b>
                                <span class="float-right">{{ address.address }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans("address.Is loading") }}</b>
                                <span class="float-right">
                                    <i v-if="address.is_loading" class="fas fa-check"></i>
                                </span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans("address.Is unloading") }}</b>
                                <span class="float-right">
                                    <i v-if="address.is_unloading" class="fas fa-check"></i>
                                </span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans("address.Contacts") }}</b>
                                <span class="float-right">{{ address.contacts }}</span>
                            </li>
                            <li class="list-group-item" v-if="address.locality">
                                <b>{{ trans("locality.Locality") }}</b>
                                <router-link v-if="can('read-locality')"
                                             :to="{name: 'locality-detail', params: {id: address.locality.id}}"
                                             class="float-right">
                                    {{ address.locality.name }}
                                </router-link>
                                <span v-else class="float-right">{{ address.locality.name }}</span>
                            </li>
                        </ul>

                        <router-link v-if="can('update-address')"
                                     :to="{name: 'address-edit', params: {id: address.id}}"
                                     class="btn btn-sm btn-primary mr-1">
                            <i class="fas fa-pencil-alt"></i>
                        </router-link>

                        <template v-if="can('create-address')">
                            <router-link :to="{ name: 'address-clone', params: { id: address.id } }"
                                         class="btn btn-sm btn-primary mr-1">
                                <i class="fas fa-clone"></i>
                            </router-link>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressService from "../../services/AddressService";

export default {
    props: ['id'],
    data() {
        return {
            address: {}
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        reload() {
            this.getData();
        },
        async getData() {
            try {
                this.$refs.formResponseErrors.hide();
                let response = await AddressService.getDetail(this.id, this.can('deleted-address-read'));
                this.address = response.data.data;
                this.$store.dispatch("breadcrumbs/setTitle", this.address.short_name);
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
        }
    }
}
</script>
