<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>

        <div class="row" v-if="vehicleType.id">
            <div class="col-lg-6 col-xl-4">

                <div class="card card-primary card-outline" :class="{'card-danger': vehicleType.deleted_at }">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <i v-if="vehicleType.deleted_at" class="fas fa-trash-alt fa-2x text-danger"></i>
                            <i v-else class="fas fa-truck fa-2x"></i>
                        </div>

                        <h3 class="profile-username text-center">{{ vehicleType.name }}</h3>

                        <ul class="list-group list-group-unbordered mt-3 mb-3">
                            <li class="list-group-item">
                                <b>{{ trans("vehicleType.Type") }}</b>
                                <span class="float-right">{{ getClassName(vehicleType.class_id) }}</span>
                            </li>
                        </ul>

                        <router-link v-if="can('update-vehicle-type')"
                                     :to="{name: 'vehicle-type-edit', params: {id: vehicleType.id}}"
                                     class="btn btn-sm btn-primary mr-1">
                            <i class="fas fa-pencil-alt"></i>
                        </router-link>

                        <template v-if="can('create-vehicle-type')">
                            <router-link :to="{ name: 'vehicle-type-clone', params: { id: vehicleType.id } }"
                                         class="btn btn-sm btn-primary mr-1">
                                <i class="fas fa-clone"></i>
                            </router-link>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VehicleTypeService from "../../services/VehicleTypeService";
import VehicleClassService from "../../services/VehicleClassService";

export default {
    props: ['id'],
    data() {
        return {
            vehicleType: {},
            vehicleClasses: []
        }
    },
    mounted() {
        this.getData();
        this.getVehicleClass();
    },
    methods: {
        reload() {
            this.getData();
        },
        async getData() {
            try {
                this.$refs.formResponseErrors.hide();
                let response = await VehicleTypeService.getDetail(this.id, this.can('deleted-vehicle-type-read'));
                this.vehicleType = response.data.data;
                this.$store.dispatch("breadcrumbs/setTitle", this.vehicleType.name);
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
        },
        async getVehicleClass() {
            let response = await VehicleClassService.getListIdNameAll();
            this.vehicleClasses = response.data.data.map(o => {
                return {id: o.id, name: o.name}
            });
        },
        getClassName(class_id) {
            let vehicleClass = this.vehicleClasses.find(o => {
                return o.id === class_id;
            });
            return vehicleClass ? vehicleClass.name : "-";
        }
    }
}
</script>
