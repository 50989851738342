<template>

    <div class="form-group">
        <validation-provider :rules="rules" v-slot="{ errors }" class="d-block">
            <label :for="id" v-html="name"></label>
            <div class="input-group">
                <div v-if="ico" class="input-group-prepend">
                    <span class="input-group-text"><i :class="ico"></i></span>
                </div>
                <the-mask v-model="value"
                          class="form-control"
                          :mask="mask"
                          :masked="masked"
                          :class="{'is-invalid': errors[0]}"
                          :id="id"
                          @input="$emit('update:value', value)"
                />
            </div>
            <div class="text-danger small mt-1">{{ errors[0] }}</div>
        </validation-provider>
    </div>

</template>

<script>
export default {
    name: "form-mask",
    props: ["name", "value", "rules", "ico", "mask", "masked"],
    data() {
        return {
            id: this.getRandId("mask-")
        };
    }
}
</script>
