<template>
    <div>
        <form-response-errors ref="formResponseErrors" :reload="reload"></form-response-errors>
        <BlockCompanyModal ref="blockModal" @block="blockCompanyCb"></BlockCompanyModal>

        <div class="row" v-if="company.id">
            <div class="col-lg-6 col-xl-4">

                <!-- Profile Image -->
                <div class="card card-primary card-outline" :class="{'card-danger': company.is_blocked || company.deleted_at }">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <i v-if="company.deleted_at" class="fas fa-trash-alt fa-2x text-danger"></i>
                            <i v-else class="fas fa-truck fa-2x"></i>
                        </div>

                        <h3 class="profile-username text-center">{{ company.name }}</h3>

                        <p v-for="contractor_type in company.contractor_type" class="text-muted text-center mb-0">
                            {{ contractor_type.name }}
                        </p>

                        <div v-if="company.is_blocked" class="info-box my-2 bg-danger">
                            <span class="info-box-icon"><i class="fas fa-ban"></i></span>
                            <div class="info-box-content">
                                <h5>{{ trans("company.The company is blocked") }}</h5>
                                <p><span class="badge badge-light">{{ blockInterval }}</span></p>
                                <p>{{ company.block_reason }}</p>
                            </div>
                        </div>

                        <ul class="list-group list-group-unbordered mt-3 mb-3">
                            <li class="list-group-item">
                                <b>{{ trans("company.INN") }}</b> <span class="float-right">{{ company.inn }}</span>
                            </li>
                            <li class="list-group-item">
                                <b>{{ trans("company.Cargo Type") }}</b>
                                <ul class="float-right list-inline">
                                    <li v-for="cargo_type in company.cargo_type">{{ cargo_type.name }}</li>
                                </ul>
                            </li>
                        </ul>

                        <template v-if="can('update-company', company)">
                            <router-link :to="{ name: 'company-edit', params: { id: company.id } }"
                                         class="btn btn-sm btn-primary mr-1">
                                <i class="fas fa-pencil-alt"></i>
                            </router-link>
                        </template>

                        <template v-if="can('create-company')">
                            <router-link :to="{ name: 'company-clone', params: { id: company.id } }"
                                         class="btn btn-sm btn-primary mr-1">
                                <i class="fas fa-clone"></i>
                            </router-link>
                        </template>

                        <template v-if="can('block-company', company)">
                            <button @click="blockCompany" class="btn btn-primary btn-sm float-right">
                                {{ trans("company.To block") }}
                            </button>
                        </template>

                    </div>
                </div>

                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">{{ trans("company.About Company") }}</h3>
                    </div>
                    <div class="card-body">
                        <strong><i class="fas fa-percentage mr-1"></i> KPI</strong>

                        <p class="text-muted">
                            {{ company.kpi }}
                        </p>


                        <template v-if="company.win_tender_cnt">
                            <hr>
                            <strong><i class="fas fa-truck-loading mr-1"></i>
                                {{ trans("company.Number of won tenders") }}
                            </strong>

                            <p class="text-muted">{{ company.win_tender_cnt }}</p>
                        </template>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-xl-8">
                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item"><a class="nav-link active" href="#activity"
                                                    data-toggle="tab">{{ trans("company.Activity") }}</a>
                            </li>
                            <li class="nav-item"><a class="nav-link" href="#users"
                                                    data-toggle="tab">{{ trans("company.Users") }}</a></li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="tab-pane active" id="activity">
                                <CompanyLog :company_id="id" :ico="true"></CompanyLog>
                            </div>

                            <div class="tab-pane" id="users">
                                <UserList :company_id="id"></UserList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import CompanyService from "../../services/CompanyService";
import UserList from "../user/UserList";
import CompanyLog from "../log/CompanyLog";
import BlockCompanyModal from "../modals/BlockCompanyModal";

Vue.component("UserList", UserList);
Vue.component("CompanyLog", CompanyLog);
Vue.component("BlockCompanyModal", BlockCompanyModal);

export default {
    props: ['id'],
    data() {
        return {
            company: {}
        }
    },
    methods: {
        blockCompany() {
            this.$refs.blockModal.show(this.company);
        },
        async blockCompanyCb(data) {
            try {
                await CompanyService.block(this.company.id, data);
                this.swalSuccess(this.trans("company.The company has been blocked"));
                this.getData();
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
                this.swalError(this.trans("company.Company block error has occurred"));
            }
        },
        reload() {
            this.getData();
        },
        async getData() {
            try {
                this.$refs.formResponseErrors.hide();
                let response = await CompanyService.getDetail(this.id, this.can('deleted-company-read'));
                this.company = response.data.data;
                this.$store.dispatch("breadcrumbs/setTitle", this.company.name);
            } catch (error) {
                this.$refs.formResponseErrors.show(error);
            }
        }
    },
    mounted() {
        this.getData();
    },
    computed: {
        blockInterval() {
            return (this.company.block_start ? this.momentDate(this.company.block_start) : "∞") + " - " +
                (this.company.block_end ? this.momentDate(this.company.block_end) : "∞");
        }
    }
}
</script>
