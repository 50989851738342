<template>
    <div class="input-group">
        <input class="form-control" type="number"
               v-model="newBid"
               :min="minBid" :max="maxBid"
               :step="tender.auction_step"
        >
        <div class="input-group-append">
            <button type="button" class="btn btn-sm btn-primary" @click="createBet">
                <i class="icon-bid"></i>
            </button>
        </div>
    </div>
</template>

<script>

import TenderingService from "../../../services/TenderingService";

export default {
    props: ["tender"],
    data() {
        return {
            inProgress: false,
            newBid: this.calcNewBid()
        }
    },
    methods: {
        async createBet() {
            if (this.inProgress) return;
            this.inProgress = true;
            try {
                let data = {
                    tender_id: this.tender.id,
                    bid: this.newBid
                };
                await TenderingService.createBet(data);
            } catch (error) {
                let errors = this.getHttpErrorList(error, this.trans("tendering.Failed to create a bet"));
                this.swalError(errors[0]);
            }
            this.inProgress = false;
        },
        calcNewBid() {
            if (this.tender.min_bet) {
                return this.tender.min_bet.bid;
            }
            return this.tender.starting_bid;
        }
    },
    computed: {
        minBid() {
            return this.tender.starting_bid % this.tender.auction_step;
        },
        maxBid() {
            return this.calcNewBid();
        }
    }
}
</script>
